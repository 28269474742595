import React,{useEffect,useState,useContext} from 'react'

import './info.css'
import {useGetData,usePutData,useDeleteData,usePostData,usePostData1,useGetData1} from '../hooks/useGetData'
import { RotatingLines } from  'react-loader-spinner'
import  letsgetstarted from '../images/signup10.jpg'

import ukman1 from '../images/ukman1.jpg'
import ukwoman1 from '../images/ukwoman1.jpg'
import swedman1 from '../images/swedman2.jpg'
import yukiwoman2 from '../images/yukiwoman2.jpg'
import yukiinbakery2 from '../images/yukiinbakery2.jpg'
import cornerbread from '../images/cornerbread2.jpg'
import languages from '../AppLanguage/AppLanguage'
import { IoShieldCheckmarkOutline, IoVolumeHigh } from "react-icons/io5";
import useReportErr from '../hooks/useReportErr'
import Alert from '../functions/appAlert'
import AppError from '../AppLanguage/AppError';
import { useNavigate,Link,useLocation } from 'react-router-dom';
import { SE } from 'country-flag-icons/react/3x2'
import { JP } from 'country-flag-icons/react/3x2'
import { GB } from 'country-flag-icons/react/3x2'
import klarnaimage from '../images/klarna.png'
import btn_base from '../images/btn_base.png'
import bslogo from '../images/bslogo.jpeg'


import ContactInfo from './Contactinfo'

// ai services
import AIServicesSwedish from './Aiservices/AIServicesSwedish'
import AIServicesEnglish from './Aiservices/AIServicesEnglish'
import AIServicesJapanese from './Aiservices/AIServicesJapanese'
// plus services
import PlusServices from './PlusServices/PlusServices'
// About us
import AboutUsSwedish from './AboutUs/AboutUsSwedish'
import AboutUsEnglish from './AboutUs/AboutUsEnglish'
import AboutUsJapanese from './AboutUs/AboutUsJapanese'
//faq
import Faq from './Faq/Faq'

//google login
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';

// stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {PaymentElement,useStripe,useElements} from "@stripe/react-stripe-js";

import { Cpu, Image, BookOpen, Camera, Box, LifeBuoy, Phone, ShieldCheck,Lightbulb, Layers, List, Grid, Utensils, Search, Scale, MessageCircle } from 'lucide-react';



import { FaHome,FaPlus, FaRegNewspaper,FaFileContract, FaConciergeBell, FaRobot, FaInfoCircle, 
  FaQuestionCircle, FaSignInAlt,FaAddressBook,FaShieldAlt } from 'react-icons/fa';
import { FiCheckCircle } from "react-icons/fi";
import { SlLogin } from "react-icons/sl";

import { ArrowRight, Facebook,CheckCircle2, Mail } from "lucide-react";
import { Package, Languages, Globe, DollarSign, Calendar } from 'lucide-react';
import { EntryPlanIcon, LanguageIcon,CountryIcon,CurrencyIcon,PeriodIcon } from '../Assets/Icons/Icons';
import { GrPlan } from "react-icons/gr";
import { BiWorld } from "react-icons/bi";
import { IoMdBook } from 'react-icons/io';
import { GrCurrency } from "react-icons/gr";
import { RxLapTimer } from "react-icons/rx";
import { AiOutlineCloseCircle } from "react-icons/ai";

export const MyContext = React.createContext();

export default function Info() {
  let location = useLocation();
  const query = new URLSearchParams(location.search);






const[signup,setSignup]=useState([{}])
const [getCountry,setCountry]=useState('en')
const [windowWidth, setWindowWith] = useState(window.innerWidth);
const [windowHeight, setWindowHeight] = useState(window.innerHeight);
const [mobileIsUsed, setMobileIsUsed] = useState(false);
const[getShowAlert,setShowAlert]=useState(false)
const[getAlertMessage,setAlertMessage]=useState('')
const[getAlertCode,setAlertCode]=useState(0)
const[pageid,setPageId]=useState(25)
const [buttonId, setButtonId] = useState(1);
const [customerPrices, setCustomerPrices] = useState([{}]);
const [xchangeRate, setXchangeRate] = useState(0);
const [currencySymbol, setCurrencySymbol] = useState([{}]);
const[getServicesInfo,setServicesInfo]=useState([{}])
const[getSpinnerLoading,setSpinnerLoading]=useState(false)
const[getServicesMainInfo,setServicesMainInfo]=useState([{}])
const[faqData,setfaqData]=useState([{}])
const[getNextTab,setNextTab]=useState(2)
const[getUserId,setUserId]=useState(0)
const[getVatInfo,setVatInfo]=useState([{}])
const[getSignUpStatus,setSignUpStatus]=useState([{}]);
const[getDetectedLanguage,setDetectedLanguage]=useState('en')


const [getSignupInfo, setSignupInfo] = useState({
  firstName: '',
  lastName: '',
  fullName:'',
  address: '',
  postalCode: '',
  city: '',
  region: '',
  localCountry: '', // default value
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  language: '',
  currency: '',
  service: '',
  planIntent: '',
  transactionId: '',
  acknowledge: false,
  customerId: '',
});


//klarna payment

const [clientToken, setClientToken] = useState(null);
const [klarna, setKlarna] = useState(false);
const [chosenServicePrice, setChosenServicePrice] = useState(0);
const [serviceName, setServiceName] = useState('');
const [klarnaXchangeRate, setKlarnaXchangeRate] = useState(0);
const [chosenServicePriceWithXchangeRate, setChosenServicePriceWithXchangeRate] = useState(0);
const [klarnaTaxRate, setKlarnaTaxRate] = useState(2500); // 25% tax rate for Klarna payments in SEK
const [klarnaTotalAmount, setKlarnaTotalAmount] = useState(0);
const [klarnaTotalTaxAmount, setKlarnaTotalTaxAmount] = useState(0);
const[finalPay,setfinalPay]=useState(false)
const[getIconSize,setIconSize]=useState(20)
const[getServiceTabStatus,setServiceTabStatus]=useState(true) // status to show the sign up tab again

//sliding menu
const [isMenuOpen, setIsMenuOpen] = useState(false);
const [isPartiallyOpen, setIsPartiallyOpen] = useState(true);
const [touchStartX, setTouchStartX] = useState(0);
const [touchEndX, setTouchEndX] = useState(0);


//stripe
const[getClientUpdate,setClientUpdate]=useState([{}])
const[getGlobalClientSecret,setGlobalClientSecret]=useState('')
// use this udseffect to trigger a update if the button id to render the component  we want to render

//REACT_APP_STRIPEPUBLIC_KEY
const stripePromise = loadStripe("pk_live_51Q2BZPP8AlL8ItFuETVcJBoEneSZaLM4l1QGDdr51MBxxAyeiLsSweubonnCbGWXGoZLs7ZmUNqPeIzdZqxmfrQQ00EmBSFMyE");

 //Creation of user account
const [getSignUpType, setSignUpType] = useState(0); // 1 means the user sign up by the ContinueWithButtons, 2 means the use sign up by email,name and password

const returnbuttonId = query.get('buttonid');
const clientSecret = query.get('client_secret');
const paymentIntent = query.get('payment_intent');
const paymentIntentClientSecret = query.get('payment_intent_client_secret');
const redirectStatus = query.get('redirect_status'); // 'succeeded' or 'failed'
const [paymentDetails, setPaymentDetails] = useState(null);

//sign up button
const signupButton = query.get('signupbutton');
useEffect(() => {
  if (signupButton) {
    setButtonId(parseInt(signupButton));
  }
}, [signupButton]);

//confirmation box for stripe payment
const[getCustomerChosenDetails,setCustomerChosenDetails]=useState([{}])
const[getPlanInfo,setPlanInfo]=useState([{}])

// Fetch additional payment details from backend if necessary, and we also make the setup
// of the users account if the payment is successful and is done by a callback supplier.
// A callback supplier is a supplier who sends the onsuccess event via callback.
// This payment check function is striggered by the callback.
// When the callback comes we read the params, and if we have a payment intent a useeffect is triggered
// this useeffect triggers the payment check function. When a payment intent is done we send with it a 
// a unique id, this id we will pick up when we verify at stripe, in this way  we can know which user
// has done the payment and we can update the user payment status in the database.
async function PaymentCheck() {
  setSpinnerLoading(false)
  let apiKey=process.env.REACT_APP_PAYVERIFYKEY
  try{
    let response=await usePostData1(`payment-details`,{intent:paymentIntent},apiKey);
    if(response.status === 200) {
      setPaymentDetails(response.data);
      setButtonId(22)
    }
      else if(response.status!==200){
        setPaymentDetails(response.data);
        setButtonId(23)
  
  }
}catch(error){
  setButtonId(23)
}
}

useEffect(() => {
  if(paymentIntent){
  PaymentCheck();
  }

}, [paymentIntent]);



const appearance = {
  theme: 'stripe', // You can try 'flat', 'night', 'none' for full custom control
  variables: {
    colorPrimary: '#1a73e8', // A professional blue shade for primary elements
    colorBackground: '#ffffff', // White background for a clean, modern look
    colorText: '#333333', // Dark grey for readable text
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', // Clean font stack
    fontWeightNormal: '400', // Normal font weight for regular text
    borderRadius: '6px', // Softer border radius for modern input fields
    spacingUnit: '4px', // Consistent padding between elements
  },
  rules: {
    '.Input': {
      backgroundColor: '#f9f9f9', // Light grey for input backgrounds
      color: '#333333', // Dark grey text for better contrast and readability
      borderColor: '#d1d5db', // Subtle grey border for inputs
      borderRadius: '6px', // Matching border radius for inputs
      padding: '12px', // Comfortable padding inside input fields
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Light shadow for input depth
    },
    '.Input:focus': {
      borderColor: '#1a73e8', // Focused input border in the primary color
      boxShadow: '0 0 0 3px rgba(26, 115, 232, 0.3)', // Soft blue focus ring
    },
    '.Label': {
      fontWeight: '600', // Make the labels bold for better emphasis
      color: '#1a73e8', // Use the primary blue for labels to match branding
    },
    '.Button': {
      backgroundColor: '#1a73e8', // Primary blue for button backgrounds
      color: '#ffffff', // White text for high contrast on buttons
      fontSize: '16px', // Comfortable button text size
      padding: '12px 16px', // Adequate padding for buttons
      borderRadius: '6px', // Matching border radius for buttons
      border: 'none', // Remove default button borders
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for button depth
      transition: 'background-color 0.3s ease', // Smooth hover transition
    },
    '.Button:hover': {
      backgroundColor: '#155ab5', // Darker blue on hover for visual feedback
    },
    '.Button:disabled': {
      backgroundColor: '#d1d5db', // Light grey for disabled buttons
      color: '#7d7d7d', // Muted text color for disabled buttons
    },
    '.Error': {
      color: '#e63946', // Bright red for error messages
    },
  },
};


// end stripe


// Line login
const [getLineCode,setLineCode]=useState('')


// google login
const[buttonWidth]=useState(200)


const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
  setIsPartiallyOpen(false);
};


const openMenu = () => {
  setIsMenuOpen(true);
  setIsPartiallyOpen(false);
};
const closeMenu = () => {
  setIsMenuOpen(false);
  setIsPartiallyOpen(false);
};



const handleTouchStart = (e) => {
  setTouchStartX(e.changedTouches[0].screenX);
};

const handleTouchMove = (e) => {
  setTouchEndX(e.changedTouches[0].screenX);
};

const handleTouchEnd = () => {
  if (touchStartX - touchEndX > 50) {
    // Swipe from right to left detected
    openMenu();
  } else if (touchEndX - touchStartX > 50) {
    // Swipe from left to right detected
    closeMenu();
  }
};
useEffect(() => {
  window.addEventListener('touchstart', handleTouchStart);
  window.addEventListener('touchmove', handleTouchMove);
  window.addEventListener('touchend', handleTouchEnd);

  return () => {
    window.removeEventListener('touchstart', handleTouchStart);
    window.removeEventListener('touchmove', handleTouchMove);
    window.removeEventListener('touchend', handleTouchEnd);
  };
}, [touchStartX, touchEndX]);




const [lastFetchTimestamp, setLastFetchTimestamp] = useState(() => {
  // Initialize state with value from localStorage or 0 if not found
  return parseInt(localStorage.getItem('lastFetchTimestamp')) || 0;
});
const navigate = useNavigate();


useEffect(() => {
  // Fetch the country from localStorage or default to 'en'
  let storedCountry = localStorage.getItem('country') || 'en';
  setCountry(storedCountry);

  // Determine the font family based on the country
  let newFontFamily = '';
  let newFontFamilyRoboto = '';
  let newFontFamilyNoto = '';  // New font family for Noto Sans

  if (storedCountry === 'ja') {
    newFontFamily = 'Zen Old Mincho, Roboto, Arial, Helvetica, sans-serif';
  } else {
    newFontFamily = 'Arial, Helvetica, sans-serif'; // Default with Arial
    newFontFamilyRoboto = 'Roboto, Arial, Helvetica, sans-serif'; // Default with Roboto
    newFontFamilyNoto = 'Noto Sans, Arial, Helvetica, sans-serif'; // Default with Noto Sans
  }

  // Set the font-family CSS variable for the entire document
  document.documentElement.style.setProperty('--font-family', newFontFamily);
  document.documentElement.style.setProperty('--font-familyRoboto', newFontFamilyRoboto);
  document.documentElement.style.setProperty('--font-familyNoto', newFontFamilyNoto); // New Noto Sans font family

}, []);


useEffect(() => {
let apiKey=process.env.REACT_APP_INFODATA
async function FetchPrices() {
  setSpinnerLoading(true);
  let response = await useGetData1(`getCustomerPrices`,{},apiKey);
  if (response.status === 200) {
    setSpinnerLoading(false);
    setCustomerPrices(response.data[0]);
    setXchangeRate(response.data[1]);
    setCurrencySymbol(response.data[2]);
    setServicesInfo(response.data[3]);
    setServicesMainInfo(response.data[4]);
    setfaqData(response.data[5]);
    setVatInfo(response.data[6]);
    setSignUpStatus(response.data[7]);
  }
}
FetchPrices()
},[])



useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
   // alert(window.innerWidth + 'x' + window.innerHeight);
    if(window.innerWidth < 600){
      setMobileIsUsed(true)
    }
  }
  // Call setSize initially to set the correct state
  setSize();
  // Add the event listener
  window.addEventListener('resize', setSize);
  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, []); // Dependencies

// language settings for the user at arrival
useEffect(() => {
  const detectLanguage = () => {
    const userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage.startsWith('sv')) {
      setCountry('se')
      return 'se'; // Swedish detected
    } else if (userLanguage.startsWith('en')) {
      setCountry('en')
      return 'en'; // English detected
    } else if (userLanguage.startsWith('ja')) {
      setCountry('ja')
      return 'ja'; // Japanese detected
    } else {
      return 'en'; // Default to English if no match
    }
  };

  const detectedCountry = detectLanguage();
  setDetectedLanguage(detectedCountry);
}, []);


// language settings for the applanguage
const selectedCountry = languages[getCountry];
const selectedError=AppError[localStorage.getItem('country')||'en']



const{
  startButtonText,
  signupButtonText,
  applicationButtonText,
  aiServicesButtonText,
  aboutUsButtonText,
  faqButtonText,
  basicButtonText,
  totalButtonText,
  basicCorporate,
  totalCorporate,
  basicfunctionalityButtonText,
  basicStoryButton,
  pleasefillupFormText,
  aftersignupemailwillbesent,
  accountsetupin24hrs,
  signUpHeaderText,
  firstNameText,
  lastNameText,
  addressText,
  postalCodeText,
  cityText,
  regionText,
  countryText,
  emailText,
  phoneText,
  passwordText,
  confirmPasswordText,
  selectLanguageText,
  selectSwedLangText,
  selectEngLangText,
  selectJapLangText,
  selectCurrencyText,
  selectSekCurrencyText,
  selectUsdCurrencyText,
  selectJapCurrencyText,
  selectServiceText,
  selectBasicServiceText,
  selectTotalServiceText,
  selectBasicCorporateServiceText,
  selectTotalCorporateServiceText,
  allfieldsMandatoryText,
  signUpButtonText,
  applicationBaseServicesHeader,
  applicationBaseVersions,
  applicationBaseVersions1,
  applicationBaseVersions2,
  applicationBaseVersions3,
  applicationBaseVersions4,
  applicationCanusePartly,
  applicationCanBeUsed,
  plusServicesHeaderText,
  plusServicesHeaderText1,
  plusServiceBenaemning,
  plusServicePrice,
  pricesGivenPerMonthText,
  faqHeaderText,
  totalWeTelltext,
  totalFunctionalitytext,
  totalCorporateWeTellText,
  totalCorporateFunctionalityText,
  basicCorporateWeTell,
  basicCorporateFunctionality,
  paypalMainHeaderText,
  pptotalAmountToPayText,
  ppChosenServiceText,
  ppChosenCurrencyText,
  registerAccountTabText,
  paymentTabText,
  ppBakesoftTermsText,
  ppBakesoftTerms,
  accountsetupSlow,
  contactInfoText,
  privacyPolicyText,
  klickOnTextMoreInfo,
  integrityPolicyText,
  contactInformationText,
  buyingTermsText,
  applicationPlusServicesText,
  signupCompanyButtonText,
  signupButtonPrivateText,
  signupButtonCompanyText,

  totalHeaderText,
  totalBasIncludedText,
  totalUppTo800RecipeText,
  totalProductsMax500Text,
  totalProductionText,
  totalAutomaticRecipeSummaryText,
  totalAutomaticIngredientSummaryText,
  totalAutomaticProductionStatisticText,
  totalCreatePricelistText,
  total200ProductCategoriesText,

  basCorpHeaderText,
  basCorporateSave500RecipeText,
  basCorpUse500IngredientsText,
  basCorpCreate200IngredientCategoriesText,
  basCorpCreateRecipeWithAiText,
  basCorpCreateRecipeManuallyWithIngredientsText,
  basCorpSearchRecipeIdeaText,
  basCorpCreateImageWithAiText,
  basCorpUploadOwnImageText,
  basCorpSave1000ImagesText,
  basCorpCreate200RecipeCategoriesText,
  basCorpAutomaticNutrientCalculationText,
  basCorpAutomaticCostCalculationText,
  basCorpAutomaticMatchIngredientsText,
  basCorpAutomaticGetNutrientsText,
  basCorpInformationsAboutIngredientsText,
  basCorpConvertVolumeToGramText,
  basCorpManageIngredientRecipeLinksText,
  basCorpMoveManageLinksAutomaticallyText,
  basCorpIngredientPriceText,
  basCorpAutomaticPriceDiagramText,
  basCorpHumoristicCommentsText,

  basPrivateSave300RecipeText,
basPrivateUse500IngredientsText,
basPrivateCreate100IngredientCategoriesText,
basPrivateSearchCreateRecipeAiText,
basPrivateCreateRecipeManuallyWithIngredientsText,
basPrivateSearchRecipeIdeaText,
basPrivateCreateImageWithAiText,
basPrivateUploadOwnImageText,
basPrivateSave400ImagesText,
basPrivateCreate100RecipeCategoriesText,
basPrivateAutomaticNutrientCalculationText,
basPrivateAutomaticCostCalculationText,
basPrivateAutomaticMatchIngredientsText,
basPrivateAutomaticGetNutrientsText,
basPrivateInformationsAboutIngredientsText,
basPrivateConvertVolumeToGramText,
basPrivateManageIngredientRecipeLinksText,
basPrivateMoveManageLinksAutomaticallyText,
basPrivateIngredientPriceText,
basPrivateAutomaticPriceDiagramText,
basPrivateHumoristicCommentsText,
totalPrivateHeaderText,



totalPrivateBaseIncludedText,
totalUppTo400RecipeText,
totalProductsMax300Text,
totalProductionText2,
totalAutomaticRecipeSummaryText2,
totalAutomaticIngredientSummaryText2,
totalAutomaticProductionStatisticText2,
createProductionMax250Text,
createPricelistWithMarginText,
createUpTo100ProductCategoriesText,

signUpSubscriptionHeaderText,
basicPlanText,
basServiceIdText,
basFeaturesText,
totalPlanText,
totalServiceIdText,
totalFeaturesText,
basicCorpPlanText,
basCorpServiceIdText,
basCorpFeaturesText,
totalCorpPlanText,
totalCorpServiceIdText,
totalCorpFeaturesText,


basPrivateHeaderText,
basCorporateheaderText,
totalCorporateheaderText,

selectPlanIntentTypeText,
selectMonthlyPlanText,
selectTrippleMonthPlanText,
selectYearlyPlanText,
discountText,
choseCountryText,
prepayLanguageHeader,
prepayCountryHeader,
prepayCurrencyHeader,
prepayPeriodHeader,

paymentDetailsHeaderText,
paymentDetailsCurrencyText,
paymentPlanText,
paymentTotalToPayText,
orderOverviewText,
plandetailText,
fullnameRequiredText,

fullNameLabelText,
emailLabelText,
passwordLabelText,
confirmPasswordLabelText,
continueLabelText,
welcomeLabelText,
signUpContinueLabelText,
orLabelText,
successPaymentHeaderText,
successPaymentText,
successPaymentIdText,
successPaymentAmountText,
successPaymentServiceText,
paymentFailedHeaderText,
paymentFailedTextInfo,
accountSetupServiceText,
accountSetupLanguageText,
accountSetupCurrencyText,
accountSetupPeriodText,
accountSetupCountryText,
accountSetupTotalAmountText,
accountSetupHeaderText,
accountSetupCancelText,
accountSetupTermsText,
confirmPayButtonText,
gotoLoginMessageText,
paymentSucceededText,
paymentProcessingText,
paymentFailedText,
paymentSomethingWentWrongText,
paymentSucceededEmailText,
continueWithFacebookText,
continueWithGoogleText,
continueWithLineText,
notValidFacebookTokenText,
planFeaturesText,
passwordConfirmText,
termsText




}=selectedCountry.info||{};


const{
  passwordsMustBelongText,
  confirmPasswordtext,
  passwordNoMatchText,
  firstNameRequiredText,
  lastNameRequiredText,
  adressRequiredText,
  postalCodeRequiredText,
  cityRequiredText,
  regionRequiredText,
  countryRequiredText,
  phoneRequiredText,
  emailRequiredText,
  languageRequiredText,
  currencyRequiredText,
  serviceRequiredText,
  emailNotValidText,
  accountCreatedSuccessText,
accountAlreadyExistText,
accountCreationFailedText,
paypalPaymentConfirmText,
klarnaPaymentSuccessText,
finalPaymentMessage,
finalPaymentMessageLink,
signUptemporaryClosedText,
klarnaPaymentfailureText,
setupDetailsDeleteFailedText,
accountCreatedNoSuccessText,
accountCantBeCreatedText,
passwordAtleast8,
passwordNoMatch,
}=selectedCountry.confirmationMessages||{};

// google login
const{
  loginConfirmToManyUsersText,
  notAuthorizedText,
}=selectedCountry.login||{};





const activeButtonHandler=(id)=>{
  setButtonId(id)

  if(id===2){
    if(getSignUpStatus&&getSignUpStatus.length>0&&getSignUpStatus[0].signUpStatus===1){
      setAlertCode(0)
      setAlertMessage(signUptemporaryClosedText)
      setShowAlert(true)
 
    }
 
}
setIsMenuOpen(false);
}

function getImageUrl(buttonId) {
  switch (buttonId) {
    case 0:
    case 1:
      return cornerbread;
      //letsgetstarted
    case 2:
      return cornerbread;
    case 3:
      return cornerbread;
    
    case 4:
      return cornerbread;
    case 5:
      return cornerbread;
    case 6:
      return cornerbread;
    case 7:
      return  cornerbread;
    case 10:
      return cornerbread;
    case 11:
      return cornerbread;
    case 13 :
      return cornerbread;
    case 20:
      return cornerbread;
    case 21:
      return cornerbread;

    case 22:
      return cornerbread;
      case 23:
        return cornerbread;

    default:
      return 'default_image_url'; // Default image if none of the IDs match
  }
}

const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);

    await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};
const handleCloseAlert = () => {
  setShowAlert(false);
};
useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);



function updateSignupInfo(value, key) {
  const error = validateField(key, value);
  if (error) {
    setAlertCode(56001);
   setAlertMessage(selectedError[56001])
   setShowAlert(true)
      return;
  }
  setSignupInfo(prevState => ({ ...prevState, [key]: value }));

}







function validateField(key, value) {
  const errors = {
      firstName: value.length > 44 ? "Name is too long." : null,
      lastName: value.length > 44 ? "Name is too long." : null,
      address: value.length > 44 ? "Address is too long." : null,
      phone: value.length > 44 ? "Phone number is too long." : null,
      postalCode: value.length > 44 ? "Postal code is too long." : null,
      city: value.length > 44 ? "City is too long." : null,
      region: value.length > 44 ? "Region is too long." : null,
      country: value.length > 44 ? "Country is too long." : null,


      // Add other field validations as needed
  };
  return errors[key] || null;
}

const createAccountRequestHandler = async () => {

   
   if(getSignupInfo.firstName.length<2){
    setAlertCode(0)
    setAlertMessage(firstNameRequiredText)
    setShowAlert(true)
    return;

   }else if (getSignupInfo.lastName.length<2){
    setAlertCode(0)
    setAlertMessage(lastNameRequiredText)
    setShowAlert(true)
    return;
   }else if (getSignupInfo.email.length<2){
      setAlertCode(0)
      setAlertMessage(emailRequiredText)
      setShowAlert(true)
      return;
    } else if (!/^\S+@\S+\.\S+$/.test(getSignupInfo.email)) {
      setAlertCode(0)
      setAlertMessage( emailNotValidText)
      setShowAlert(true)
      return
    }
    else if(getSignupInfo.planIntent===''){
      setAlertCode(0)
      setAlertMessage(selectPlanIntentTypeText)
      setShowAlert(true)
      return;
    }
    else if(getSignupInfo.fullName===''){
      setAlertCode(0)
      setAlertMessage(fullnameRequiredText)
      setShowAlert(true)
      return;
    }else if (getSignupInfo.language===''){
      setAlertCode(0)
      setAlertMessage(languageRequiredText)
      setShowAlert(true)
      return;
    }else if (getSignupInfo.currency===''){
      setAlertCode(0)
      setAlertMessage(currencyRequiredText)
      setShowAlert(true)
      return;
    }else if (getSignupInfo.service===''){
      setAlertCode(0)
      setAlertMessage(serviceRequiredText)
      setShowAlert(true)
      return;
    }
    else if (getSignupInfo.password.length < 8) {
      setAlertCode(0);
      setAlertMessage(passwordAtleast8); // Use the correct message for the password length requirement
      setShowAlert(true);
      return;
    }
    else if (getSignupInfo.confirmPassword.length < 8) {
      setAlertCode(0);
      setAlertMessage(passwordAtleast8); // Use the same message for confirm password
      setShowAlert(true);
      return;
    }
    else if (getSignupInfo.password !== getSignupInfo.confirmPassword) {
      setAlertCode(0);
      setAlertMessage(passwordNoMatch); // Use a different message for mismatching passwords
      setShowAlert(true);
      return;
    }
    // setNextTab(2)
    // return;
    SignUpRequest();

}

async function SignUpRequest(){
  setSpinnerLoading(true)
  let apiKey=process.env.REACT_APP_CREATE_USR

try{
  const response=await usePostData1('createUserAccount',{getSignupInfo},
    apiKey
  )
  if(response.status === 200){
    setUserId(response.data.userId)
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(accountCreatedSuccessText)
    setShowAlert(true)
    //setSignupInfo([])// for clearing the form
    setNextTab(2)
  }else if(response.status === 201){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(accountAlreadyExistText)
    setShowAlert(true)
  }
}catch(error){
  setSpinnerLoading(false)
  setAlertCode(0)
  setAlertMessage(accountCreationFailedText)
  setShowAlert(true)
}
}

async function SignUpRequestContinueWith(setupVal,id){
  setSpinnerLoading(true)
  let apiKey=process.env.REACT_APP_CREATE_USR


try{
  const response=await usePostData1('createUserAccountContinueWith',{getSignupInfo,setup:setupVal,clientIntent:clientSecret},
    apiKey
  )
  if(response.status === 200){
    setUserId(response.data.userId)
    setSpinnerLoading(false)
  //setAlertCode(0)
  //setAlertMessage(accountCreatedSuccessText)
  //setShowAlert(true)
    //setSignupInfo([])// for clearing the form
  //UpdateStripeUserPaymentStatus(response.data.userId)  // we have created the user account now we need to update the payment status
    //setNextTab(2)
  }else if(response.status === 201){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(accountAlreadyExistText)
    setShowAlert(true)
  }
}catch(error){
  setSpinnerLoading(false)
  setAlertCode(0)
  setAlertMessage(accountCreationFailedText)
  setShowAlert(true)
}
}


const jpHandler=()=>{
  setCountry('ja')
  localStorage.setItem('country','ja');
  
  }
  const seHandler=()=>{
  setCountry('se')
  localStorage.setItem('country','se');
  }
  const gbHandler=()=>{
  setCountry('en')
  localStorage.setItem('country', 'en');
  
  }  


    useEffect(() => {
      if(getSignupInfo.service){
      let currencyXchangeRate=xchangeRate.find((item)=>item.cc=='se')
      setKlarnaXchangeRate(currencyXchangeRate.xchangeRate)
      let myNewObjArr=[...customerPrices]
      let myNewObjIndex=myNewObjArr.findIndex((item)=>item.serviceid==getSignupInfo.service && item.cc=='se')
      setChosenServicePrice(myNewObjArr[myNewObjIndex].price)
      setServiceName(myNewObjArr[myNewObjIndex].benaemn)
      setChosenServicePriceWithXchangeRate(myNewObjArr[myNewObjIndex].price/currencyXchangeRate.xchangeRate)

      }
    }, [getSignupInfo]);
    


  async function ClearUserfromSystem(){
    setSpinnerLoading(true)
   let apiKey=process.env.REACT_APP_DELETEUSR
    try{
      const response=await usePostData1('deleteUserAccountInSetup',{getUserId},
        apiKey
      )
      if(response.status === 200){
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(accountCreatedNoSuccessText)
        setShowAlert(true)
        //setSignupInfo([])// for clearing the form
        setNextTab(1)
        setServiceTabStatus(false) // status to show the sign up tab again
        
      }
    }catch(error){

      setSpinnerLoading(false)
      setAlertCode(53026)
      setAlertMessage(selectedError[53026])
      setShowAlert(true)
    }
  }

  // async function UpdateStripeUserPaymentStatus(userId){
  //   let userservice=getSignupInfo.service
  //   let userlanguage=getSignupInfo.language
  //   let usercurrency=getSignupInfo.currency
  //   setSpinnerLoading(true)
  //   let apiKey=process.env.REACT_APP_PAYVERIFYKEY
  //   try{
  //     const response=await usePostData1('updateUserPaymentStatus',{userId,userservice,userlanguage,getClientUpdate,usercurrency},
  //    //   ctx.getUserId,ctx.getSignupInfo.service,ctx.getSignupInfo.language
  //       apiKey
  //     )
  //     if(response.status === 200){
  //       setSpinnerLoading(false)
  //       setAlertCode(0)
  //       setAlertMessage(klarnaPaymentSuccessText)
  //       setShowAlert(true)
  //       //setSignupInfo([])// for clearing the form
  //       setNextTab(2)
  //     }else if(response.status === 401){
  //       setSpinnerLoading(false)
  //       setAlertCode(0)
  //       setAlertMessage(klarnaPaymentfailureText)
  //       setShowAlert(true)
  //     }
  //   }catch(error){
  //     setSpinnerLoading(false)
  //     setAlertCode(53025)
  //     setAlertMessage(selectedError[53025])
  //     setShowAlert(true)
  //   }
  // }

  const handleSocialLogin = (platform) => {
    console.log('platform', platform);
    if (platform === 'Google') {
      initGoogleSignIn(); // Trigger Google Sign-In only when the user clicks on the Google button
    }else if(platform==='Facebook'){
      
      if (window.FB) {
        window.FB.login((response) => {
          statusChangeCallback(response);
        }, {scope: 'public_profile,email'});  // Requesting profile and email permissions
      }
    }else if(platform==='Line'){
      LineloginHandler()
    }
  };


  

// Line login -------------------------------------------------------------
async function LineloginHandler() {
  localStorage.setItem('hasLineConsent', 'true');

  const hasLineConsent = localStorage.getItem('hasLineConsent');
 
  if (!hasLineConsent) {
    setAlertMessage('LINEからあなたのメールアドレスを受け取る許可をお願いします。あなたのメールアドレスは、本人確認のため、本システム内であなたとの連絡やサポートをするためのみに使用します。他のいかなる第三者へも供与しません');
    setShowAlert(true);
    setAlertCode(0);
  } else {
    let code_challenge = localStorage.getItem('code_challenge');
    let nonce = localStorage.getItem('nonce');
    let randomLineState = localStorage.getItem('randomLineState');
     window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2000462696&redirect_uri=${encodeURIComponent('https://www.bakesoft.se/logincontwith')}&state=${randomLineState}&scope=profile%20openid%20email&nonce=${nonce}&code_challenge=${encodeURIComponent(code_challenge)}&code_challenge_method=S256`;

  }
}


useEffect(() => {
// when this component mounts, we set these values in the localstorage, so we can use them later
if (!localStorage.getItem('randomLineState')) {
  let randomLineState = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  localStorage.setItem('randomLineState', randomLineState);
}
if (!localStorage.getItem('nonce')) {
  let nonce = generateRandomString(32);
  localStorage.setItem('nonce', nonce);
}
if (!localStorage.getItem('code_verifier')) {
  // Generate a code_verifier
  let code_verifier =randomAlphaNumericString(43);
  // Store the code_verifier in localStorage for later
  localStorage.setItem('code_verifier', code_verifier);
  // Generate a code_challenge
  let code_challenge = base64URL(CryptoJS.SHA256(code_verifier));
  // Store the code_challenge in localStorage for later
  localStorage.setItem('code_challenge', code_challenge);
}
}, []);

function base64URL(string) {
  return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

function generateRandomString(length) {
  return Math.random().toString(36).substring(2, 2 + length);
}

function randomAlphaNumericString(length) {
var result = '';
var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
var charactersLength = characters.length;
for (var i = 0; i < length; i++) {
  result += characters.charAt(Math.floor(Math.random() * charactersLength));
}
return result;
}



// useeffect for Line login -------------------------------------------------------------
useEffect(() => {
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code'); // Get the authorization code from xthe query parameters
  const state = urlParams.get('state'); // Get the state parameter from the query parameters
  const randomLineState = localStorage.getItem('randomLineState'); // i get the random state from the localstorage
  if (state===randomLineState) {   // Check if the state parameter matches the random state, the random state is a requirement
                                   // from Line to prevent CSRF attacks
    setLineCode(code)
    return;
  }else{
 //console.error('Invalid state');
    setLineCode('')
  }
}, [location]);

// we have the code and we will send it to the backend
useEffect(() =>{ 
  if(getLineCode){
    LineLogin()
  }
}, [getLineCode]);

async function LineLogin() {
  let code_verifier = localStorage.getItem('code_verifier');
  setSpinnerLoading(true)
  try{
    let response = await usePostData(`continueLineLogin`, { code: getLineCode, code_verifier: code_verifier });
      if (response.status === 200 && response.data.token) {
        localStorage.setItem('contwithauth_token', response.data.token);
        const decodedToken = jwtDecode(response.data.token);
        const name=decodedToken.name
        const email=decodedToken.email
        if(name){
          const nameArr=name.split(' ')
          setSignupInfo(prevState => ({ ...prevState, firstName: nameArr[0], lastName: nameArr[1], email: email,fullName:name }));
        }else{
          setSignupInfo(prevState => ({ ...prevState, email: email }));
        }


       if (response.data.image) {
          localStorage.setItem('ContinueWithimage_link', response.data.image);
        }
        setSpinnerLoading(false);
        setSignUpType(1);  // 1 means the user has made the account with the continue with service Button on the sign up page
        setButtonId(20);
  
      }else if(response.status===298){
        setSpinnerLoading(false);
        setAlertCode(101180);
        setAlertMessage(accountCantBeCreatedText);
        setShowAlert(true);
      }
  
   
  
  }catch(error){
    setSpinnerLoading(false)
    localStorage.removeItem('code_verifier');
    localStorage.removeItem('code_challenge');
    localStorage.removeItem('nonce');
    localStorage.removeItem('randomLineState');
    setAlertCode(101180);
    setAlertMessage(accountCantBeCreatedText);
    setShowAlert(true);
    
  }
}

// Line login end -----------------------------------------------------------------------------








// Facebook starts here
 // Facebook login start -----------------------------------------------------------------

 useEffect(() => {
  const loadFacebookSDK = () => {
    // Load the SDK asynchronously if it's not already loaded
    if (!window.FB) {
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    } else {
      console.log('Facebook SDK already loaded');
    }
  };

  // Initialize the Facebook SDK when it's ready
  window.fbAsyncInit = function() {
    window.FB.init({
      appId      : '234940295680117',  // Your Facebook App ID
     //test appId:'1588754415326900',
      cookie     : true,
      xfbml      : true,
      version    : 'v20.0'
    });

    // Subscribe to login status changes
    window.FB.Event.subscribe('auth.statusChange', function(response) {
      statusChangeCallback(response);
    });

    // Parse XFBML to render the Facebook button
    window.FB.XFBML.parse();
  };

  // Load the SDK
  loadFacebookSDK();

  // Parse the Facebook button again if the SDK is already loaded
  if (window.FB) {
    console.log('Parsing Facebook button');
    window.FB.XFBML.parse();
  }

}, [location]);  // Re-run the effect on route changes




const statusChangeCallback = async (response) => {
  if (response.status === 'connected') {

    // we use our own api key to get to the backend
   const apiKey = process.env.REACT_APP_FBLOGINKEY;

    const accessToken = response.authResponse.accessToken;
    FacebookLogin(accessToken, apiKey);
    setSpinnerLoading(true);

  }
};

async function FacebookLogin(accessToken, apiKey) {
  try {
    let response = await usePostData1(`continueWithfacebookLogin`, { token: accessToken }, apiKey);
console.log('response',response)
    // Handle the response from your backend
    if (response.status === 200 && response.data.token) {
      localStorage.setItem('contwithauth_token', response.data.token);
      const decodedToken = jwtDecode(response.data.token);
      const name=decodedToken.name
      const email=decodedToken.email
      if(name){
        const nameArr=name.split(' ')
        setSignupInfo(prevState => ({ ...prevState, firstName: nameArr[0], lastName: nameArr[1], email: email,fullName:name }));
      }else{
        setSignupInfo(prevState => ({ ...prevState, email: email }));
      }


     if (response.data.image) {
        localStorage.setItem('ContinueWithimage_link', response.data.image);
      }
      setSpinnerLoading(false);
      setSignUpType(1);  // 1 means the user has made the account with the continue with service Button on the sign up page
      setButtonId(20);

    }else if(response.status===298){
      setSpinnerLoading(false);
      //setAlertCode(56002);
      setAlertMessage(accountCantBeCreatedText);
      setShowAlert(true);
    }

  } catch (error) {
    setSpinnerLoading(false);
    setAlertCode(56002);
    setAlertMessage(selectedError[56002]);
    setShowAlert(true);

  }
}

// Facebook login end -----------------------------------------------------------------








// google starts here
  useEffect(() => {
    // Load the Google Identity Services SDK once when the component mounts
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initGoogleSignIn = () => {
    const clientId = "447578481583-te1nq0ka5tuar8b3mjono33cq9reqdl3.apps.googleusercontent.com"; // Your Google Client ID
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse, // Callback for the login response
     
      });
      window.google.accounts.id.prompt(); // This will trigger the Google login prompt
    }
  };

  const handleCredentialResponse = (response) => {
    setSpinnerLoading(true);
    const apiKey = process.env.REACT_APP_VERIFY;

    // Clean up local storage items not needed for Google login
    localStorage.removeItem('code_verifier');
    localStorage.removeItem('code_challenge');
    localStorage.removeItem('nonce');
    localStorage.removeItem('randomLineState');
 

    // Send Google token to backend for verification
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/continueWithGoogleToken`, { token: response.credential }, {
        headers: { 'x-api-key': apiKey }
      })
      .then((response) => {
        if (response.status === 200 && response.data.token) {
          setSpinnerLoading(false);
          // Store the token and additional user data
          localStorage.setItem('contwithauth_token', response.data.token);
          const decodedToken = jwtDecode(response.data.token);
          const name=decodedToken.name
          const email=decodedToken.email
          if(name){
            const nameArr=name.split(' ')
            setSignupInfo(prevState => ({ ...prevState, firstName: nameArr[0], lastName: nameArr[1], email: email,fullName:name }));
          }else{
            setSignupInfo(prevState => ({ ...prevState, email: email }));
          }
           
           // if image is available
          if (response.data.image) {
            localStorage.setItem('ContinueWithimage_link', response.data.image);
          }

          // window.dispatchEvent(new Event('storage')); // To trigger a storage event
          // // navigate('/WelcomeImage'); // Redirect the user after successful login
          setSignUpType(1);  // 1 means the user has made the account with the continue with service Button on the sign up page
          setButtonId(20);

        } else if (response.status === 401) {
          // Handle not authorized case
          setSpinnerLoading(false); // Stop spinner
          setAlertCode(101179);
          setAlertMessage(notAuthorizedText);
          setShowAlert(true);
        }else if(response.status===298){
          setSpinnerLoading(false);
          setAlertCode(101179);
          setAlertMessage(accountCantBeCreatedText);
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setSpinnerLoading(false); // Stop spinner
        setAlertCode(101179);
        setAlertMessage(notAuthorizedText);
        setShowAlert(true);
      })
      .finally(() => {
        setSpinnerLoading(false); // Stop spinner
      });
  };

// Plan info

const GetPlanPriceInfoHandler=(intent)=>{
  if(intent){
    GetPlanPriceInfo(intent)
  }
}

useEffect(() => {
  if (getSignupInfo.currency) {
    GetPlanPriceInfoHandler(getSignupInfo.currency);
    setSignupInfo(prevState => ({ ...prevState, planIntent: '' }));
  } 
}, [getSignupInfo.currency]);

async function GetPlanPriceInfo(intent) {
  setSpinnerLoading(true);
  let apiKey=process.env.REACT_APP_GETPLANKEY
  try{
    let response = await usePostData1('getPlanInfo',{customerSetup:getSignupInfo,intentNum:intent},apiKey);
    if(response.status === 200){
      setSpinnerLoading(false);
      setPlanInfo(response.data);
    
    }else if(response.status !== 200){
      setSpinnerLoading(false);
       setAlertCode(1)
       setAlertMessage('no ok')
       setShowAlert(true)
    }

  }catch(error){
    console.log(error)
  }

}

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) && email.length <= 45;
};

function CheckMail(){
  if(getSignupInfo.email){
    if(isValidEmail(getSignupInfo.email)){
       return ValidatePassword(getSignupInfo.password, getSignupInfo.confirmPassword);
    }else{
      setAlertCode(0)
      setAlertMessage(emailNotValidText)
      setShowAlert(true)
      return false
    }
  }
}

function ValidatePassword(password, confirmPassword) {
  if (password.length < 8) {
    setAlertCode(0)
    setAlertMessage(passwordAtleast8)
    setShowAlert(true)
    return false;
  }
  
  if (confirmPassword.length < 8) {
    setAlertCode(0)
    setAlertMessage(passwordAtleast8)
    setShowAlert(true)
    return false;
   
  }
  
  if (password !== confirmPassword) {
    setAlertCode(0)
    setAlertMessage(passwordNoMatch)
    setShowAlert(true)
    return false;
  }
  
  return CheckUserInfoHandler();
}


async function CheckUserInfoHandler(){
  console.log('checking user info')
  setSpinnerLoading(true);
  let apiKey=process.env.REACT_APP_CHECKUSERKEY
  try{
    let response = await usePostData1('checkUserInfo',{email:getSignupInfo.email},apiKey);
    if(response.status === 200){
      setSpinnerLoading(false);
     setButtonId(20) // we check the email of the signed up user and if it exists we show the continue with button
    }else if(response.status !== 200){
      setSpinnerLoading(false);
      setAlertCode(56004)
      setAlertMessage(selectedError[56004])
      setShowAlert(true)
    }
  }catch(error){
    setSpinnerLoading(false);
    setAlertCode(56004)
    setAlertMessage(selectedError[56004])
    setShowAlert(true)
  }
}




  return (
    <div>
    <MyContext.Provider value={{
      
      getCountry,setCountry,
      faqButtonText:faqButtonText,
      aboutUsButtonText:aboutUsButtonText,
      aiServicesButtonText:aiServicesButtonText,
      applicationButtonText:applicationButtonText,
      signupButtonText:signupButtonText,
      startButtonText:startButtonText,
      basicButtonText:basicButtonText,
      totalButtonText:totalButtonText,
      basicCorporate:basicCorporate,
      totalCorporate:totalCorporate,
      basicfunctionalityButtonText:basicfunctionalityButtonText,
      basicStoryButton:basicStoryButton,
      pleasefillupFormText:pleasefillupFormText,
      aftersignupemailwillbesent: aftersignupemailwillbesent,
      accountsetupin24hrs:accountsetupin24hrs,
      signUpHeaderText:signUpHeaderText,
      firstNameText:firstNameText,
      lastNameText:lastNameText,
      addressText:addressText,
      postalCodeText:postalCodeText,
      cityText:cityText,
      regionText:regionText,
      countryText:countryText,
      emailText:emailText,
      phoneText:phoneText,
      passwordText:passwordText,
      confirmPasswordText:confirmPasswordText,
      selectLanguageText:selectLanguageText,
      selectSwedLangText:selectSwedLangText,
      selectEngLangText:selectEngLangText,
      selectJapLangText:selectJapLangText,
      selectCurrencyText:selectCurrencyText,
      selectSekCurrencyText:selectSekCurrencyText,
      selectUsdCurrencyText:selectUsdCurrencyText,
      selectJapCurrencyText:selectJapCurrencyText,
      selectServiceText:selectServiceText,
      selectBasicServiceText:selectBasicServiceText,
      selectTotalServiceText:selectTotalServiceText,
      selectBasicCorporateServiceText:selectBasicCorporateServiceText,
      selectTotalCorporateServiceText:selectTotalCorporateServiceText,
      allfieldsMandatoryText:allfieldsMandatoryText,
      signUpButtonText:signUpButtonText,
      getImageUrl:getImageUrl,
      buttonId:buttonId,
      applicationBaseServicesHeader:applicationBaseServicesHeader,
      applicationBaseVersions:applicationBaseVersions,
      applicationBaseVersions1:applicationBaseVersions1,
      applicationBaseVersions2:applicationBaseVersions2,
      applicationBaseVersions3:applicationBaseVersions3,
      applicationBaseVersions4:applicationBaseVersions4,
      applicationCanusePartly:applicationCanusePartly,
      applicationCanBeUsed:applicationCanBeUsed,
      
      ukman1:ukman1,
      ukwoman1:ukwoman1,
      yukiwoman2:yukiwoman2,
      yukiinbakery2:yukiinbakery2,
      customerPrices:customerPrices,
      xchangeRate:xchangeRate,
      currencySymbol:currencySymbol,
      swedman1:swedman1,
      getServicesInfo:getServicesInfo,
      plusServicesHeaderText:plusServicesHeaderText,
      plusServicesHeaderText1:plusServicesHeaderText1,
      plusServiceBenaemning:plusServiceBenaemning,
      plusServicePrice:plusServicePrice,
      pricesGivenPerMonthText:pricesGivenPerMonthText,
      getSignupInfo:getSignupInfo,
      getSpinnerLoading:getSpinnerLoading,
      getServicesMainInfo:getServicesMainInfo,
      faqData:faqData,
      faqHeaderText:faqHeaderText,
      totalWeTelltext:totalWeTelltext,
      totalFunctionalitytext:totalFunctionalitytext,
      totalCorporateWeTellText:totalCorporateWeTellText,
      totalCorporateFunctionalityText:totalCorporateFunctionalityText,
      basicCorporateWeTell:basicCorporateWeTell,
      basicCorporateFunctionality:basicCorporateFunctionality,



  pleasefillupFormText:pleasefillupFormText,
  pleasefillupFormText:pleasefillupFormText,
  aftersignupemailwillbesent:aftersignupemailwillbesent,
  signUpHeaderText:signUpHeaderText,
  getCountry:getCountry,
  firstNameText:firstNameText,
  lastNameText:lastNameText,
  addressText:addressText,
  postalCodeText:postalCodeText,
  cityText:cityText,
  regionText:regionText,
  countryText:countryText,
  emailText:emailText,
  phoneText:phoneText,
  passwordText:passwordText,
  confirmPasswordText:confirmPasswordText,
  selectLanguageText:selectLanguageText,
  selectSwedLangText:selectSwedLangText,
  selectEngLangText:selectEngLangText,
  selectJapLangText:selectJapLangText,
  selectCurrencyText:selectCurrencyText,
  selectSekCurrencyText:selectSekCurrencyText,
  selectUsdCurrencyText:selectUsdCurrencyText,
  selectJapCurrencyText:selectJapCurrencyText,
  selectServiceText:selectServiceText,
  selectBasicServiceText:selectBasicServiceText,
  selectTotalServiceText:selectTotalServiceText,
  selectBasicCorporateServiceText:selectBasicCorporateServiceText,
  getSignupInfo:getSignupInfo,
  updateSignupInfo:updateSignupInfo,
  selectTotalCorporateServiceText:selectTotalCorporateServiceText,
  allfieldsMandatoryText:allfieldsMandatoryText,
  signUpButtonText:signUpButtonText,
  createAccountRequestHandler:createAccountRequestHandler,
  letsgetstarted:letsgetstarted,
  accountsetupin24hrs:accountsetupin24hrs,
  updateSignupInfo:updateSignupInfo,
  getNextTab:getNextTab,
  getUserId:getUserId,
  paypalMainHeaderText:paypalMainHeaderText,
  pptotalAmountToPayText:pptotalAmountToPayText,
  ppChosenServiceText:ppChosenServiceText,
  ppChosenCurrencyText:ppChosenCurrencyText,
  registerAccountTabText:registerAccountTabText,
  paymentTabText:paymentTabText,
  ppBakesoftTermsText:ppBakesoftTermsText,
  ppBakesoftTerms:ppBakesoftTerms,
  setAlertMessage:setAlertMessage,
  setShowAlert:setShowAlert,
  paypalPaymentConfirmText:paypalPaymentConfirmText,
  // CustomerPaymentHandler:CustomerPaymentHandler,
  getVatInfo:getVatInfo,
  accountsetupSlow:accountsetupSlow,
  klarnaimage:klarnaimage,
  // fetchKlarnaPaymentSession:fetchKlarnaPaymentSession,
  // setChosenServicePrice:setChosenServicePrice,
  // setServiceName:setServiceName,
  // customerPrices:customerPrices,
  clientToken:clientToken,
  klarnaTotalAmount:klarnaTotalAmount,
  klarnaTotalTaxAmount:klarnaTotalTaxAmount,
  chosenServicePrice:chosenServicePrice,
  serviceName:serviceName,
  chosenServicePriceWithXchangeRate:chosenServicePriceWithXchangeRate,
  klarnaTaxRate:klarnaTaxRate,
// finalizeKlarnaPayment:finalizeKlarnaPayment,
setKlarna:setKlarna,
setAlertCode:setAlertCode,
klarna:klarna,
finalPay:finalPay,
finalPaymentMessage:finalPaymentMessage,
finalPaymentMessageLink:finalPaymentMessageLink,
klickOnTextMoreInfo:klickOnTextMoreInfo,
integrityPolicyText:integrityPolicyText,
contactInformationText:contactInformationText,
klarnaPaymentfailureText:klarnaPaymentfailureText,
getCountry:getCountry,
setCountry:setCountry,
ClearUserfromSystem:ClearUserfromSystem,
getServiceTabStatus:getServiceTabStatus,
startButtonText:startButtonText,
signupButtonText:signupButtonText,
applicationButtonText:applicationButtonText,
aiServicesButtonText:aiServicesButtonText,
faqButtonText:faqButtonText,
aboutUsButtonText:aboutUsButtonText,
activeButtonHandler:activeButtonHandler,
    contactInfoText:contactInfoText,
    privacyPolicyText:privacyPolicyText,
    buyingTermsText:buyingTermsText,
    setSpinnerLoading:setSpinnerLoading,
    setClientUpdate:setClientUpdate,
   // UpdateStripUserPaymentStatus:UpdateStripeUserPaymentStatus,
    setGlobalClientSecret:setGlobalClientSecret,
    getGlobalClientSecret:getGlobalClientSecret,


    totalHeaderText:totalHeaderText,
    totalBasIncludedText:totalBasIncludedText,
    totalUppTo800RecipeText:totalUppTo800RecipeText,
    totalProductsMax500Text:totalProductsMax500Text,
    totalProductionText:totalProductionText,
    totalAutomaticRecipeSummaryText:totalAutomaticRecipeSummaryText,
    totalAutomaticIngredientSummaryText:totalAutomaticIngredientSummaryText,
    totalAutomaticProductionStatisticText:totalAutomaticProductionStatisticText,
    totalCreatePricelistText:totalCreatePricelistText,
    total200ProductCategoriesText:total200ProductCategoriesText,

    basCorpHeaderText:basCorpHeaderText,
    basCorporateSave500RecipeText:basCorporateSave500RecipeText,
    basCorpUse500IngredientsText:basCorpUse500IngredientsText,
    basCorpCreate200IngredientCategoriesText:basCorpCreate200IngredientCategoriesText,
    basCorpCreateRecipeWithAiText:basCorpCreateRecipeWithAiText,
    basCorpCreateRecipeManuallyWithIngredientsText:basCorpCreateRecipeManuallyWithIngredientsText,
    basCorpSearchRecipeIdeaText:basCorpSearchRecipeIdeaText,
    basCorpCreateImageWithAiText:basCorpCreateImageWithAiText,
    basCorpUploadOwnImageText:basCorpUploadOwnImageText,
    basCorpSave1000ImagesText:basCorpSave1000ImagesText,
    basCorpCreate200RecipeCategoriesText:basCorpCreate200RecipeCategoriesText,
    basCorpAutomaticNutrientCalculationText:basCorpAutomaticNutrientCalculationText,
    basCorpAutomaticCostCalculationText:basCorpAutomaticCostCalculationText,
    basCorpAutomaticMatchIngredientsText:basCorpAutomaticMatchIngredientsText,
    basCorpAutomaticGetNutrientsText:basCorpAutomaticGetNutrientsText,
    basCorpInformationsAboutIngredientsText:basCorpInformationsAboutIngredientsText,
    basCorpConvertVolumeToGramText:basCorpConvertVolumeToGramText,
    basCorpManageIngredientRecipeLinksText:basCorpManageIngredientRecipeLinksText,
    basCorpMoveManageLinksAutomaticallyText:basCorpMoveManageLinksAutomaticallyText,
    basCorpIngredientPriceText:basCorpIngredientPriceText,
    basCorpAutomaticPriceDiagramText:basCorpAutomaticPriceDiagramText,
    basCorpHumoristicCommentsText:basCorpHumoristicCommentsText,


basPrivateSave300RecipeText:basPrivateSave300RecipeText,
basPrivateUse500IngredientsText:basPrivateUse500IngredientsText,
basPrivateCreate100IngredientCategoriesText:basPrivateCreate100IngredientCategoriesText,
basPrivateSearchCreateRecipeAiText:basPrivateSearchCreateRecipeAiText,
basPrivateCreateRecipeManuallyWithIngredientsText:basPrivateCreateRecipeManuallyWithIngredientsText,
basPrivateSearchRecipeIdeaText:basPrivateSearchRecipeIdeaText,
basPrivateCreateImageWithAiText:basPrivateCreateImageWithAiText,
basPrivateUploadOwnImageText:basPrivateUploadOwnImageText,
basPrivateSave400ImagesText:basPrivateSave400ImagesText,
basPrivateCreate100RecipeCategoriesText:basPrivateCreate100RecipeCategoriesText,
basPrivateAutomaticNutrientCalculationText:basPrivateAutomaticNutrientCalculationText,
basPrivateAutomaticCostCalculationText:basPrivateAutomaticCostCalculationText,
basPrivateAutomaticMatchIngredientsText:basPrivateAutomaticMatchIngredientsText,
basPrivateAutomaticGetNutrientsText:basPrivateAutomaticGetNutrientsText,
basPrivateInformationsAboutIngredientsText:basPrivateInformationsAboutIngredientsText,
basPrivateConvertVolumeToGramText:basPrivateConvertVolumeToGramText,
basPrivateManageIngredientRecipeLinksText:basPrivateManageIngredientRecipeLinksText,
basPrivateMoveManageLinksAutomaticallyText:basPrivateMoveManageLinksAutomaticallyText,
basPrivateIngredientPriceText:basPrivateIngredientPriceText,
basPrivateAutomaticPriceDiagramText:basPrivateAutomaticPriceDiagramText,
basPrivateHumoristicCommentsText:basPrivateHumoristicCommentsText,


totalPrivateBaseIncludedText:totalPrivateBaseIncludedText,
totalUppTo400RecipeText:totalUppTo400RecipeText,
totalProductsMax300Text:totalProductsMax300Text,
totalProductionText2:totalProductionText2,
totalAutomaticRecipeSummaryText2:totalAutomaticRecipeSummaryText2,
totalAutomaticIngredientSummaryText2:totalAutomaticIngredientSummaryText2,
totalAutomaticProductionStatisticText2:totalAutomaticProductionStatisticText2,
createProductionMax250Text:createProductionMax250Text,
createPricelistWithMarginText:createPricelistWithMarginText,
createUpTo100ProductCategoriesText:createUpTo100ProductCategoriesText,

signUpSubscriptionHeaderText:signUpSubscriptionHeaderText,
basicPlanText:basicPlanText,
basServiceIdText:basServiceIdText,
basFeaturesText:basFeaturesText,
totalPlanText:totalPlanText,
totalServiceIdText:totalServiceIdText,
totalFeaturesText:totalFeaturesText,
basicCorpPlanText:basicCorpPlanText,
basCorpServiceIdText:basCorpServiceIdText,
basCorpFeaturesText:basCorpFeaturesText,
totalCorpPlanText:totalCorpPlanText,
totalCorpServiceIdText:totalCorpServiceIdText,
totalCorpFeaturesText:totalCorpFeaturesText,
totalPrivateHeaderText:totalPrivateHeaderText,

basPrivateHeaderText:basPrivateHeaderText,
totalPrivateHeaderText:totalPrivateHeaderText,
basCorporateheaderText:basCorporateheaderText,
totalCorporateheaderText:totalCorporateheaderText,
handleSocialLogin:handleSocialLogin,

selectPlanIntentTypeText:selectPlanIntentTypeText,
selectMonthlyPlanText:selectMonthlyPlanText,
selectTrippleMonthPlanText:selectTrippleMonthPlanText,
selectYearlyPlanText:selectYearlyPlanText,
discountText:discountText,
choseCountryText:choseCountryText,

prepayLanguageHeader:prepayLanguageHeader,
prepayCountryHeader:prepayCountryHeader,
prepayCurrencyHeader:prepayCurrencyHeader,
prepayPeriodHeader:prepayPeriodHeader,

paymentDetailsHeaderText:paymentDetailsHeaderText,
paymentDetailsCurrencyText:paymentDetailsCurrencyText,
paymentPlanText:paymentPlanText,
discountText:discountText,
paymentTotalToPayText:paymentTotalToPayText,

orderOverviewText:orderOverviewText,
plandetailText:plandetailText,

fullNameLabelText:fullNameLabelText,
emailLabelText:emailLabelText,
passwordLabelText:passwordLabelText,
confirmPasswordLabelText:confirmPasswordLabelText,
continueLabelText:continueLabelText,
welcomeLabelText:welcomeLabelText,
signUpContinueLabelText:signUpContinueLabelText,
orLabelText:orLabelText,
setSignupInfo:setSignupInfo,
setButtonId:setButtonId,
buttonId:buttonId,
stripePromise:stripePromise,  
appearance:appearance,
paymentDetails:paymentDetails,
redirectStatus:redirectStatus,

successPaymentHeaderText:successPaymentHeaderText,
successPaymentText:successPaymentText,
successPaymentIdText:successPaymentIdText,
successPaymentAmountText:successPaymentAmountText,
successPaymentServiceText:successPaymentServiceText,
paymentFailedHeaderText:paymentFailedHeaderText,
paymentFailedText:paymentFailedText,
getCustomerChosenDetails:getCustomerChosenDetails,
setCustomerChosenDetails:setCustomerChosenDetails,

accountSetupServiceText:accountSetupServiceText,
accountSetupLanguageText:accountSetupLanguageText,
accountSetupCurrencyText:accountSetupCurrencyText,
accountSetupPeriodText:accountSetupPeriodText,
accountSetupCountryText:accountSetupCountryText,
accountSetupTotalAmountText:accountSetupTotalAmountText,
accountSetupHeaderText:accountSetupHeaderText,
accountSetupCancelText:accountSetupCancelText,
accountSetupTermsText:accountSetupTermsText,
confirmPayButtonText:confirmPayButtonText,
orLabelText:orLabelText,
getCustomerChosenDetails:getCustomerChosenDetails,
GetPlanPriceInfoHandler:GetPlanPriceInfoHandler,
getPlanInfo:getPlanInfo,
setPlanInfo:setPlanInfo,
navigate:navigate,
customerPrices:customerPrices,
loadStripe:loadStripe,
gotoLoginMessageText:gotoLoginMessageText,
paymentSucceededText:paymentSucceededText,
paymentProcessingText:paymentProcessingText,
paymentFailedTextInfo:paymentFailedTextInfo,
paymentSomethingWentWrongText:paymentSomethingWentWrongText,
paymentSucceededEmailText:paymentSucceededEmailText,
Mail:Mail,
continueWithFacebookText:continueWithFacebookText,
continueWithGoogleText:continueWithGoogleText,
continueWithLineText:continueWithLineText,
getImageUrl:getImageUrl,
SignUpRequestContinueWith:SignUpRequestContinueWith,
planFeaturesText:planFeaturesText,
bslogo:bslogo,
CheckMail:CheckMail,
passwordConfirmText:passwordConfirmText,
termsText:termsText,
redirectStatus:redirectStatus,



// Payment component flow
// 1st component the user comes to is BakesoftContinueWith  , user chose a social network or sing up with email
// 2nd component is SignupPrivateFast  , user choses the plan and other details
// 3rd component is PreCheckOut, but its displayed beside the SignupPrivateFast
// 4th component is StripePayment, this component maes a payment intent to stripe, using the currency and amount
// then a intent is returned from the backend.
// 5th component is the CheckoutForm, this component is displayed after the payment intent is returned from the backend
// 6th component is the CompletePage, this component is displayed and displays success or failure.
// If we have a success we make a call to the backend, we create the account and send an email to the user

    
   }}>
    <div className="infototWrapper">
    <div id="status"></div>
      <div className="infototalArea">

       {mobileIsUsed===true?
        <div className="infoA"></div>
        :null}

{mobileIsUsed===true?
      <SlidingMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}
      seHandler={seHandler}
      jpHandler={jpHandler}
      gbHandler={gbHandler}
      isPartiallyOpen={isPartiallyOpen}
      />
      :null}
      
 
 {!mobileIsUsed?(
         <div className="infoA">
          <div className="infoButtonTopWrapper">
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 1 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(1)}><FaHome size={getIconSize} />&nbsp;{startButtonText}</button>
              
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 2 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(2)}><FaRegNewspaper size={getIconSize} />&nbsp;{signupButtonPrivateText}</button>

             
           

                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 4 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(4)}><FaRobot size={getIconSize} />&nbsp;{aiServicesButtonText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 7 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(7)}><FaPlus size={getIconSize} />&nbsp;{applicationPlusServicesText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 12 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(12)}><FaFileContract size={getIconSize} />&nbsp;{buyingTermsText}</button>
            
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 5 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(5)}> <FaInfoCircle size={getIconSize} />&nbsp;{aboutUsButtonText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 6 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(6)}><FaQuestionCircle size={getIconSize} />&nbsp;{faqButtonText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 11 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(11)}><FaAddressBook size={getIconSize} />&nbsp;{contactInfoText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 10 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(10)}><FaShieldAlt size={getIconSize} />&nbsp;{privacyPolicyText}</button>
               
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 8 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(8)}><FaSignInAlt size={getIconSize} />&nbsp;Login</button>
   

              <div className="infoCountryFlagWrapper"> 
              <SE title="Swedish" className="xzloginflagse" onClick={seHandler} />
              <JP title="Japanese" className="xzloginflagJapan"  onClick={jpHandler}/>
              <GB title="English" className="xzloginflaggb" onClick={gbHandler} />
              </div>

              <div className="infoPaymentWrapper">



 <div className="infoPaymentPayPal">
 <table border="0" cellPadding="10" cellSpacing="0" align="center">
   <tr>
      <td align="center"></td>
   </tr>
      <tr>
        <td align="center">
<a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" 
target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
<img src="https://www.paypalobjects.com/webstatic/mktg/logo/bdg_now_accepting_pp_2line_w.png" 
border="0" alt="Now accepting PayPal" /></a>
< div style={{ textAlign: 'center', marginTop: '10px' }}>

    <a href="https://www.paypal.com/webapps/mpp/pay-online" 
    style={{ fontSize: '12px', color: '#0079CD', fontFamily: 'Arial', textDecoration: 'none' }}>
    <strong>How PayPal Works</strong></a>
</div>
</td>
</tr>
</table>

</div>
</div>


            

          </div>
        </div> 
        ):null}

 
   


{(buttonId === 1 || buttonId === 2) ? (
  <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    
    {buttonId === 1 && getCountry === 'se' ? <InfoStartSwedish /> : null}
    {buttonId === 1 && getCountry === 'en' ? <InfoStartEnglish /> : null}
    {buttonId === 1 && getCountry === 'ja' ? <InfoStartJapanese /> : null}

    {buttonId === 2&&getSignUpStatus[0].signUpStatus===0? (
      <div className="signUpTopWrapper">
        <BakesoftContinueWith />
      </div>
    ) : null}

  </div>
) : null}

{buttonId === 22 && paymentDetails ? (
  <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <PaymentDetailsConfirm/>
  </div>
) : null}

{buttonId === 23? (
  <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <PaymentDetailsConfirmNoSuccess/>
  </div>
) : null}


         

          {buttonId===20&&mobileIsUsed===false ?
         <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <SignUpPrivateFast/>
          </div>:null}
          {buttonId===20&&mobileIsUsed===true ?
         <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <SignUpPrivateFastMobile/>
          </div>:null}
          


          
          {buttonId===21?
       <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <div className="signuptoverifyTop">
            <StripePayment/>
          </div></div>:null}
       


      

          
  
    



          {buttonId===4 && getCountry==='se'?<AIServicesSwedish  getImageUrl={getImageUrl} buttonId={buttonId} />:null}

          {buttonId===4 && getCountry==='en'?<AIServicesEnglish  getImageUrl={getImageUrl} buttonId={buttonId} />:null}

          {buttonId===4 && getCountry==='ja'?<AIServicesJapanese  getImageUrl={getImageUrl} buttonId={buttonId} />:null}
        
          
          {buttonId===5 && getCountry==='se'?<AboutUsSwedish
              getImageUrl={getImageUrl} buttonId={buttonId}
          />:null}
              {buttonId===5 && getCountry==='en'?<AboutUsEnglish
                  getImageUrl={getImageUrl} buttonId={buttonId}
              />:null}
                    {buttonId===5 && getCountry==='ja'?<AboutUsJapanese
                        getImageUrl={getImageUrl} buttonId={buttonId}
                    />:null}


          {buttonId===6?<Faq
          faqData={faqData}
          getCountry={getCountry}
          faqHeaderText={faqHeaderText}
          getImageUrl={getImageUrl}
          buttonId={buttonId}
          />:null}

          {buttonId===7?<PlusServices
                
                getImageUrl={getImageUrl}
                buttonId={buttonId}
                getServicesInfo={getServicesInfo}
                getCountry={getCountry}
                xchangeRate={xchangeRate}
                currencySymbol={currencySymbol}
                plusServicePrice={plusServicePrice}
                plusServiceBenaemning={plusServiceBenaemning}
                plusServicesHeaderText={plusServicesHeaderText}
                plusServicesHeaderText1={plusServicesHeaderText1}
                pricesGivenPerMonthText={pricesGivenPerMonthText}
                getServicesMainInfo={getServicesMainInfo}
                klickOnTextMoreInfo={klickOnTextMoreInfo}
          />:null}

          {buttonId===8? navigate('/login'):null}
          {buttonId===10?<IntegrityPolicy/>:null}
          {buttonId===11?<ContactInfo
          getImageUrl={getImageUrl}
          buttonId={buttonId}
          contactInfoText={contactInfoText}
  
          />:null}
      
          {getCountry==='se' && buttonId===12?<BuyingTerms/>:null}
          {getCountry==='en' && buttonId===12?<BuyingTermsENG/>:null}
          {getCountry==='ja' && buttonId===12?<BuyingTermsJAP/>:null}

  
       

         

  </div>
</div>





  </MyContext.Provider>
  <div className="infoSpinnerContainer">
        <div className="infoSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>
  
  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
  </div>
  )
}

function InfoStartSwedish(){
  
    return(
  
<div className="infoStartWrapper">
    <div className="infoStartHeader">Välkommen</div>
    <div className="infoStartTextSwedish">
        Bakesoft.se är en unik SAAS-applikation som samlar ihop <br></br>många viktiga funktioner. Den skapar en unik och kreativ<br></br> plats för kockar,bagare,cafe & restaurang personal.
        <br></br> Bakesoft beräknar olika parametrar och skapar<br></br> en komplett  och informativ  miljö
        <br></br> när man som mest behöver viktig<br></br> och avgörande information för att kunna prestera.<br></br>
        <br></br> Applikationen drivs av AI-tjänster i bakgrunden för att<br></br> förbättra användarupplevelsen, och för att <br></br> tillhandahålla extern och utökad<br></br> information på ett mycket enkelt och<br></br> förståeligt sätt.
        Kulinärernas område är stort<br></br> och så omfattande och nästan<br></br> oöverkomligt, vi har tillbringat<br></br> år för att ta fram en unik<br></br> och kreativ applikation<br></br> som kommer hjälpa <br></br>användaren att få ut det mesta<br></br> av sin kulinariska upplevelse.
        <br></br><p></p><p></p> Vi hoppas innerligt att du kommer att<br></br> gå med oss och bli en del av denna unika<br></br> och kreativa resa.<br></br>
        <br></br> Låt oss börja!<br></br>
        <br></br> Anders Hellström VD<br></br>
        www.bakesoft.se<br></br>
    </div>
</div>
    )
  }
  function InfoStartEnglish(){
  
    return(
  
      <div className="infoStartWrapper">
        <div className="infoStartHeader">Welcome</div>
        <div className="infoStartText">Bakesoft.se is a unique SAAS application that brings several 
         important functionalities <br></br>together.It creates a unique and creative place for chefs & bakers, professional or not.
         <br></br> Bakesoft calculates many different parameters and brings forward <br></br> a  complete and informative enviroment for the<br></br>
         chef or the baker when they are in most need of the important<br></br>and crucial information to be able to perform.<br></br><br></br> The application is<br></br> powered by AI services<br></br> in the backgorund to 
         enhance<br></br> the experience for the user,and<br></br> to bring forward external and<br></br> extensive information<br></br> in a very easy and <br></br>understandable way.<br></br>
         The area of culinaries is vast<br></br> and so big and almost<br></br> unconquerable, we have spent<br></br> years to bring forward a unique<br></br> and creative application that will<br></br> help the user to get the most out<br></br> of their culinary experience.<br></br>
        <br></br> We sincereally hope you will join us<br></br> and be a part of this unique<br></br> and creative journey.<br></br>
        <br></br> Lets get started!<br></br>

        Anders Hellström CEO<br></br>
        www.bakesoft.se<br></br>
          
          
          
          
          </div>
      </div>
  
    )
  }
  function InfoStartJapanese(){
  
    return(
  
<div className="infoStartWrapper">
    <div className="infoStartHeaderJAP">ようこそ</div>
    <div className="infoStartTextJapanese">
        Bakesoft.seはユニークなSAASアプリケーショ<br></br>ンです それは多くの重要な機能を 一つ<br></br>にまとめます。プロフェッショナルであれそ<br></br>うでないであれ、シェフやベイカーの<br></br>ためにユニークでクリエイティブな<br></br> 場所を作り出します。
        <br></br> Bakesoftはさまざまなパラメータを計<br></br>算し、シェフやベイカーにとって重要<br></br>で重要な情報を必要とす<br></br>る時に、完全で有益な<br></br>環境を提供します。<br></br><br></br> このアプリケーションは<br></br> AIサービスによって<br></br> 背景で強化されており、<br></br>ユーザーの体験を向上させ、<br></br> 外部からの広範な情報を<br></br> 非常に簡単で<br></br> 理解しやすい方法で提供します。<br></br>
        料理の分野は広大で<br></br> 非常に大きく、ほとんど<br></br> 手に負えないほどですが、私たちは<br></br> 多年にわたってユニークで<br></br> クリエイティブなアプリケーションを開発し、<br></br> ユーザーが料理体験を最大限に<br></br> 活用できるようにしました。<br></br>
        <br></br> 私たちは心から、あなたがこのユニークで<br></br> クリエイティブな旅の一員となることを<br></br> 願っています。
        <br></br> さあ、始めましょう！<br></br>
        <br></br> アンダース・ヘルストロム CEO<br></br>
        www.bakesoft.se<br></br>
    </div>
</div>

  
    )
  }





  

  function StripePayment(stripePromise) {
    let ctx=useContext(MyContext)
    const [clientSecret, setClientSecret] = useState("");
    const [isPaymentComplete, setIsPaymentComplete] = useState(false);
    let styleappearance=ctx.appearance
    let chosencurrency=ctx.getSignupInfo.currency
    let{
      productprice,
      discountToAdd,
      discountPercentage,
      discountAmount,
      currencyName,
      productName,
      periodName,
      currencySign,
      nonStripeAdjustedPrice,
     }=ctx.getPlanInfo




    useEffect(() => {
   if(!clientSecret){
        fetch(`${process.env.REACT_APP_BACKEND_URL}/create-payment-intent`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{userd:ctx.getSignupInfo,id:ctx.getSignupInfo.service,currency:chosencurrency,planIntent:ctx.getSignupInfo.planIntent}] }), // Example se
        })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
          ctx.setClientUpdate(data.clientSecret);
          ctx.updateSignupInfo(data.clientSecret,'transactionId')
          ctx.setGlobalClientSecret(data.clientSecret)
          ctx.updateSignupInfo(data.customer,'customerId')
      })
        .catch((error) => console.error('Error fetching client secret:', error));
    }
    }, []);

    const handlePaymentSuccess = () => {
       // ctx.UpdateStripUserPaymentStatus(ctx.getUserId,ctx.getSignupInfo.service,ctx.getSignupInfo.language)
       ctx.SignUpRequestContinueWith()
        setIsPaymentComplete(true); // Trigger CompletePage render
    };

    return (
        // <div className="CustomApp-App">
        <div className="infosppStripeConfirmBoxWrapper">   
        <div className="infosppStripeConfirmBox">
            <div className="infosppConfirmBoxHeader">{ctx.accountSetupHeaderText}</div> 
            
              <div className="infospfConfirmBoxTop">
                    <div className="infospfConfirmBoxTextSmall">{ctx.accountSetupServiceText}{': '}{productName&&productName.length>0?productName:''}</div>
                    <div className="infospfConfirmBoxTextSmall">{ctx.accountSetupCurrencyText}{': '}{currencyName&&currencyName.length>0?currencyName:''}</div>
                    <div className="infospfConfirmBoxTextSmall">{ctx.accountSetupPeriodText}{': '}{periodName&&periodName.length>0?periodName:''}</div>
            </div>

          <div className="infospfConfirmBoxTextSmallBold">{ctx.accountSetupTotalAmountText}{': '}{nonStripeAdjustedPrice}{' '}{currencyName&&currencyName.length>0?currencyName:''}</div>
          <div className="infospfConfirmBoxTextSmall">{ctx.accountSetupTermsText}:{ctx.accountSetupCancelText}</div>
    
      </div>


                   {clientSecret && (
                    <Elements stripe={ctx.stripePromise} options={{ clientSecret, appearance: styleappearance }}>
                        {isPaymentComplete 
                            ? <CompletePage clientSecret={clientSecret} /> 
                            : <CheckoutForm onPaymentSuccess={handlePaymentSuccess} clientSecret={clientSecret} />
                        }
                    </Elements>
                )}
        </div>
    );
}

  
  
function CheckoutForm({ dpmCheckerLink, onPaymentSuccess, clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    setIsLoading(true);

    // Confirm payment using the PaymentElement
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Change to your actual completion page
        return_url: "https://www.bakesoft.se/returncall?buttonid=22",
      },
      redirect: 'if_required', // Redirect to the URL if no additional authentication is required
    });

    // Handle any errors returned during confirmation
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
    } else {
      // Trigger success callback

      onPaymentSuccess();
    }
  };

  const paymentElementOptions = {
    layout: "tabs", // Allow users to switch between payment methods
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="infoCheckoutButton">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {/* Display any error messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>

      {/* DEV: Show dynamic payment methods annotation and checker */}
      <div id="dpm-annotation">
  
      </div>
    </>
  );
}

// stripe payment confirm const






function CompletePage({ clientSecret }) {
  let ctx = useContext(MyContext)
const stripe = useStripe();
const [status, setStatus] = useState("default");
const [intentId, setIntentId] = useState(null);
// we delete this token since its just used for signing up
localStorage.removeItem('contwithauth_token')

const SuccessIcon =
<svg width="24" height="24" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M15.4695 0.232963C15.8241 0.561287 15.8454 1.1149 15.5171 1.46949L6.14206 11.5945C5.97228 11.7778 5.73221 11.8799 5.48237 11.8748C5.23253 11.8698 4.99677 11.7582 4.83452 11.5681L0.459523 6.44311C0.145767 6.07557 0.18937 5.52327 0.556912 5.20951C0.924454 4.89575 1.47676 4.93936 1.79051 5.3069L5.52658 9.68343L14.233 0.280522C14.5613 -0.0740672 15.1149 -0.0953599 15.4695 0.232963Z" fill="white"/>
</svg>;

const ErrorIcon = 
<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M1.25628 1.25628C1.59799 0.914573 2.15201 0.914573 2.49372 1.25628L8 6.76256L13.5063 1.25628C13.848 0.914573 14.402 0.914573 14.7437 1.25628C15.0854 1.59799 15.0854 2.15201 14.7437 2.49372L9.23744 8L14.7437 13.5063C15.0854 13.848 15.0854 14.402 14.7437 14.7437C14.402 15.0854 13.848 15.0854 13.5063 14.7437L8 9.23744L2.49372 14.7437C2.15201 15.0854 1.59799 15.0854 1.25628 14.7437C0.914573 14.402 0.914573 13.848 1.25628 13.5063L6.76256 8L1.25628 2.49372C0.914573 2.15201 0.914573 1.59799 1.25628 1.25628Z" fill="white"/>
</svg>;

const InfoIcon = 
<svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M10 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V10C1.5 11.3807 2.61929 12.5 4 12.5H10C11.3807 12.5 12.5 11.3807 12.5 10V4C12.5 2.61929 11.3807 1.5 10 1.5ZM4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4Z" fill="white"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H7.25C7.66421 6.25 8 6.58579 8 7V10.5C8 10.9142 7.66421 11.25 7.25 11.25C6.83579 11.25 6.5 10.9142 6.5 10.5V7.75H6C5.58579 7.75 5.25 7.41421 5.25 7Z" fill="white"/>
  <path d="M5.75 4C5.75 3.31075 6.31075 2.75 7 2.75C7.68925 2.75 8.25 3.31075 8.25 4C8.25 4.68925 7.68925 5.25 7 5.25C6.31075 5.25 5.75 4.68925 5.75 4Z" fill="white"/>
</svg>;
const EmailIcon = (
  <svg width="24" height="24" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2C0.447715 2 0 2.44772 0 3V11C0 11.5523 0.447715 12 1 12H15C15.5523 12 16 11.5523 16 11V3C16 2.44772 15.5523 2 15 2H1ZM14.8 3L8 7.5L1.2 3H14.8ZM1 11V3.67L7.53 7.83C7.81471 8.01508 8.18529 8.01508 8.47 7.83L15 3.67V11H1Z"
      fill="black"
    />
  </svg>
);



const STATUS_CONTENT_MAP = {

  succeeded: {
    text:ctx.paymentSucceededText||'Payment succeeded',
    sentEmailText:ctx.paymentSucceededEmailText||'We have sent you an email with information!',
    iconColor: "#30B130",
    icon: SuccessIcon,
    emailIcon: EmailIcon,
  },
  processing: {
    text:ctx.paymentProcessingText||'Payment processing',
    sentEmailText:'',
    iconColor: "#6D6E78",
    icon: InfoIcon,
  },
  requires_payment_method: {
    text:ctx.paymentFailedTextInfo||'Payment failed, try again',
    sentEmailText:'',
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
  default: {
    text:ctx.paymentSomethingWentWrongText||'Something went wrong,try again',
    sentEmailText:'',
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  }
  };

useEffect(() => {
  if (!stripe || !clientSecret) {
    return;
  }

  stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    if (!paymentIntent) {
      return;
    }

    setStatus(paymentIntent.status);
    setIntentId(paymentIntent.id); 
    ctx.setSpinnerLoading(false)
  });
}, [stripe, clientSecret]);

const navigateHandler = () => {
  ctx.navigate('/login')
}

return (
<div id="CustomApp-payment-status" className="infocompleteTopWrapper">
<div id="CustomApp-status-icon" style={{ backgroundColor: STATUS_CONTENT_MAP[status].iconColor }}>
  {STATUS_CONTENT_MAP[status].icon}
</div>
<h2 id="CustomApp-status-text" className="infocompleteMessage">{STATUS_CONTENT_MAP[status].text}</h2>
<div className="infocompleteWrapper">
<div id="CustomApp-status-text">{STATUS_CONTENT_MAP[status].emailIcon}</div>
<div id="CustomApp-status-text" className="infocompleteMessageEmail">{STATUS_CONTENT_MAP[status].sentEmailText}!</div>
</div>
{intentId && (
  <div id="CustomApp-details-table">
    <table>
      <tbody>
        <tr>
        <div className="infocompleteLogin">
          
        <SlLogin className="infocompleteLoginIcon" title={ctx.gotoLoginMessageText}
            onClick={()=>navigateHandler()}/>
        </div>
        </tr>
        <tr>
    
        </tr>
      </tbody>
    </table>
  </div>
)}
</div>

);
}

function PaymentDetailsConfirm() {
  let ctx=useContext(MyContext)
  localStorage.removeItem('contwithauth_token')

      const redirectHandler = () => {
         ctx.navigate(`/login`)
      }

useEffect(() => {
  ctx.setSpinnerLoading(false)
}
,[])

  return(
    <div className="infoppDetailsTop">
    {ctx.redirectStatus === 'succeeded' ? (
      <div className="infoppMessageBoxWrapper">
        <div className="infoppMessageBox">
        <div className="infosppHeader">
          <CheckCircle2 size={26} className="text-green-600 "/>
               <div className="infosppHeaderText">
                  {ctx.successPaymentHeaderText}
              </div>
        </div>
        <div className="infosppConfirmMessageWrapper">
        <div className="infosppConfirmMessage">{ctx.successPaymentText}</div>
    
        <div className="infosppConfirmEmailMessage">
                <div className="infosppEmailIconWrapper"> {ctx.Email}</div>
                <div className="infosppEmailText">{ctx.successPaymentEmailText}</div>
        </div>
        </div>
        {ctx.paymentDetails && (
          <div className="mt-6">
           
            <SlLogin className="infosppLoginIcon" title={ctx.gotoLoginMessageText}
            onClick={()=>redirectHandler()}/>
          
            {/* <p><strong>{ctx.successPaymentAmountText}:</strong> {ctx.paymentDetails.amount/100}</p>
            <p><strong>{ctx.successPaymentServiceText}:</strong> {ctx.getSignupInfo.service}</p> */}
          </div>

          
        )}
      </div>
</div>
    ) : (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-red-600">{ctx.paymentFailedHeaderText}</h1>
        <p className="mt-4 text-gray-600">{ctx.paymentFailedText}</p>
      </div>
    )}
  </div>



  )
}
function PaymentDetailsConfirmNoSuccess() {
  let ctx=useContext(MyContext)
  localStorage.removeItem('contwithauth_token')


useEffect(() => {
  ctx.setSpinnerLoading(false)
}
,[])

  return(
    <div className="infoppDetailsTop">
      <div className="infoppMessageBoxWrapper">
        <div className="infoppMessageBoxFail">
        <div className="infosppHeader">
          <CheckCircle2 size={26} className="infospfCheckCircleFail"/>
               <div className="infosppHeaderTextFail">
                  {ctx.paymentFailedHeaderText}
              </div>
        </div>
        <div className="infosppConfirmMessageWrapper">
        <div className="infosppConfirmMessageFail">{ctx.paymentFailedText}</div>
    
  
        </div>
      </div>
</div>

  </div>



  )
}


const SlidingMenu = (props) => {
  let { isOpen,toggleMenu,
  seHandler,jpHandler,gbHandler,isPartiallyOpen,
   }=props
   let ctx=useContext(MyContext)
   
  return (
    <div className={`sliding-menu ${isOpen ? 'open' : ''} ${isPartiallyOpen ? 'partially-open' : ''}`}>
      <button className="close-button" onClick={toggleMenu}><AiOutlineCloseCircle className="slidingMenuIcon"/></button>
      <div className="slidingMenuWrapper">
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(1)}> <FaHome size={20}/>&nbsp;{ctx.startButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(2)}> <FaRegNewspaper size={20}/>&nbsp;{ctx.signupButtonText}</button>
  
  
     {/* <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(3)}> <FaConciergeBell size={20} />&nbsp;{ctx.applicationButtonText}</button>
      */}
     
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(4)}> <FaRobot size={20}/>&nbsp;{ctx.aiServicesButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(7)}><FaPlus size={20} />&nbsp;Plus</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(12)}><FaFileContract size={20} />&nbsp;{ctx.buyingTermsText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(5)}> <FaInfoCircle size={20}/>&nbsp;{ctx.aboutUsButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(6)}> <FaQuestionCircle size={20} />&nbsp;{ctx.faqButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(11)}> <FaAddressBook size={20} />&nbsp;{ctx.contactInfoText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(10)}> <FaShieldAlt size={20} />&nbsp;{ctx.privacyPolicyText}</button>
  
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(8)}> <FaSignInAlt size={20} />&nbsp;Login</button>
  </div>
    <div className="menuCountryFlagWrapper">
      <SE title="Swedish" className="xzloginflagse" onClick={seHandler} />
      <JP title="Japanese" className="xzloginflagJapan" onClick={jpHandler} />
      <GB title="English" className="xzloginflaggb" onClick={gbHandler} />




      </div>
    </div>
  );
};


function IntegrityPolicy(){
  let {getImageUrl, buttonId,integrityPolicyText} = useContext(MyContext)
  return(
    <div className="integrityPolicyTop">
   

          <div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <div className="faqtransparentBox">

     <div className="integrityPolicyWrapper">
    <div className="integrityPolicyHeaderText">{integrityPolicyText}</div>

                 <div className="integrityPolicyDoc">
                    <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/se`} 
                    style={{ textDecoration: 'none', color:'black' }}
                    >Integritets policy - Swedish</a>
                </div>
                      <div className="integrityPolicyDoc">
                          <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/ja`}
                          style={{ textDecoration: 'none',color:'black' }}
                          >プライバシーポリシー - 日本語</a>
                    </div>
                        <div className="integrityPolicyDoc">
                              <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/en`}
                              style={{ textDecoration: 'none',color:'black' }}
                              >Privacy Policy -  English</a>
                        </div>
                    
                </div>
                
                </div>
                </div>
                </div>
            

      

  

        )
}

function BuyingTerms(){
let ctx = useContext(MyContext)
return(

  <div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${ctx.getImageUrl(ctx.buttonId)})` }}>
    <div className="buyingTermstransparentBox">
      <div className="infoBuyingTermsHeader">Köpvillkor</div>
      <div className="infoBuyingTermsWrapper">
        <div className="infoBuyingTermsText">
        
<div className="allmant-section">
  <h2 className="allmant-heading">1. Allmänt</h2>
  <p className="allmant-paragraph">
    Dessa köpvillkor gäller för alla köp som görs genom <strong>Hellström Trading i Göteborg AB</strong> 
    (Org nr: 556572-0413) via webbplatsen <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>.
    Genom att lägga en beställning på vår webbplats accepterar du att följa dessa villkor.
  </p>
  <p className="allmant-paragraph">
    <strong>Hellström Trading i Göteborg AB</strong> är baserat i Göteborg, Sverige, och alla transaktioner 
    och avtal mellan kunden och företaget regleras av svensk lag. Särskilt gäller <strong>E-handelslagen</strong>, 
    som reglerar rättigheter och skyldigheter för företag och konsumenter vid handel på internet. 
    För mer information om denna lag, besök den officiella webbplatsen för Konsumentverket: 
    <a href="https://www.konsumentverket.se/for-foretag/olika-saljkanaler/regler-nar-du-saljer-pa-internet/e-handelslagen/" 
    target="_blank" rel="noopener noreferrer">E-handelslagen</a>.
  </p>
  <p className="allmant-paragraph">
    Genom att använda <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> 
    &nbsp;bekräftar du att du är minst 18 år gammal eller har förälders samtycke, och att du förstår och accepterar dessa köpvillkor.
  </p>
</div>
<div className="bestallning-section">
  <h2 className="bestallning-heading">2. Beställning</h2>
  <p className="bestallning-paragraph">
    För att göra din första beställning på <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> 
    &nbsp;måste du registrera ett konto. Vid registreringen kan du välja mellan fyra grundläggande tjänstepaket:
  </p>
  <ul className="bestallning-list">
    <li>Grund PLan</li>
    <li>Premium Plan</li>
    <li>Grund Företag</li>
    <li>Premium Företag</li>
  </ul>
  <p className="bestallning-paragraph">
    Du måste ha ett av dessa grundpaket för att kunna hantera ett konto på plattformen. Varje paket ger tillgång till nödvändiga funktioner och verktyg för att hantera dina aktiviteter på webbplatsen.
  </p>
  <p className="bestallning-paragraph">
    Utöver de grundläggande tjänsterna erbjuder <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> cirka 25 tilläggstjänster som du kan välja att lägga till för att förbättra din upplevelse och hjälpa dig att växa inom applikationen. Varje tilläggstjänst debiteras individuellt och du kan välja de tjänster som bäst passar dina behov.
  </p>
  <h3 className="bestallning-subheading">Betalningsvillkor:</h3>
  <p className="bestallning-paragraph">
    Vid beställning av någon tjänst eller tilläggsfunktion krävs betalning i enlighet med de betalningsvillkor som anges i avsnitt 4 av dessa villkor. Alla tjänster och produkter faktureras individuellt och debiteras enligt den prisinformation som ges vid utcheckningen.
  </p>
</div>

<div className="priser-section">
  <h2 className="priser-heading">3. Priser</h2>
  <p className="priser-paragraph">
    Alla priser på <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> inkluderar mervärdesskatt (moms) som styrs av ditt val av land under kontoinställningen. Momssatserna är som följer:
  </p>
  <ul className="priser-list">
    <li>USD/UK: 20% moms</li>
    <li>SEK: 25% moms</li>
    <li>YEN: 10% moms</li>
  </ul>
  <p className="priser-paragraph">
    Företag utanför EU kan ange sitt skattenummer under "Mitt konto", och momsen kommer då att dras från deras faktura.
  </p>
  <p className="priser-paragraph">
    Inga ytterligare avgifter tillkommer. Alla våra paket och tjänster debiteras automatiskt månadsvis. För att undvika att få en ny faktura måste tjänster sägas upp senast den 30:e varje månad (28:e i februari). Uppsägning kan ske när som helst under månaden via ditt konto.
  </p>
</div>

<div className="betalning-section">
  <h2 className="betalning-heading">4. Betalning</h2>
  <p className="betalning-paragraph">
    Vi erbjuder flera betalningsmetoder beroende på ditt land och dina preferenser.
    Vi använder Stripe som sammarbetspartner för att hantera betalningar. Olika betalningsmetodervisas upp beroende på 
    ditt land och dina preferenser.
  </p>
  <ul className="betalning-list">
    <li>PayPal: Tillgängligt för alla länder och alla kunder.</li>
    <li>Klarna: Tillgängligt för kunder i Sverige.</li>
    <li>Swish: Tillgängligt för kunder i Sverige.</li>
  </ul>
  <p className="betalning-paragraph">
    När betalningen har bearbetats av våra betalpartners, kommer vårt system att utföra den tjänst som kunden efterfrågar, vilket innebär att avtalet mellan kunden och <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> är giltigt och bindande.
  </p>
  <p className="betalning-paragraph">
    Våra betalningspartners använder den senaste tekniken för att säkerställa säkerheten vid betalning. <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">Bakesoft.se</a> lagrar inga betalningsuppgifter såsom kortnummer eller annan känslig information. 
  </p>
</div>

<div className="leverans-section">
  <h2 className="leverans-heading">5. Leverans</h2>
  <p className="leverans-paragraph">
    När du betalar för en ny tjänst eller ett nytt konto i applikationen, sker uppsättningen av kontot vanligtvis inom cirka 5 minuter. Eftersom vår verksamhet är helt online har vi inga geografiska restriktioner, och våra tjänster är tillgängliga för kunder över hela världen.
  </p>
  <p className="leverans-paragraph">
    Eventuella förseningar i leveransen skulle bero på att applikationen är otillgänglig, vilket skulle innebära att användarna inte kan komma åt sina konton eller tjänster. I sådana fall kommer vi att meddela användarna om det uppstår problem, antingen på vår sida eller från våra leverantörers sida.
  </p>
</div>

<div className="angerratt-section">
  <h2 className="angerratt-heading">6. Ångerrätt</h2>
  <p className="angerratt-paragraph">
    Eftersom vi endast säljer digitala tjänster, gäller ingen ångerrätt enligt gällande lagstiftning. När en tjänst har betalats och aktiverats anses avtalet vara giltigt och bindande.
  </p>
  <p className="angerratt-paragraph">
    Kunder har rätt att säga upp sina prenumerationer när som helst via sitt konto, men vi erbjuder ingen återbetalning för en redan aktiv och giltig prenumeration.
  </p>
</div>

<div className="reklamation-section">
  <h2 className="reklamation-heading">7. Reklamation och Garanti</h2>
  <p className="reklamation-paragraph">
    Inuti applikationen finns en supportpanel där användare kan göra förfrågningar, lämna klagomål eller ställa frågor om applikationen. Vi strävar efter att besvara alla förfrågningar inom 24 timmar.
  </p>
  <p className="reklamation-paragraph">
    Om det uppstår tekniska problem som orsakas av oss eller våra leverantörer, kommer vi att meddela användarna så snart som möjligt. Vi tar fullt ansvar för att lösa eventuella problem som påverkar applikationens tillgänglighet.
  </p>
  <p className="reklamation-paragraph">
    Som kompensation för förlorad tid i applikationen, på grund av tekniska problem, kommer vi att förlänga användarens tillgång med samma tid som användaren inte kunde använda applikationen.
  </p>
</div>

<div className="force-majeure-section">
  <h2 className="force-majeure-heading">8. Force Majeure</h2>
  <p className="force-majeure-paragraph">
    Vi ansvarar inte för förseningar eller brister i leverans av tjänster om dessa beror på händelser utanför vår kontroll. Detta inkluderar men är inte begränsat till naturkatastrofer, krig, strejker, myndighetsbeslut, pandemier eller större tekniska fel som påverkar vår infrastruktur eller våra leverantörers infrastruktur.
  </p>
  <p className="force-majeure-paragraph">
    I händelse av en force majeure-situation kommer vi att meddela alla användare så snart som möjligt. Under sådana omständigheter kan våra tjänster vara otillgängliga till dess att problemet har lösts. Vi kommer att återuppta tjänsterna så snart situationen tillåter det.
  </p>
  <p className="force-majeure-paragraph">
    Force majeure fritar oss från ansvar för försenad eller utebliven leverans av tjänster under den tid som händelsen varar, men vi kommer att göra vårt bästa för att minimera påverkan på våra kunder.
  </p>
</div>

<div className="tvist-section">
  <h2 className="tvist-heading">9. Tvist</h2>
  <p className="tvist-paragraph">
    Vid en tvist mellan kunden och <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> ska tvisten i första hand lösas genom förhandling mellan parterna. Vi strävar alltid efter att hitta en fredlig lösning som gynnar båda parter.
  </p>
  <p className="tvist-paragraph">
    Om en tvist inte kan lösas genom förhandling, och om rättsliga åtgärder blir nödvändiga, ska alla rättsliga förfaranden ske i domstol i Göteborg, där vårt företag är baserat. Tvisten ska inte behandlas i någon annan jurisdiktion eller ort.
  </p>
</div>

<div className="personuppgifter-section">
  <h2 className="personuppgifter-heading">10. Personuppgifter</h2>
  <p className="personuppgifter-paragraph">
    Vi följer dataskyddsförordningen (GDPR) och skyddar alla våra kunders personuppgifter. De uppgifter vi samlar in sker enbart i samband med kontoregistrering och används för att fakturera kunden samt för att kunna hantera kundens användning av vår applikation.
  </p>
  <p className="personuppgifter-paragraph">
    Kundernas personuppgifter lagras säkert på våra servrar hos Amazon Web Services (AWS), som erbjuder en säker och pålitlig infrastruktur. Vi ser till att dina data skyddas enligt de senaste säkerhetsstandarderna.
  </p>
  <p className="personuppgifter-paragraph">
    All data som lagras på våra servrar tillhör <strong>Hellström Trading i Göteborg AB</strong> och är vårt exklusiva ägande, även om du som användare har fullständig rätt att använda datan i enlighet med våra villkor. Detta klargör att ingen äganderätt överförs till användaren, för att undvika eventuella anspråk på ägande av data.
  </p>
  <p className="personuppgifter-paragraph">
    Vi säljer eller överför aldrig kunduppgifter till tredje part på något sätt, varken inom eller utanför EU. Ditt data används endast för att leverera de tjänster du har beställt från oss.
  </p>
</div>
&nbsp;
&nbsp;


        </div>
      </div>
    </div>
  </div>
)
}


function BuyingTermsENG(){
  let ctx = useContext(MyContext)
  return(
<div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${ctx.getImageUrl(ctx.buttonId)})` }}>
  <div className="buyingTermstransparentBox">
    <div className="infoBuyingTermsHeader">Terms of Purchase</div>
    <div className="infoBuyingTermsWrapper">
      <div className="infoBuyingTermsText">
        
        <div className="allmant-section">
          <h2 className="allmant-heading">1. General</h2>
          <p className="allmant-paragraph">
            These terms of purchase apply to all purchases made through <strong>Hellström Trading i Göteborg AB</strong>
            (Org No: 556572-0413) via the website <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>.
            By placing an order on our website, you agree to follow these terms.
          </p>
          <p className="allmant-paragraph">
            <strong>Hellström Trading i Göteborg AB</strong> is based in Gothenburg, Sweden, and all transactions
            and agreements between the customer and the company are regulated by Swedish law. Specifically, the <strong>E-commerce Act</strong>,
            which regulates the rights and obligations of businesses and consumers in internet commerce.
            For more information about this law, visit the official website of the Swedish Consumer Agency: 
            <a href="https://www.konsumentverket.se/for-foretag/olika-saljkanaler/regler-nar-du-saljer-pa-internet/e-handelslagen/"
              target="_blank" rel="noopener noreferrer">E-commerce Act</a>.
          </p>
          <p className="allmant-paragraph">
            By using <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>, you confirm
            that you are at least 18 years old or have parental consent, and that you understand and accept these terms of purchase.
          </p>
        </div>
        <div className="bestallning-section">
          <h2 className="bestallning-heading">2. Order</h2>
          <p className="bestallning-paragraph">
            To make your first order on <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>, 
            &nbsp;you must register an account. During registration, you can choose from four basic service packages:
          </p>
          <ul className="bestallning-list">
            <li>Entry Plan</li>
            <li>Premium Plan</li>
            <li>Entry Corporate Plan</li>
            <li>Premium Corporate Plan</li>
          </ul>
          <p className="bestallning-paragraph">
            You must have one of these basic packages to manage an account on the platform. Each package provides access to essential features and tools for managing your activities on the website.
          </p>
          <p className="bestallning-paragraph">
            In addition to the basic services, <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> offers approximately 25 add-on services that you can choose to enhance your experience and help you grow within the application. Each add-on service is charged individually, and you can select the services that best suit your needs.
          </p>
          <h3 className="bestallning-subheading">Payment Terms:</h3>
          <p className="bestallning-paragraph">
            When ordering any service or add-on feature, payment is required in accordance with the payment terms stated in section 4 of these terms. All services and products are invoiced individually and charged according to the price information provided at checkout.
          </p>
        </div>

        <div className="priser-section">
          <h2 className="priser-heading">3. Prices</h2>
          <p className="priser-paragraph">
            All prices on <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> include value-added tax (VAT), which is governed by your choice of country during account setup. The VAT rates are as follows:
          </p>
          <ul className="priser-list">
            <li>USD/UK: 20% VAT</li>
            <li>SEK: 25% VAT</li>
            <li>YEN: 10% VAT</li>
          </ul>
          <p className="priser-paragraph">
            Companies outside the EU can enter their tax number under "My Account", and the VAT will be deducted from their invoice.
          </p>
          <p className="priser-paragraph">
            No additional fees apply. All of our packages and services are automatically billed monthly. To avoid receiving a new invoice, services must be canceled by the 30th of each month (28th in February). Cancellations can be made at any time during the month via your account.
          </p>
        </div>

        <div className="betalning-section">
          <h2 className="betalning-heading">4. Payment</h2>
          <p className="betalning-paragraph">
            We offer several payment methods depending on your country and preferences. We use Stripe as a payment partner to handle payments. 
            Different payment methods are displayed depending on your country and preferences.

          </p>
          <ul className="betalning-list">
            <li>PayPal: Available for all countries and all customers.</li>
            <li>Klarna: Available for customers in Sweden.</li>
            <li>Swish: Available for customers in Sweden.</li>
          </ul>
          <p className="betalning-paragraph">
            Once payment has been processed by our payment partners, our system will execute the service requested by the customer, which means that the agreement between the customer and <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> is valid and binding.
          </p>
          <p className="betalning-paragraph">
            Our payment partners use the latest technology to ensure payment security. <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">Bakesoft.se</a> does not store any payment details such as card numbers or other sensitive information.
          </p>
        </div>

        <div className="leverans-section">
          <h2 className="leverans-heading">5. Delivery</h2>
          <p className="leverans-paragraph">
            When you pay for a new service or account in the application, the account setup usually takes about 5 minutes. Since our business is entirely online, we have no geographical restrictions, and our services are available to customers worldwide.
          </p>
          <p className="leverans-paragraph">
            Any delivery delays would occur if the application is unavailable, which would mean that users cannot access their accounts or services. In such cases, we will notify users if problems arise, either on our side or from our suppliers.
          </p>
        </div>

        <div className="angerratt-section">
          <h2 className="angerratt-heading">6. Right of Withdrawal</h2>
          <p className="angerratt-paragraph">
            Since we only sell digital services, no right of withdrawal applies under current legislation. Once a service has been paid for and activated, the agreement is considered valid and binding.
          </p>
          <p className="angerratt-paragraph">
            Customers have the right to cancel their subscriptions at any time via their account, but we do not offer refunds for an already active and valid subscription.
          </p>
        </div>

        <div className="reklamation-section">
          <h2 className="reklamation-heading">7. Complaints and Warranty</h2>
          <p className="reklamation-paragraph">
            Inside the application, there is a support panel where users can make requests, submit complaints, or ask questions about the application. We strive to respond to all requests within 24 hours.
          </p>
          <p className="reklamation-paragraph">
            If technical issues arise that are caused by us or our suppliers, we will notify users as soon as possible. We take full responsibility for resolving any issues that affect the application's availability.
          </p>
          <p className="reklamation-paragraph">
            As compensation for lost time in the application due to technical problems, we will extend the user's access by the same amount of time the user was unable to use the application.
          </p>
        </div>

        <div className="force-majeure-section">
          <h2 className="force-majeure-heading">8. Force Majeure</h2>
          <p className="force-majeure-paragraph">
            We are not responsible for delays or deficiencies in the delivery of services if these are caused by events beyond our control. This includes, but is not limited to, natural disasters, war, strikes, government decisions, pandemics, or major technical failures affecting our infrastructure or that of our suppliers.
          </p>
          <p className="force-majeure-paragraph">
            In the event of a force majeure situation, we will notify all users as soon as possible. Under such circumstances, our services may be unavailable until the issue is resolved. We will resume services as soon as the situation allows.
          </p>
          <p className="force-majeure-paragraph">
            Force majeure exempts us from liability for delayed or non-delivery of services during the time the event lasts, but we will do our best to minimize the impact on our customers.
          </p>
        </div>

        <div className="tvist-section">
          <h2 className="tvist-heading">9. Dispute</h2>
          <p className="tvist-paragraph">
            In the event of a dispute between the customer and <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>, the dispute shall first be resolved through negotiation between the parties. We always strive to find a peaceful solution that benefits both parties.
          </p>
          <p className="tvist-paragraph">
            If a dispute cannot be resolved through negotiation, and if legal action becomes necessary, all legal proceedings shall take place in the court in Gothenburg, where our company is based. The dispute shall not be processed in any other jurisdiction or location.
          </p>
        </div>

        <div className="personuppgifter-section">
          <h2 className="personuppgifter-heading">10. Personal Data</h2>
          <p className="personuppgifter-paragraph">
            We comply with the General Data Protection Regulation (GDPR) and protect all of our customers' personal data. The data we collect is solely for account registration purposes and is used to bill the customer and manage the customer's use of our application.
          </p>
          <p className="personuppgifter-paragraph">
            Customers' personal data is securely stored on our servers with Amazon Web Services (AWS), which provides a secure and reliable infrastructure. We ensure that your data is protected according to the latest security standards.
          </p>
          <p className="personuppgifter-paragraph">
            All data stored on our servers belongs to <strong>Hellström Trading i Göteborg AB</strong> and is our exclusive ownership, although you, as a user, have full rights to use the data in accordance with our terms. This clarifies that no ownership is transferred to the user to avoid any claims of data ownership.
          </p>
          <p className="personuppgifter-paragraph">
            We never sell or transfer customer data to third parties in any way, either within or outside the EU. Your data is only used to deliver the services you have ordered from us.
          </p>
        </div>
        &nbsp;
        &nbsp;

      </div>
    </div>
  </div>
</div>

  )
  }

  
function BuyingTermsJAP() {
  const ctx = useContext(MyContext)
  return (
      <div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${ctx.getImageUrl(ctx.buttonId)})` }}>
          <div className="buyingTermstransparentBox">
              <div className="infoBuyingTermsHeader">購入条件</div>
              <div className="infoBuyingTermsWrapper">
                  <div className="infoBuyingTermsText">
                      <div className="allmant-section">
                          <h2 className="allmant-heading">1. 一般事項</h2>
                          <p className="allmant-paragraph">
                              これらの購入条件は、<strong>Hellström Trading i Göteborg AB</strong>
                              （法人番号: 556572-0413）を通じて、ウェブサイト
                              <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>
                              で行われるすべての購入に適用されます。当社のウェブサイトで注文することにより、これらの条件に従うことに同意したものとみなされます。
                          </p>
                          <p className="allmant-paragraph">
                              <strong>Hellström Trading i Göteborg AB</strong> はスウェーデンのヨーテボリに拠点を置いており、顧客と会社の間のすべての取引および契約は、スウェーデンの法律に基づいています。特に、インターネットでの取引における企業と消費者の権利と義務を規定する<strong>電子商取引法</strong>が適用されます。この法律の詳細については、スウェーデン消費者庁の公式ウェブサイトをご覧ください:
                              <a href="https://www.konsumentverket.se/for-foretag/olika-saljkanaler/regler-nar-du-saljer-pa-internet/e-handelslagen/"
                                  target="_blank" rel="noopener noreferrer">電子商取引法</a>。
                          </p>
                          <p className="allmant-paragraph">
                              <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>を利用することにより、あなたが18歳以上であるか、保護者の同意を得ていることを確認し、これらの購入条件を理解し、受け入れることに同意します。
                          </p>
                      </div>

                      <div className="bestallning-section">
                          <h2 className="bestallning-heading">2. 注文</h2>
                          <p className="bestallning-paragraph">
                              <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>で初めて注文するには、アカウントを登録する必要があります。登録時には、次の4つの基本サービスパッケージから選択できます:
                          </p>
                          <ul className="bestallning-list">
                              <li>ベース</li>
                              <li>トータル</li>
                              <li>企業向けベース</li>
                              <li>企業向けトータル</li>
                          </ul>
                          <p className="bestallning-paragraph">
                              プラットフォーム上でアカウントを管理するには、これらの基本パッケージのいずれかを使用する必要があります。各パッケージには、ウェブサイトでの活動を管理するために必要な機能とツールへのアクセスが含まれています。
                          </p>
                          <p className="bestallning-paragraph">
                              基本サービスに加えて、<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>は、アプリケーション内での体験を向上させ、成長を支援するための約25の追加サービスを提供しています。各追加サービスは個別に課金され、ニーズに最も適したサービスを選択できます。
                          </p>
                          <h3 className="bestallning-subheading">支払い条件:</h3>
                          <p className="bestallning-paragraph">
                              いかなるサービスまたは追加機能を注文する際にも、これらの条件の第4条に記載されている支払い条件に従って支払いが必要です。すべてのサービスと製品は個別に請求され、チェックアウト時に提供される価格情報に従って請求されます。
                          </p>
                      </div>

                      <div className="priser-section">
                          <h2 className="priser-heading">3. 価格</h2>
                          <p className="priser-paragraph">
                              <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>のすべての価格には、アカウント設定時に選択した国によって異なる付加価値税（VAT）が含まれます。VATの税率は以下の通りです:
                          </p>
                          <ul className="priser-list">
                              <li>USD/UK: 20% VAT</li>
                              <li>SEK: 25% VAT</li>
                              <li>YEN: 10% VAT</li>
                          </ul>
                          <p className="priser-paragraph">
                              EU外の企業は、「マイアカウント」で税番号を入力でき、VATが請求書から差し引かれます。
                          </p>
                          <p className="priser-paragraph">
                              追加料金は発生しません。すべてのパッケージとサービスは自動的に毎月請求されます。新しい請求書を避けるためには、サービスは各月の30日（2月は28日）までに解約する必要があります。解約はアカウントを通じていつでも行えます。
                          </p>
                      </div>

                      <div className="betalning-section">
                          <h2 className="betalning-heading">4. 支払い</h2>
                          <p className="betalning-paragraph">
                              国や好みに応じて、以下の支払い方法を提供しています:
                          </p>
                          <ul className="betalning-list">
                              <li>PayPal: すべての国とすべての顧客に対応。</li>
                              <li>Klarna: スウェーデンの顧客向け。</li>
                              <li>Swish: スウェーデンの顧客向け。</li>
                          </ul>
                          <p className="betalning-paragraph">
                              支払いが当社の決済パートナーによって処理されると、当社のシステムは顧客が要求したサービスを実行します。これにより、顧客と<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>との間の契約は有効かつ拘束力のあるものとなります。
                          </p>
                          <p className="betalning-paragraph">
                              当社の決済パートナーは、支払いの安全性を確保するための最新技術を使用しています。<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">Bakesoft.se</a>は、カード番号やその他の機密情報などの支払い情報を保存しません。
                          </p>
                      </div>

                      <div className="leverans-section">
                          <h2 className="leverans-heading">5. 配送</h2>
                          <p className="leverans-paragraph">
                              アプリケーション内で新しいサービスまたはアカウントを支払うと、アカウントの設定は通常約5分以内に完了します。当社のビジネスは完全にオンラインで行われているため、地理的な制限はなく、当社のサービスは世界中の顧客に提供されています。
                          </p>
                          <p className="leverans-paragraph">
                              配送の遅延は、アプリケーションが利用できない場合に発生する可能性があり、ユーザーはアカウントやサービスにアクセスできなくなります。その場合、当社または供給者側で問題が発生した場合には、ユーザーに通知いたします。
                          </p>
                      </div>

                      <div className="angerratt-section">
                          <h2 className="angerratt-heading">6. 取消権</h2>
                          <p className="angerratt-paragraph">
                              当社はデジタルサービスのみを提供しているため、現行の法律に基づいて取消権は適用されません。サービスが支払われ、アクティブ化された時点で契約は有効かつ拘束力のあるものとみなされます。
                          </p>
                          <p className="angerratt-paragraph">
                              顧客はアカウントを通じていつでもサブスクリプションを解約する権利がありますが、既にアクティブで有効なサブスクリプションに対しては払い戻しは行いません。
                          </p>
                      </div>

                      <div className="reklamation-section">
                          <h2 className="reklamation-heading">7. クレームと保証</h2>
                          <p className="reklamation-paragraph">
                              アプリケーション内には、ユーザーがリクエストを行ったり、クレームを提出したり、アプリケーションに関する質問をするためのサポートパネルがあります。当社はすべてのリクエストに24時間以内に対応するよう努めています。
                          </p>
                          <p className="reklamation-paragraph">
                              当社または供給者によって引き起こされた技術的な問題が発生した場合、当社はできるだけ早くユーザーに通知します。当社は、アプリケーションの可用性に影響を与える問題を解決する全責任を負います。
                          </p>
                          <p className="reklamation-paragraph">
                              技術的な問題によりアプリケーションの使用が妨げられた時間に対しては、その期間と同じ期間だけ、ユーザーのアクセスを延長することで補償いたします。
                          </p>
                      </div>

                      <div className="force-majeure-section">
                          <h2 className="force-majeure-heading">8. 不可抗力</h2>
                          <p className="force-majeure-paragraph">
                              私たちは、当社の制御外の事象によりサービスの提供が遅延したり不完全になったりする場合、責任を負いません。これには、自然災害、戦争、ストライキ、政府の決定、パンデミック、または当社や供給者のインフラに影響を与える大規模な技術的な障害が含まれますが、これに限定されません。
                          </p>
                          <p className="force-majeure-paragraph">
                              不可抗力の状況が発生した場合、できるだけ早くすべてのユーザーに通知します。そのような状況下では、問題が解決するまでサービスが利用できない場合があります。状況が許す限り、サービスを再開します。
                          </p>
                          <p className="force-majeure-paragraph">
                              不可抗力は、事象が続いている間、サービスの遅延または提供不能に関する責任を免除しますが、顧客への影響を最小限に抑えるために最善を尽くします。
                          </p>
                      </div>

                      <div className="tvist-section">
                          <h2 className="tvist-heading">9. 紛争</h2>
                          <p className="tvist-paragraph">
                              顧客と<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>の間で紛争が発生した場合、その紛争はまず両当事者間の交渉により解決されるものとします。当社は常に両当事者にとって有益な平和的な解決策を見つけるよう努めています。
                          </p>
                          <p className="tvist-paragraph">
                              交渉によって紛争が解決できない場合、法的措置が必要な場合は、すべての法的手続きは当社の所在地であるヨーテボリの裁判所で行われます。紛争は他の管轄区や場所では処理されません。
                          </p>
                      </div>

                      <div className="personuppgifter-section">
                          <h2 className="personuppgifter-heading">10. 個人情報</h2>
                          <p className="personuppgifter-paragraph">
                              私たちは、一般データ保護規則（GDPR）に準拠して、すべての顧客の個人情報を保護します。収集される情報はアカウント登録時のみであり、顧客に請求書を発行し、アプリケーションの使用を管理するために使用されます。
                          </p>
                          <p className="personuppgifter-paragraph">
                              顧客の個人情報は、Amazon Web Services（AWS）のサーバーに安全に保存され、安全で信頼性の高いインフラを提供します。最新のセキュリティ基準に従ってデータを保護します。
                          </p>
                          <p className="personuppgifter-paragraph">
                              サーバーに保存されているすべてのデータは<strong>Hellström Trading i Göteborg  AB</strong>の所有物であり、当社の専有物です。ただし、ユーザーは当社の条件に従ってデータを使用する完全な権利を有します。これは、データ所有権に対する請求を回避するために、所有権がユーザーに移転されないことを明確にしています。
                          </p>
                          <p className="personuppgifter-paragraph">
                              当社は、EU内外を問わず、いかなる方法でも第三者に顧客情報を販売または移転することはありません。お客様のデータは、お客様が当社から注文したサービスを提供するためにのみ使用されます。
                          </p>
                      </div>
                      &nbsp;
                      &nbsp;
                  </div>
              </div>
          </div>
      </div>
  )
}
    function SignUpPrivateFastMobile(){
let ctx=useContext(MyContext)

let filteredCustomerPrices = ctx.customerPrices.filter((item) => item.cc ===ctx.getCountry)

const[service,setService]=useState(0)
   const serviceHandler = (serviceChoice) => {
    setService(serviceChoice)
  }
 


      return(
        <div className="infoSignUpMobileTopWrapper">
          <div className="infoSignUpMobileServiceWrapper">
            <div className="infoSignUpMobileHeaderText">Select service</div>
            <div className="infoSignUpMobileSelectBox">
              <select className="infospfselect" id="signup" value={ctx.getSignupInfo.service}

              onChange={(e)=>{ctx.updateSignupInfo(e.target.value,'service')
                ;serviceHandler(e.target.value)}}>


                <option value="0">{ctx.selectServiceText}</option>
                {filteredCustomerPrices.map((item,index)=>(
                  <option key={index} value={item.serviceid}>{item.benaemn}</option>
                ))}
              </select>

              <select className="infospfselect" id="signup" value={ctx.getSignupInfo.language}
          onChange={(e)=>ctx.updateSignupInfo(e.target.value,'language')}>
             <option value="0">{ctx.selectLanguageText}</option>
            <option value="se">{ctx.selectSwedLangText}</option>
            <option value="en">{ctx.selectEngLangText}</option>
            <option value="ja">{ctx.selectJapLangText}</option>
          </select>

          <select  className="infospfselect" id="signup" value={ctx.getSignupInfo.localCountry}
                     onChange={(e) => {
                      ctx.updateSignupInfo(e.target.value, 'localCountry');
                     }}
                  
                  >
            <option value="">{ctx.choseCountryText}</option>
            <option value="US">United States</option>
            <option value="Canada">Canada</option>
            <option value="GB">United Kingdom</option>
            <option value="SE">Sweden</option>
            <option value="DE">Germany</option>
            <option value="FR">France</option>
            <option value="JP">Japan</option>
            <option value="AU">Australia</option>
            <option value="IN">India</option>
            <option value="BR">Brazil</option>
            <option value="CN">China</option>
            <option value="KR">South Korea</option>
            <option value="SG">Singapore</option>
            <option value="MX">Mexico</option>
            <option value="NL">Netherlands</option>
            <option value="IT">Italy</option>
            <option value="ES">Spain</option>
            <option value="NO">Norway</option>
            <option value="CH">Switzerland</option>
            <option value="Other">Other</option>
        </select>
        
        <select className="infospfselect"  id="signup" value={ctx.getSignupInfo.currency}
              onChange={(e) => {
                ctx.updateSignupInfo(e.target.value, 'currency');
               }}
          >
            <option value="0">{ctx.selectCurrencyText}</option>
            <option value="1">{ctx.selectSekCurrencyText}</option>
            <option value="2">{ctx.selectUsdCurrencyText}</option>
            <option value="3">{ctx.selectJapCurrencyText}</option>
          </select>

          <select className="infospfselect" id="signup" value={ctx.getSignupInfo.planIntent}
              onChange={(e) => {
                ctx.updateSignupInfo(e.target.value, 'planIntent');ctx.GetPlanPriceInfoHandler(e.target.value);
               }}
          >
            <option value="0">{ctx.selectPlanIntentTypeText}</option>
            <option value="1">{ctx.selectMonthlyPlanText}</option>
            <option value="2">{ctx.selectTrippleMonthPlanText}</option>
            <option value="3">{ctx.selectYearlyPlanText}</option>
          </select>

          <div className="acknowledgeWrapper">
            <div className="acknowledgeText">{ctx.termsText}</div>
            <input type="checkbox" id="acknowledge" name="acknowledge" value="acknowledge"
            className="acknowledgeCheckbox"
            onClick={(e)=>ctx.updateSignupInfo(e.target.checked,'acknowledge')}
            />
          </div>

{service&&!ctx.getSignupInfo.language?
<div className="infoSignUpMobileServiceInfo">
   {service&&service==127&&!ctx.getSignupInfo.language?<BasPrivateInfo />:''}
    {service&&service==128&&!ctx.getSignupInfo.language?<TotalPrivateInfo />:''}
    {service==133&&!ctx.getSignupInfo.language?<BasicCompanyInfo />:''}
    {service==134&&!ctx.getSignupInfo.language?<ProCompanyInfo />:''}
  </div>
  :''}
  {service&&ctx.getSignupInfo.language?
    <PreCheckoutMobile
    service={service}
    />:''}
          
              </div>

          </div>


           
      
        </div>

      )
    }


  function SignUpPrivateFast(){
    let ctx = useContext(MyContext)

    const[boxchoice,setBoxChoice] = useState(0);
    const handleClick = (boxchoice,serviceChoice) => {
     // setIsSelected(!isSelected); // Toggle the selected state
     //toggle the boxchoice
     setBoxChoice(prevState => prevState === boxchoice ? 0 : boxchoice);
    //  setBoxChoice(boxchoice);
      ctx.updateSignupInfo(serviceChoice, 'service')
      ctx.updateSignupInfo('', 'language')
      ctx.updateSignupInfo('', 'localCountry')
      ctx.updateSignupInfo('', 'currency')
      ctx.updateSignupInfo('', 'planIntent')
      ctx.setPlanInfo([{}])


    };

let languageStatus=ctx.getSignupInfo.language
// console.log(!languageStatus?'no language selected':'language selected')

    return(

      <div className="infospfTopWrapper">
        <div className="infospfBoxWrapper">
          <div className="infospfLeftBox">
            <div className="infospfHeaderText">{ctx.signUpSubscriptionHeaderText}</div>

                       <div className="infospfServicesWrapper">
                      <div className="infospfServicesTop">
                        <div className={`infospfServicesBox ${boxchoice===1 ? 'selected' : ''}`} 
                           onClick={()=>handleClick(1,127)}
                           

                          

                           >
                          <div className="infospfInsideBox">

                                    <div className="infospfCheckboxCircle">{ctx.basicPlanText}
                                      {boxchoice===1?<FiCheckCircle className="infospfCheckCircle"
                                      />:''}</div>
                                      <div className="infospfInsideBoxTextA">{ctx.basServiceIdText}</div>
                                      <div className="infospfInsideBoxTextC">{ctx.basFeaturesText}</div>   
                                    </div>
                        </div>


                        <div className={`infospfServicesBox ${boxchoice===2? 'selected' : ''}`} 
                           onClick={()=>handleClick(2,128)}
                           >
                          <div className="infospfInsideBox">

                                    <div className="infospfCheckboxCircle">{ctx.totalPlanText}
                                      {boxchoice===2?<FiCheckCircle className="infospfCheckCircle"
                                    
                                      />:''}</div>
                                      <div className="infospfInsideBoxTextA">{ctx.totalServiceIdText}</div>
                                      <div className="infospfInsideBoxTextC">{ctx.totalFeaturesText}</div>   
                                    </div>
                        </div>


                        <div className={`infospfServicesBox ${boxchoice===3 ? 'selected' : ''}`} 
                           onClick={()=>handleClick(3,133)}
                           >
                          <div className="infospfInsideBox">

                                    <div className="infospfCheckboxCircle">{ctx.basicCorpPlanText}
                                      {boxchoice===3?<FiCheckCircle className="infospfCheckCircle"
                                
                                      />:''}</div>
                                      <div className="infospfInsideBoxTextA">{ctx.basCorpServiceIdText}</div>
                                      <div className="infospfInsideBoxTextC">{ctx.basCorpFeaturesText}</div>   
                                    </div>
                        </div>


                        <div className={`infospfServicesBox ${boxchoice===4 ? 'selected' : ''}`} 
                           onClick={()=>handleClick(4,134)}
                           >
                          <div className="infospfInsideBox">

                                    <div className="infospfCheckboxCircle">{ctx.totalCorpPlanText}
                                      {boxchoice===4?<FiCheckCircle className="infospfCheckCircle"
                           
                                      />:''}</div>
                                      <div className="infospfInsideBoxTextA">{ctx.totalCorpServiceIdText}</div>
                                      <div className="infospfInsideBoxTextC">{ctx.totalCorpFeaturesText}</div>   
                                    </div>
                        </div>
                        </div>

                  
                       </div>
                       <div className="infospfSelectAreaWrapper">

                       <div className="infoservicesArea">
          <select className="infospfselect" id="signup" value={ctx.getSignupInfo.language}
          onChange={(e)=>ctx.updateSignupInfo(e.target.value,'language')}>
             <option value="0">{ctx.selectLanguageText}</option>
            <option value="se">{ctx.selectSwedLangText}</option>
            <option value="en">{ctx.selectEngLangText}</option>
            <option value="ja">{ctx.selectJapLangText}</option>
          </select>


          <select  className="infospfselect" id="signup" value={ctx.getSignupInfo.localCountry}
                     onChange={(e) => {
                      ctx.updateSignupInfo(e.target.value, 'localCountry');
                     }}
                  
                  >
            <option value="">{ctx.choseCountryText}</option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
            <option value="GB">United Kingdom</option>
            <option value="SE">Sweden</option>
            <option value="DE">Germany</option>
            <option value="FR">France</option>
            <option value="JP">Japan</option>
            <option value="AU">Australia</option>
            <option value="IN">India</option>
            <option value="BR">Brazil</option>
            <option value="CN">China</option>
            <option value="KR">South Korea</option>
            <option value="SG">Singapore</option>
            <option value="MX">Mexico</option>
            <option value="NL">Netherlands</option>
            <option value="IT">Italy</option>
            <option value="ES">Spain</option>
            <option value="NO">Norway</option>
            <option value="CH">Switzerland</option>
            <option value="Other">Other</option>
        </select>

          <select className="infospfselect"  id="signup" value={ctx.getSignupInfo.currency}
              onChange={(e) => {
                ctx.updateSignupInfo(e.target.value, 'currency');
               }}
          >
            <option value="0">{ctx.selectCurrencyText}</option>
            <option value="1">{ctx.selectSekCurrencyText}</option>
            <option value="2">{ctx.selectUsdCurrencyText}</option>
            <option value="3">{ctx.selectJapCurrencyText}</option>
          </select>

          <select className="infospfselect" id="signup" value={ctx.getSignupInfo.planIntent}
              onChange={(e) => {
                ctx.updateSignupInfo(e.target.value, 'planIntent');ctx.GetPlanPriceInfoHandler(e.target.value);
               }}
          >
            <option value="0">{ctx.selectPlanIntentTypeText}</option>
            <option value="1">{ctx.selectMonthlyPlanText}</option>
            <option value="2">{ctx.selectTrippleMonthPlanText}</option>
            <option value="3">{ctx.selectYearlyPlanText}</option>
          </select>
       

          <div className="acknowledgeWrapper">
            <div className="acknowledgeText">{ctx.termsText}</div>
            <input type="checkbox" id="acknowledge" name="acknowledge" value="acknowledge"
            className="acknowledgeCheckbox"
            onClick={(e)=>ctx.updateSignupInfo(e.target.checked,'acknowledge')}
            />
          </div>


        </div>
     
      

  </div>
</div>





          <div className="infospfRightBox">
                     {boxchoice === 4 && (!ctx.getSignupInfo.language || ctx.getSignupInfo.language === '') ? (
                      <ProCompanyInfo />
                    ) : boxchoice === 3 && !ctx.getSignupInfo.language ? (
                     <BasicCompanyInfo />
                    ) : boxchoice === 1 && !ctx.getSignupInfo.language ? (
                    <BasPrivateInfo />
                    ) : boxchoice === 2 && !ctx.getSignupInfo.language ? (
                      <TotalPrivateInfo />
                    ) : boxchoice &&ctx.getSignupInfo.language? (
                      <PreCheckout boxchoice={boxchoice} />
                    ) : (
                      ''
                    )}
          </div>
      

        </div>
        
      </div>
    )
  }

  function PreCheckoutMobile(props){
    let {service}=props
    let ctx=useContext(MyContext)
    let imagelink=localStorage.getItem('ContinueWithimage_link')
    let{
     productprice,
     discountToAdd,
     discountPercentage,
     discountAmount,
     currencyName,
     nonStripeAdjustedPrice,
    }=ctx.getPlanInfo

    // here we recive the currency in full and then we convert it to the currency code
let currencyInFull=ctx.getSignupInfo.currency
let currencyCC=''
if(currencyInFull==='1'){
  currencyInFull='SEK'
  currencyCC='se'
}else if(currencyInFull==='2'){
  currencyInFull='USD'
  currencyCC='en'
}else if(currencyInFull==='3'){
  currencyInFull='YEN'
  currencyCC='ja'
}else if(currencyInFull==='4'){
  currencyInFull='GBP'
  currencyCC='gb'
}else if(currencyInFull==='5'){
  currencyInFull='CAD'
  currencyCC='ca'
}else if(currencyInFull==='6'){
  currencyInFull='AUD'
  currencyCC='au'
}
else if(currencyInFull==='7'){
  currencyInFull='BRL'
  currencyCC='br'
}
else if(currencyInFull==='8'){
  currencyInFull='KRW'
  currencyCC='kr'
}else if(currencyInFull==='9'){
  currencyInFull='SGD'
  currencyCC='sg'
}else if(currencyInFull==='10'){
  currencyInFull='MXN'
  currencyCC='mx'
}else if(currencyInFull==='11'){
  currencyInFull='NOK'
  currencyCC='no'
}else if(currencyInFull==='12'){
  currencyInFull='CHF'
  currencyCC='ch'
}else if(currencyInFull==='13'){
  currencyInFull='EUR'
  currencyCC='eu'
}else if(currencyInFull==='14'){
  currencyInFull='DKK'
  currencyCC='dk'
}


    let languageInFull=ctx.getSignupInfo.language
    if(languageInFull==='se'){
      languageInFull='Svenska'
    }else if(languageInFull==='en'){
      languageInFull='English'
    }else if(languageInFull==='ja'){
      languageInFull='日本語'
    }

    let planInFull=service
    let planInfo=''
    let chosenPlan=''
    if(planInFull==127){
     planInfo= ctx.basicPlanText
   //  chosenPlan=basicPriceWithXchange

    }else if(planInFull==128){
      planInfo=ctx.totalPlanText
    }else if(planInFull==133){
      planInfo=ctx.basicCorpPlanText
    }else if(planInFull==134){
      planInfo=ctx.totalCorpPlanText
    }

    let planIntent=ctx.getSignupInfo.planIntent
    let planIntentInfo=''
    let planDiscountText=''
    let planDiscountAmount=''
    let discountedAmount=''
    if(planIntent==='1'){
      planIntentInfo=ctx.selectMonthlyPlanText
    }
    else if(planIntent==='2'){
      planIntentInfo=ctx.selectTrippleMonthPlanText
      planDiscountText=ctx.discountText
    }
    else if(planIntent==='3'){
      planIntentInfo=ctx.selectYearlyPlanText
      planDiscountText=ctx.discountText
    }

 let countryCurrencySymbol='SEK'

let countryFullName=ctx.getSignupInfo.localCountry
let vatCharge=''
if(countryFullName==='US'){
  countryFullName='United States'
}else if(countryFullName==='CA'){
  countryFullName='Canada'
}else if(countryFullName==='GB'){
  countryFullName='United Kingdom'
}else if(countryFullName==='SE'){
  countryFullName='Sweden'
}else if(countryFullName==='DE'){
  countryFullName='Germany'
}else if(countryFullName==='FR'){
  countryFullName='France'
}else if(countryFullName==='JP'){
  countryFullName='Japan'
}else if(countryFullName==='AU'){
  countryFullName='Australia'
}else if(countryFullName==='IN'){
  countryFullName='India'
}else if(countryFullName==='BR'){
  countryFullName='Brazil'
}else if(countryFullName==='CN'){
  countryFullName='China'
}else if(countryFullName==='KR'){
  countryFullName='South Korea'
}else if(countryFullName==='SG'){
  countryFullName='Singapore'
}else if(countryFullName==='MX'){
  countryFullName='Mexico'
}else if(countryFullName==='NL'){
  countryFullName='Netherlands'
}else if(countryFullName==='IT'){
  countryFullName='Italy'
}else if(countryFullName==='ES'){
  countryFullName='Spain'
}else if(countryFullName==='NO'){
  countryFullName='Norway'
}else if(countryFullName==='CH'){
  countryFullName='Switzerland'
}
 else if (countryFullName === 'DK') {
  countryFullName = 'Denmark';
 }
else if(countryFullName==='Other'){
  countryFullName='Other'
}

    return(    
    <div className="preCheckWrapper">
          <div className="preCheckHeaderWrapper">
          <div className="preCheckHeader">{ctx.orderOverviewText}</div>
                <img src={imagelink||ctx.bslogo} className="preCheckImage" />
      </div>

      <div className="preCheckNameInfoWrapper">
          <div className="preCheckName">{ctx.getSignupInfo.fullName}</div>
          <div className="preCheckEmail">{ctx.getSignupInfo.email}</div>
      </div>
     <div className="preCheckMidWrapper">
      <div className="preCheckOutMobileWrapper">
 
      <div className="preCheckPlanLabel">{ctx.plandetailText}</div>
      <div className="preCheckMidSize"></div>
      </div>
      <div className="preCheckRowWrapper">

        <div classname="preCheckIconRow"><GrPlan className="preCheckIconMid"/></div>
             <div className="preCheckPlanText">&nbsp;Plan:{planInfo}</div>
      </div>
      <div className="preCheckMidSize"></div>
      <div className="preCheckRowWrapper">
      <div classname="preCheckIconRow"><IoMdBook className="preCheckIconLarge"/></div>
              <div className="preCheckPlanText"> {ctx.prepayLanguageHeader}: {languageInFull} </div>
      </div>
     
      <div className="preCheckMidSize"></div>
      <div className="preCheckRowWrapper">
      <div classname="preCheckIconRow"><BiWorld className="preCheckIconLarge"/></div>
              <div className="preCheckPlanText">{ctx.prepayCountryHeader}: {countryFullName} </div>
      </div>

      <div className="preCheckMidSize"></div>
      <div className="preCheckRowWrapper">
      <div classname="preCheckIconRow"><GrCurrency className="preCheckIconLarge"/></div>
              <div className="preCheckPlanText">{ctx.prepayCurrencyHeader}: {currencyInFull} </div>
      </div>


    <div className="preCheckMidSize"></div>
      <div className="preCheckRowWrapper">
      <div classname="preCheckIconRow"><RxLapTimer className="preCheckIconLarge"/></div>
              <div className="preCheckPlanText">{ctx.prepayPeriodHeader}: {planIntentInfo}</div>
      </div>
    </div>

<div className="preCheckFootWrapper">
  <div className="preCheckPlanLabel">{ctx.paymentDetailsHeaderText}</div>

  <div className="preCheckRowWrapperLow">
    <span className="preCheckPlanText">{ctx.paymentDetailsCurrencyText}:</span>
    <span className="preCheckPlanText">{currencyInFull}</span>
  </div>

  <div className="preCheckRowWrapperLow">
    <div className="preCheckPlanText">{ctx.paymentPlanText}:</div>
    <div className="preCheckPlanText">{planIntentInfo}</div>
  </div>
  <hr className="preCheckHR" />

  {discountAmount > 0 && (
    <div className="preCheckColumnWrapperLow">
      <div className="preCheckPlanTextBoldGreen">
        {planDiscountText} {Math.round(discountPercentage)}% - {Math.round(discountAmount)} {currencyName}
      </div>
    </div>
  )}
</div>

<div className="preCheckPayWrapper">
        {productprice && (
          <>
            <div className="preCheckPlanTextBold">{ctx.paymentTotalToPayText}:</div>
            <div className="preCheckPlanTextBold">{nonStripeAdjustedPrice} {currencyName}</div>
          </>
        )}
      </div>

      <div className="preCheckButtonArea">
      <button
        className="preCheckButton"
        onClick={() => { ctx.setButtonId(21); }}
        disabled={
          !ctx.getSignupInfo.language ||
          !ctx.getSignupInfo.localCountry ||
          !ctx.getSignupInfo.currency ||
          !ctx.getSignupInfo.planIntent ||
          !ctx.getSignupInfo.acknowledge
        }
      >
        {ctx.signUpButtonText}
      </button>
    </div>
    </div>


    
  
    )
  }


  function PreCheckout(props) {
    let {boxchoice}=props
    let ctx=useContext(MyContext)
       let imagelink=localStorage.getItem('ContinueWithimage_link')
       let{
        productprice,
        discountToAdd,
        discountPercentage,
        discountAmount,
        currencyName,
        nonStripeAdjustedPrice,
       }=ctx.getPlanInfo

      


// here we recive the currency in full and then we convert it to the currency code
let currencyInFull=ctx.getSignupInfo.currency
let currencyCC=''
if(currencyInFull==='1'){
  currencyInFull='SEK'
  currencyCC='se'
}else if(currencyInFull==='2'){
  currencyInFull='USD'
  currencyCC='en'
}else if(currencyInFull==='3'){
  currencyInFull='YEN'
  currencyCC='ja'
}else if(currencyInFull==='4'){
  currencyInFull='GBP'
  currencyCC='gb'
}else if(currencyInFull==='5'){
  currencyInFull='CAD'
  currencyCC='ca'
}else if(currencyInFull==='6'){
  currencyInFull='AUD'
  currencyCC='au'
}
else if(currencyInFull==='7'){
  currencyInFull='BRL'
  currencyCC='br'
}
else if(currencyInFull==='8'){
  currencyInFull='KRW'
  currencyCC='kr'
}else if(currencyInFull==='9'){
  currencyInFull='SGD'
  currencyCC='sg'
}else if(currencyInFull==='10'){
  currencyInFull='MXN'
  currencyCC='mx'
}else if(currencyInFull==='11'){
  currencyInFull='NOK'
  currencyCC='no'
}else if(currencyInFull==='12'){
  currencyInFull='CHF'
  currencyCC='ch'
}else if(currencyInFull==='13'){
  currencyInFull='EUR'
  currencyCC='eu'
}else if(currencyInFull==='14'){
  currencyInFull='DKK'
  currencyCC='dk'
}


    let languageInFull=ctx.getSignupInfo.language
    if(languageInFull==='se'){
      languageInFull='Svenska'
    }else if(languageInFull==='en'){
      languageInFull='English'
    }else if(languageInFull==='ja'){
      languageInFull='日本語'
    }

    let planInFull=boxchoice
    let planInfo=''
    let chosenPlan=''
    if(planInFull===1){
     planInfo= ctx.basicPlanText
   //  chosenPlan=basicPriceWithXchange

    }else if(planInFull===2){
      planInfo=ctx.totalPlanText
    }else if(planInFull===3){
      planInfo=ctx.basicCorpPlanText
    }else if(planInFull===4){
      planInfo=ctx.totalCorpPlanText
    }

    let planIntent=ctx.getSignupInfo.planIntent
    let planIntentInfo=''
    let planDiscountText=''
    let planDiscountAmount=''
    let discountedAmount=''
    if(planIntent==='1'){
      planIntentInfo=ctx.selectMonthlyPlanText
    }
    else if(planIntent==='2'){
      planIntentInfo=ctx.selectTrippleMonthPlanText
      planDiscountText=ctx.discountText
    }
    else if(planIntent==='3'){
      planIntentInfo=ctx.selectYearlyPlanText
      planDiscountText=ctx.discountText
    }

 let countryCurrencySymbol='SEK'

let countryFullName=ctx.getSignupInfo.localCountry
let vatCharge=''
if(countryFullName==='US'){
  countryFullName='United States'
}else if(countryFullName==='CA'){
  countryFullName='Canada'
}else if(countryFullName==='GB'){
  countryFullName='United Kingdom'
}else if(countryFullName==='SE'){
  countryFullName='Sweden'
}else if(countryFullName==='DE'){
  countryFullName='Germany'
}else if(countryFullName==='FR'){
  countryFullName='France'
}else if(countryFullName==='JP'){
  countryFullName='Japan'
}else if(countryFullName==='AU'){
  countryFullName='Australia'
}else if(countryFullName==='IN'){
  countryFullName='India'
}else if(countryFullName==='BR'){
  countryFullName='Brazil'
}else if(countryFullName==='CN'){
  countryFullName='China'
}else if(countryFullName==='KR'){
  countryFullName='South Korea'
}else if(countryFullName==='SG'){
  countryFullName='Singapore'
}else if(countryFullName==='MX'){
  countryFullName='Mexico'
}else if(countryFullName==='NL'){
  countryFullName='Netherlands'
}else if(countryFullName==='IT'){
  countryFullName='Italy'
}else if(countryFullName==='ES'){
  countryFullName='Spain'
}else if(countryFullName==='NO'){
  countryFullName='Norway'
}else if(countryFullName==='CH'){
  countryFullName='Switzerland'
}
 else if (countryFullName === 'DK') {
  countryFullName = 'Denmark';
 }
else if(countryFullName==='Other'){
  countryFullName='Other'
}
return (
<div className="infopre-container">
  <div className="infopre-card">
    <div className="infopre-header">
      <div className="infopre-title">
        <div className="infopre-signup-image-top">
          {ctx.orderOverviewText}
          <img src={imagelink} className="infopre-signup-image" />
        </div>
        <div className="infopre-signup-name-top">{ctx.getSignupInfo.fullName}</div>
        <div className="infopre-signup-email-top">{ctx.getSignupInfo.email}</div>
      </div>
    </div>

    <div className="infopre-content">
      <div className="infopre-plan-details">
        <label className="infopre-plan-label">{ctx.plandetailText}</label>
        <div className="infopre-plan-card">
          <div className="infopre-plan-card-content">
            <div className="infopre-plan-info">
              <span className="infopre-icon"><EntryPlanIcon/></span>
              <span className="infopre-plan-text">{planInfo}</span>
            </div>
            <div className="infopre-plan-info">
              <span className="infopre-icon"><LanguageIcon/></span>
              <span className="infopre-plan-text">
                {ctx.prepayLanguageHeader}: {languageInFull}
              </span>
            </div>
            <div className="infopre-plan-info">
              <span className="infopre-icon"><CountryIcon/></span>
              <span className="infopre-plan-text">
                {ctx.prepayCountryHeader}: {countryFullName}
              </span>
            </div>
            <div className="infopre-plan-info">
              <span className="infopre-icon"><CurrencyIcon/></span>
              <span className="infopre-plan-text">
                {ctx.prepayCurrencyHeader}: {currencyInFull}
              </span>
            </div>
            <div className="infopre-plan-info">
              <span className="infopre-icon"><PeriodIcon/></span>
              <span className="infopre-plan-text">
                {ctx.prepayPeriodHeader}: {planIntentInfo}
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <hr />

      <div className="infopre-payment-details">
        <label className="infopre-payment-label">{ctx.paymentDetailsHeaderText}</label>
        <div className="infopre-payment-info">
          <span className="infopre-payment-text">{ctx.paymentDetailsCurrencyText}</span>
          <span className="infopre-payment-value">{currencyInFull}</span>
        </div>
        <div className="infopre-payment-info">
          <span className="infopre-payment-text">{ctx.paymentPlanText}</span>
          <span className="infopre-payment-value">{planIntentInfo}</span>
        </div>
        {discountAmount > 0 && (
          <div className="infopre-discount-info">
            <span className="infopre-discount-text"></span>
            <span className="infopre-discount-value text-green-600">
              {planDiscountText} {Math.round(discountPercentage)}% - {Math.round(discountAmount)} {currencyName}
            </span>
          </div>
        )}
      </div>
      
      <hr />

      <div className="infopre-total-info">
        {productprice && (
          <>
            <div className="infopre-total-info">{ctx.paymentTotalToPayText}</div>
            <div className="infopre-total-info">{nonStripeAdjustedPrice} {currencyName}</div>
          </>
        )}
      </div>
    </div>

    <div className="infopre-footer">
      <button
        className="infopre-button"
        onClick={() => { ctx.setButtonId(21); }}
        disabled={
          !ctx.getSignupInfo.language ||
          !ctx.getSignupInfo.localCountry ||
          !ctx.getSignupInfo.currency ||
          !ctx.getSignupInfo.planIntent ||
          !ctx.getSignupInfo.acknowledge
        }
      >
        {ctx.signUpButtonText}
      </button>
    </div>
  </div>
</div>


);


  }

  function BakesoftContinueWith(){
    let ctx=useContext(MyContext)
    // if user had previous been to login we need to remove the previous login details
    localStorage.removeItem('contwithauth_token')
    localStorage.removeItem('ContinueWithimage_link')

return(


<div className="infocontinueTopWrapper">
  <div className="infocontinueBoxWrapper">
    <div className="infocontinueLoginBoxOuter">
    <div className="infocontinueLoginBox">
      <div className="infocontinueHeaderWrapper">
        <div className="infocontinueHeaderText">{ctx.welcomeLabelText}</div>
        <div className="infocontinueHeaderTextSmall">{ctx.signUpContinueLabelText}</div>
      
      </div>
   


  
       <div className="infocontinueHRWrapper">
           <div className="infocontinueHR"></div>
         </div>

<div className="infocontinueMidWrapper">

        <div className="infocontinueSocialWrapper">
        <div className="infocontinueGoogleButton"
        onClick={() => ctx.handleSocialLogin('Google')}>
      <svg
className="infocontinueGoogleIcon"
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
  fill="#4285F4"
/>
<path
  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
  fill="#34A853"
/>
<path
  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
  fill="#FBBC05"
/>
<path
  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
  fill="#EA4335"
/>
<path d="M1 1h22v22H1z" fill="none" />
</svg>
<div className="infocontinueGoogleText">
      {ctx.continueWithGoogleText}</div>
    </div>
</div>


<div className="infocontinueSocialWrapper">
    <div className="infocontinueFacebookButton"
    onClick={() => ctx.handleSocialLogin('Facebook')}
    >
    <svg className="infocontinueFacebookIcon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          fill="white"  // Ensures the icon is white on the blue background
          d="M22.675 0h-21.35C.595 0 0 .592 0 1.324v21.351C0 23.408.595 24 1.324 24h11.495v-9.294H9.688v-3.622h3.131V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24h-1.918c-1.504 0-1.794.715-1.794 1.763v2.31h3.588l-.467 3.622h-3.121V24h6.116c.729 0 1.324-.592 1.324-1.324V1.324C24 .592 23.405 0 22.675 0z"
        />
      </svg>
      <div className="infocontinueFacebookText">
      {ctx.continueWithFacebookText} </div>
    </div>

</div>


<div className="infocontinueSocialWrapper">
          <div className="infocontinueLineButton"
          
          onClick={() => ctx.handleSocialLogin('Line')}
          >
            <img src={btn_base} className="infocontinueLineIcon" /> 
        
          <div className="infocontinueLineText">{ctx.continueWithLineText}</div>
          </div>
    
</div>


            <div className="infocontinueHRWrapper">
                 <div className="infocontinueORText">{ctx.orLabelText}</div>
           </div>
    
           <input id="email" className="infocontinueInput" required 
    onChange={(e) => ctx.setSignupInfo(prevState => ({ ...prevState, email: e.target.value }))}
    placeholder={ctx.emailLabelText} />




<input id="name" className="infocontinueInput"  required 
  onChange={(e) => 
    ctx.setSignupInfo(prevState => ({
      ...prevState,
      fullName: e.target.value
    }))
  } 
  placeholder={ctx.fullNameLabelText} 
/>



<input id="name" className="infocontinueInput"  required 
  onChange={(e) => 
    ctx.setSignupInfo(prevState => ({
      ...prevState,
      password: e.target.value
    }))
  } 
  placeholder={ctx.passwordText} 
/>



<input id="name" className="infocontinueInput"  required 
  onChange={(e) => 
    ctx.setSignupInfo(prevState => ({
      ...prevState,
      confirmPassword: e.target.value
    }))
  } 
  placeholder={ctx.passwordConfirmText}
/>



    <button className={!ctx.getSignupInfo.email || !ctx.getSignupInfo.fullName || !ctx.getSignupInfo.password|| !ctx.getSignupInfo.confirmPassword?"infocontinueButton1":"infocontinueButton"}
     onClick={() => { ctx.CheckMail(); }}
      disabled={!ctx.getSignupInfo.email || !ctx.getSignupInfo.fullName || !ctx.getSignupInfo.password|| !ctx.getSignupInfo.confirmPassword}
      >
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 30"
    width="20"
    height="20"
    class="arrow-icon"
  >
    <path
      d="M14 5l7 7-7 7M5 12h16"
      stroke="#ffffff"
      stroke-width="4"
      fill="none"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>&nbsp;
  {ctx.continueLabelText}
      </button>


</div>


          </div>
        

      </div>
    </div>
    </div>
      );      
        
 }

 // company services information components below

function ProCompanyInfo(){
  let ctx = useContext(MyContext)
  return(
    <><div className="basicServicesText">
       <div className="infospfListHeaderThick">{ctx.planFeaturesText}</div>
      <div className="infospfListHeader">{ctx.basPrivateIncludedText}</div>
          <div className="infospfList">
               <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                   {ctx.totalBasIncludedText}</div>
          </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.totalUppTo800RecipeText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                  {ctx.totalProductsMax500Text}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.totalProductionText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.totalAutomaticRecipeSummaryText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.totalAutomaticIngredientSummaryText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.totalAutomaticProductionStatisticText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.totalCreatePricelistText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.total200ProductCategoriesText}</div>
                </div>
  
  
    </div>
</>

  )
}

function BasicCompanyInfo(){
  let ctx = useContext(MyContext)




  return(
    <><div className="basicServicesText">
      <div className="infospfListHeaderThick">{ctx.planFeaturesText}</div>
          <div className="infospfList">
               <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                   {ctx.basCorporateSave500RecipeText}</div>
          </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpUse500IngredientsText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                  {ctx.basCorpCreate200IngredientCategoriesText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpCreateRecipeWithAiText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.basCorpCreateRecipeManuallyWithIngredientsText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpSearchRecipeIdeaText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpCreateImageWithAiText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpUploadOwnImageText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpSave1000ImagesText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpCreate200RecipeCategoriesText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpAutomaticNutrientCalculationText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpAutomaticCostCalculationText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpAutomaticMatchIngredientsText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpAutomaticGetNutrientsText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpInformationsAboutIngredientsText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpConvertVolumeToGramText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpManageIngredientRecipeLinksText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basCorpMoveManageLinksAutomaticallyText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.basCorpManageIngredientRecipeLinksText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.basCorpMoveManageLinksAutomaticallyText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.basCorpManageIngredientRecipeLinksText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.basCorpIngredientPriceText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.basCorpAutomaticPriceDiagramText}</div>
                </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
               {ctx.basCorpHumoristicCommentsText}</div>
                </div>
  
    </div>
</>

  )
}

function BasPrivateInfo(){
  let ctx=useContext(MyContext)

  return(
    <>
<div className="basicServicesText">
<div className="infospfListHeaderThick">{ctx.planFeaturesText}</div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basPrivateSave300RecipeText}</div>

          </div>
          <div className="infospfList">
                <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
                    {ctx.basPrivateUse500IngredientsText}</div>
          </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateCreate100IngredientCategoriesText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateSearchCreateRecipeAiText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateCreateRecipeManuallyWithIngredientsText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateSearchRecipeIdeaText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateCreateImageWithAiText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateUploadOwnImageText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateSave400ImagesText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateCreate100RecipeCategoriesText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateAutomaticNutrientCalculationText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateAutomaticCostCalculationText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateAutomaticMatchIngredientsText}</div>

    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateAutomaticGetNutrientsText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateInformationsAboutIngredientsText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateConvertVolumeToGramText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateManageIngredientRecipeLinksText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateMoveManageLinksAutomaticallyText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateIngredientPriceText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateAutomaticPriceDiagramText}</div>
    </div>
    <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.basPrivateHumoristicCommentsText}</div>
    </div>
    </div>
</>
            
  )


}

function TotalPrivateInfo(){

  let ctx = useContext(MyContext)



  return(
    <>
<div className="basicServicesText">
<div className="infospfListHeaderThick">{ctx.planFeaturesText}</div>
<div className="infospfListHeader">{ctx.basPrivateIncludedText}</div>
     <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.totalPrivateBaseIncludedText}</div>
          </div>
      <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.totalUppTo400RecipeText}</div>
          </div>
      <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
            {ctx.totalProductsMax300Text}</div>
          </div>
      <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.totalProductionText2}</div>
          </div>
      <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
         {ctx.totalAutomaticRecipeSummaryText2}</div>
          </div>
      <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.totalAutomaticIngredientSummaryText2}</div>
          </div>
      <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.totalAutomaticProductionStatisticText2}</div>
          </div>
          <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.createProductionMax250Text}</div>
          </div>
          <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.createPricelistWithMarginText}</div>
              </div>
          <div className="infospfList">
          <div className="infospfTextRow"><IoShieldCheckmarkOutline className="infospfCheckIcon" />
              {ctx.createUpTo100ProductCategoriesText}</div>
            </div>
     
      </div>
      </>

  )
}

export {Faq,InfoStartSwedish,InfoStartEnglish,InfoStartJapanese,
  PlusServices,SlidingMenu,IntegrityPolicy,BuyingTerms,SignUpPrivateFast,
  BuyingTermsENG,BuyingTermsJAP,ProCompanyInfo,BasicCompanyInfo,BasPrivateInfo,TotalPrivateInfo,
  PreCheckout,BakesoftContinueWith,PaymentDetailsConfirm,CompletePage,
PaymentDetailsConfirmNoSuccess}
