import React, { useEffect, useState } from 'react';
import './CookieConsent.scss'; // Import the SCSS file
import translations from './CoockieConsentMessage.json';
const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const[getCountry,setCountry]=useState('')
    const[getDetectedLanguage,setDetectedLanguage]=useState('')

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true); // Show the alert if no consent is found
    }
  }, []);

  console.log(getCountry)
  console.log(getDetectedLanguage)

  // language settings
useEffect(() => {
    const detectLanguage = () => {
      const userLanguage = navigator.language || navigator.userLanguage;
      if (userLanguage.startsWith('sv')) {
        setCountry('se')
        return 'se'; // Swedish detected
      } else if (userLanguage.startsWith('en')) {
        setCountry('en')
        return 'en'; // English detected
      } else if (userLanguage.startsWith('ja')) {
        setCountry('ja')
        return 'ja'; // Japanese detected
      } else if(userLanguage.startsWith('da')){
        setCountry('da')
        return 'da'; // Danish detected
        }else if(userLanguage.startsWith('de')){
        setCountry('de')
        return 'de'; // German detected
        }
        else if(userLanguage.startsWith('es')){
        setCountry('es')
        return 'es'; // Spanish detected
        }
        else if(userLanguage.startsWith('fr')){
        setCountry('fr')
        return 'fr'; // French detected
        }
        else if(userLanguage.startsWith('it')){
        setCountry('it')
        return 'it'; // Italian detected
        }
        else if(userLanguage.startsWith('nl')){
        setCountry('nl')
        return 'nl'; // Dutch detected
        }
        else if(userLanguage.startsWith('no')){
        setCountry('no')
        return 'no'; // Norwegian detected
        }
        else if(userLanguage.startsWith('fi')){
        setCountry('fi')
        return 'fi'; // Finnish detected
        }
        else if(userLanguage.startsWith('zh')){
        setCountry('zh')
        return 'zh'; // Chinese detected
        }
        else if(userLanguage.startsWith('ko')){
        setCountry('ko')
        return 'ko'; // Korean detected
        }else{
         setCountry('en')
        return 'en'; // Default to English if no match
        }
      
   
    };
  
    const detectedCountry = detectLanguage();
    setDetectedLanguage(detectedCountry);
  }, []);

  const language = getDetectedLanguage||getCountry;
  const cookieConsentMessage = translations[language]?.cookieConsent?.message || "Default message here.";
  const acceptButtonText = translations[language]?.cookieConsent?.accept || "Accept";
  const declineButtonText = translations[language]?.cookieConsent?.decline || "Decline";




  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false); // Hide the alert
  };

  return (
    <div className={`cookie-consent ${isVisible ? 'visible' : ''}`}>
      <div className="cookie-consent-content">
        <p>
            {cookieConsentMessage}
        </p>
        <button onClick={handleAccept} className="cookie-consent-button">
            {acceptButtonText}
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;

