import React from "react";
import { Utensils, BookOpen, Lightbulb, Image, Search, Scale } from 'lucide-react';

export default function AIServicesSwedish(props){
    let {getImageUrl, buttonId} = props;
   
  
    return (
      <div className="basicServicesWrapper">
        <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="transparentBoxAIServices">
    <div className="plusServicesHeader">Vi använder följande AI tjänster.</div>
  
          <div className="basicServicesListText">
          <ol className="basicServicesListMid">
            <div className="aiServicesSurround">
            <div className="aiServicesText">
           <Utensils className="aiServicesIcon" />Skapa recept</div>
            <div className="aiServicesText">
              <Lightbulb className="aiServicesIcon" />Skapa recept idéer</div>
            <div className="aiServicesText">
              <Image className="aiServicesIcon" />Skapa bilder</div>
            <div className="aiServicesText">
              <Search className="aiServicesIcon" />Sök ingrediens information</div>
            <div className="aiServicesText">
              <Scale className="aiServicesIcon" />Ingrediens konvertering</div>
              </div>
              </ol>
          </div>
  </div>
  </div>
  </div>
  
      )
  
  
  }