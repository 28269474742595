import React, { useState,useEffect } from 'react';
import {useNavigate,Link} from 'react-router-dom';
import useGetData, { usePostData, usePostData1 } from '../hooks/useGetData';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import { jwtDecode } from 'jwt-decode';
import './LoginPage1.css';
import logo from '../images/yukistartblack.jpg'
import { US } from 'country-flag-icons/react/3x2'
import { SE } from 'country-flag-icons/react/3x2'
import { JP } from 'country-flag-icons/react/3x2'
import { GB } from 'country-flag-icons/react/3x2'
import languages from '../AppLanguage/AppLanguage';
import { useLocation } from 'react-router-dom';
import YesNoLineAlert from '../functions/YesNoLineAlert';
import CryptoJS from 'crypto-js';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import Alert from '../functions/appAlert';
import useReportErr from '../hooks/useReportErr'
import AppError from '../AppLanguage/AppError';
import btn_base from '../images/btn_base.png'
import CookieConsent from '../CookieConsent/CookieConsent';
import { FaUserPlus } from "react-icons/fa";










export default function LoginPage(){


const [email, setEmail] = useState('');
const [password, setPassword] = useState('')
const[login,setLogin]=useState([{}])
const[getSpinnerLoading,setSpinnerLoading]=useState(false)
const[getEmaildone,setEmaildone]=useState(false)
const[getBackGroundShade,setBackGroundShade]=useState(0.45)
const[windowWidth,setWindowWith]=useState(0)
const[windowHeight,setWindowHeight]=useState(0)
const[getloginCountry,setloginCountry]=useState('')
const[getCountry,setCountry]=useState('en')
const [randomLineState,setRandomLineState]=useState('')
const [getLineCode,setLineCode]=useState('')
const[getShowAlert,setShowAlert]=useState(false)
const[getAlertMessage,setAlertMessage]=useState('')
const[getAlertCode,setAlertCode]=useState('')
const [isLoading, setIsLoading] = useState(false); // google login
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const[getMobileIsUsed,setMobileIsUsed]=useState(false)
const [tabletIsUsed,setTabletIsUsed]=useState(false)
const [laptopIsUsed,setLaptopIsUsed]=useState(false)
const [desktopIsUsed,setDesktopIsUsed]=useState(false)
const [largeDesktopIsUsed,setLargeDesktopIsUsed]=useState(false)
const [buttonWidth,setButtonWidth]=useState('360px') // adjust as needed
const [buttonWidth1,setButtonWidth1]=useState('290px') // adjust as needed
const [requireLink,setRequireLink]=useState(false)
const [resetEmail,setResetEmail]=useState('')
const [getResetShowAlert,setResetShowAlert]=useState(false)
const [getResetAlertMessage,setResetAlertMessage]=useState('')
const [getResetAlertCode,setResetAlertCode]=useState('')
const [getAlertCodeUser,setAlertCodeUser]=useState('')
const [getAlertMessageUser,setAlertMessageUser]=useState('')
const[getShowAlertUser,setShowAlertUser]=useState(false)
const[getShowRecoverButton,setShowRecoverButton]=useState(false)
const [getLatestNews,setLatestNews]=useState([{}])
const [isExpanded, setIsExpanded] = useState(false);
const [showLogin, setShowLogin] = useState(false);  // show login form
const [showSignup, setShowSignup] = useState(false);  // show signup form
const[signUpWithGoogle,setSignUpWithGoogle]=useState(false)  // sign up with google
const[getSignUpUser,setSignUpUser]=useState([{}])
const[showGoogleLogin,setShowGoogleLogin]=useState(false)

const [detectedLanguage, setDetectedLanguage] = useState(null); // For auto-detection,language
const [selectedLanguage, setSelectedLanguage] = useState(null); // For manual selection,language


// google signup
const [showSignUpWithGoogleDetails, setShowSignUpWithGoogleDetails] = useState(false);
// const [googleSignupEmail, setGoogleSignupEmail] = useState('');
// const [googleSignupName, setGoogleSignupName] = useState('');
// const [googleSignupPicture, setGoogleSignupPicture] = useState('');

// facebook signup
// const [fbuserInfo, setfbUserInfo] = useState({});
// const [fbstatus, setfbStatus] = useState('');
// const [fbshowLogin, setfbShowLogin] = useState(false);



const pageid=22
// country settings
useEffect(()=>{
  let country = localStorage.getItem('country')||getCountry // this is the country code
  setCountry(country)
},[getCountry])


// language settings
useEffect(() => {
  const detectLanguage = () => {
    const userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage.startsWith('sv')) {
      setCountry('se')
      return 'se'; // Swedish detected
    } else if (userLanguage.startsWith('en')) {
      setCountry('en')
      return 'en'; // English detected
    } else if (userLanguage.startsWith('ja')) {
      setCountry('ja')
      return 'ja'; // Japanese detected
    } else {
      return 'en'; // Default to English if no match
    }
  };

  const detectedCountry = detectLanguage();
  setDetectedLanguage(detectedCountry);
  GetLatestNewsData(detectedCountry); // Fetch news based on the detected language
}, []);

async function GetLatestNewsData(language) {
  try {
    let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getLatestNews/${language}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_NEWSKEY 
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setLatestNews(data[0]); // Assuming data[0] contains the latest news item
  } catch (error) {
  }
}

// Determine which language to use: detected or manually selected
const finalLanguage = selectedLanguage || detectedLanguage;


const handleLanguageChange = (lang) => {
  setSelectedLanguage(lang); // Manually set the language
  GetLatestNewsData(lang); // Fetch news based on manually selected language
};


let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
  newFontFamily = 'Arial, Helvetica, sans-serif'
  
}
document.documentElement.style.setProperty('--font-family', newFontFamily);
    
const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
    if(window.innerWidth<600){
      setMobileIsUsed(true)
    }

  }

  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, []); // Dependencies

// This useffect help us to change the size of the google button depending on the size of the screen
useEffect(() => {
  const updateButtonWidth = () => {
    if(window.innerWidth < 600){
      setMobileIsUsed(true);
      setButtonWidth('250px'); // adjust as needed
    } else if(window.innerWidth >599 && window.innerWidth < 1438){
      setTabletIsUsed(true);
      setButtonWidth('250px'); // adjust as needed
    } else if(window.innerWidth >1437 && window.innerWidth < 1911){
      setLaptopIsUsed(true);
      setButtonWidth('430px'); // adjust as needed
    } else if(window.innerWidth >1910 && window.innerWidth < 2302){
      setDesktopIsUsed(true);
      setButtonWidth('430px'); // adjust as needed
    } else if(window.innerWidth >2302){
      setLargeDesktopIsUsed(true);
      setButtonWidth('630px'); // adjust as needed
    }
  };

  window.addEventListener('resize', updateButtonWidth);
  updateButtonWidth(); // initial call

  return () => window.removeEventListener('resize', updateButtonWidth);
}, []);
// end google button resizing--------------------------------------------------------


    const selectedCountry = languages[getCountry];
    const {
      welcomeMessageText,
      emailadressText,
      continuebuttonText,
      noaccountText,
      createaccountText,
      availableLanguagesText,
      loginText,
      passwordText,
      forgotPasswordText,
      sendMeResetLinkText,
      sendResetButtonText,
      resetinputPlaceholder,
      aboutUsText,
      contactText,
      privacyPolicyText,
      loginText2,
      signUpText2,
      orText2,
      returnText,
      mobileWelcomeMessageText,
      mobileWelcomeMessageText2,
      setresetEmailText,
    } = selectedCountry.loginBox||{};

    const{
      resetLinkSentToEmail,
      loginConfirmToManyUsersText,
      forceLoginText,
      noaccessContactSupportText,
      wrongIdOrAccountText,
      notAuthorizedText,
      useNoAccessText,
      signupButtontext
      
      }=selectedCountry.confirmationMessages||{};

      const{
        continueWithGoogleText,
      continueWithFacebookText,
      continueWithLineText,
    } = selectedCountry.info||{};

    // error messages
//const selectedError=AppError[localStorage.getItem('country')||'en']
const selectedError = AppError[getCountry || 'en'];
  const navigate = useNavigate();





// Line login -------------------------------------------------------------
  async function LineloginHandler() {

    const hasLineConsent = localStorage.getItem('hasLineConsent');
   
    if (!hasLineConsent) {
      setAlertMessage('LINEからあなたのメールアドレスを受け取る許可をお願いします。あなたのメールアドレスは、本人確認のため、本システム内であなたとの連絡やサポートをするためのみに使用します。他のいかなる第三者へも供与しません');
      setShowAlert(true);
      setAlertCode(0);
    } else {
      let code_challenge = localStorage.getItem('code_challenge');
      let nonce = localStorage.getItem('nonce');
      let randomLineState = localStorage.getItem('randomLineState');
       window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2003871602&redirect_uri=${encodeURIComponent('https://www.bakesoft.se/login')}&state=${randomLineState}&scope=profile%20openid%20email&nonce=${nonce}&code_challenge=${encodeURIComponent(code_challenge)}&code_challenge_method=S256`;
 
    }
  }
  

useEffect(() => {
  // when this component mounts, we set these values in the localstorage, so we can use them later
  if (!localStorage.getItem('randomLineState')) {
    let randomLineState = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    localStorage.setItem('randomLineState', randomLineState);
  }
  if (!localStorage.getItem('nonce')) {
    let nonce = generateRandomString(32);
    localStorage.setItem('nonce', nonce);
  }
  if (!localStorage.getItem('code_verifier')) {
    // Generate a code_verifier
    let code_verifier =randomAlphaNumericString(43);
    // Store the code_verifier in localStorage for later
    localStorage.setItem('code_verifier', code_verifier);
    // Generate a code_challenge
    let code_challenge = base64URL(CryptoJS.SHA256(code_verifier));
    // Store the code_challenge in localStorage for later
    localStorage.setItem('code_challenge', code_challenge);
  }
}, []);

  function base64URL(string) {
    return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  function generateRandomString(length) {
    return Math.random().toString(36).substring(2, 2 + length);
  }

function randomAlphaNumericString(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}



 
  const location = useLocation();
// useeffect for Line login -------------------------------------------------------------
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code'); // Get the authorization code from xthe query parameters
    const state = urlParams.get('state'); // Get the state parameter from the query parameters
    const randomLineState = localStorage.getItem('randomLineState'); // i get the random state from the localstorage
    if (state===randomLineState) {   // Check if the state parameter matches the random state, the random state is a requirement
                                     // from Line to prevent CSRF attacks
      setLineCode(code)
      return;
    }else{
   //console.error('Invalid state');
      setLineCode('')
    }
  }, [location]);

  // we have the code and we will send it to the backend
  useEffect(() =>{ 
    if(getLineCode){
      LineLogin()
    }
  }, [getLineCode]);

  async function LineLogin() {
    let code_verifier = localStorage.getItem('code_verifier');
    setSpinnerLoading(true)
    try{
 
  
      let response = await usePostData(`lineLogin`, { code: getLineCode, code_verifier: code_verifier });
       if(response.status===200){
        
        localStorage.setItem('auth_token', response.data.token);
        const decodedToken = jwtDecode(response.data.token);
        const country = decodedToken.country;

        // Store the countryC value in local storage
        if(country){
        localStorage.setItem('country', country);

        }

          // here we will store the statusLevel in the local storage
          const statusLevel = decodedToken.StatusLevel
          let encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(statusLevel),`${process.env.REACT_APP_USER_SECRET2}`).toString();
          localStorage.setItem('qba', encryptedValue);


        if (response.data.image) {
          localStorage.setItem('image_link', response.data.image);
     
        }
        setSpinnerLoading(false)
        window.dispatchEvent(new Event('storage'));
        navigate('/WelcomeImage');
      }
    }catch(error){
      localStorage.removeItem('code_verifier');
      localStorage.removeItem('code_challenge');
      localStorage.removeItem('nonce');
      localStorage.removeItem('randomLineState');
      
      alert('We cannot log you in by Line, please sign up or log in by email and password.');
      setSpinnerLoading(false)
      
    }
  }

// Line login end -----------------------------------------------------------------------------







  // google login -----------------------------------------------------------------
  useEffect(() => {
    // Check for token in local storage or cookie
    const token = localStorage.getItem('auth_token'); // or however you store it
    if (token) {
      // Redirect to the welcome page if token exists
      navigate('/WelcomeImage');
    }
  }, [navigate]);


// google starts here
useEffect(() => {
  // Load the Google Identity Services SDK once when the component mounts
  const script = document.createElement('script');
  script.src = 'https://accounts.google.com/gsi/client';
  document.body.appendChild(script);

  // Cleanup the script when the component unmounts
  return () => {
    document.body.removeChild(script);
  };
}, []);

  const initGoogleSignIn = () => {
    const clientId = "447578481583-te1nq0ka5tuar8b3mjono33cq9reqdl3.apps.googleusercontent.com"; // Your Google Client ID
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse, // Callback for the login response
     
      });
      window.google.accounts.id.prompt(); // This will trigger the Google login prompt
    }
  };



  const handleCredentialResponse = (response) => {
       setSpinnerLoading(true)
        const apiKey = process.env.REACT_APP_VERIFY
     
       // Remove the code_verifier, code_challenge, nonce and randomLineState from local storage
       // because its not needed for Google Login
       localStorage.removeItem('code_verifier');
       localStorage.removeItem('code_challenge');
       localStorage.removeItem('nonce');
        localStorage.removeItem('randomLineState');

      axios.post(`${process.env.REACT_APP_BACKEND_URL}/verifyGoogleToken`, { token: response.credential },
      { headers: { 'x-api-key': apiKey } }
      )
       .then(response => {
        // Handle the response from your backend
      if (response.status === 200 && response.data.token) {
         localStorage.setItem('auth_token', response.data.token);
         const decodedToken = jwtDecode(response.data.token);

         const country = decodedToken.country;
         // Store the countryC value in local storage
         localStorage.setItem('country', country);  
          
         // here we will store the statusLevel in the local storage
         const statusLevel = decodedToken.StatusLevel
         let encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(statusLevel),`${process.env.REACT_APP_USER_SECRET2}`).toString();
         localStorage.setItem('qba', encryptedValue);

        if (response.data.image) {
          localStorage.setItem('image_link', response.data.image);
     
        }
        window.dispatchEvent(new Event('storage'));
         navigate('/WelcomeImage');
         setSpinnerLoading(false)
        }else if(response.status===280){
          localStorage.setItem('auth_token', response.data.token);
          setShowRecoverButton(true)
          setAlertCodeUser(0)
          setAlertMessageUser(loginConfirmToManyUsersText)
          setShowAlertUser(true)
          setSpinnerLoading(false)
        }else if(response.status===281){
          setShowRecoverButton(false)
          setAlertCodeUser(101161)
          setAlertMessageUser(notAuthorizedText)
          setShowAlertUser(true)
          setSpinnerLoading(false)
        }
      })
      .catch((error) => {
        setShowRecoverButton(false)
        setAlertCodeUser(101162)
        setAlertMessageUser(notAuthorizedText)
        setShowAlertUser(true)
        setSpinnerLoading(false)
      
      });
  };

 // Google login end -----------------------------------------------------------------


  // Manual Bakesoft Login -----------------------------------------------------------------
  async function HandleLogin() {
    // Remove the code_verifier, code_challenge, nonce and randomLineState from local storage
    // because its not needed for Bakesoft Login
    localStorage.removeItem('code_verifier');
    localStorage.removeItem('code_challenge');
    localStorage.removeItem('nonce');
    localStorage.removeItem('randomLineState');
 

    setSpinnerLoading(true)
   let apiKey=process.env.REACT_APP_CHECKLOGIN

  try {
    //  let response = await usePostData1(`${process.env.REACT_APP_BACKEND_URL}/checkLogin`, {
      let response = await usePostData1(`checkLogin`, {
      email: email,
      password: password},
      apiKey
    );
    console.log(response)

      if (response.status === 200 && response.data.token) {
        localStorage.setItem('auth_token', response.data.token);
        const decodedToken = jwtDecode(response.data.token);
      
        const country = decodedToken.country;
        
    
        // Store the countryC value in local storage
        localStorage.setItem('country', country);

          // here we will store the statusLevel in the local storage
          const statusLevel = decodedToken.StatusLevel
          let encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(statusLevel),`${process.env.REACT_APP_USER_SECRET2}`).toString();
          localStorage.setItem('qba', encryptedValue);
        
          
      
          localStorage.setItem('image_link','b');
        

     
    
        window.dispatchEvent(new Event('storage'));
        setSpinnerLoading(false)
    
        navigate('/WelcomeImage');
      } else if(response.status===280){
    
        setShowRecoverButton(true)
        setAlertCodeUser(0)
        setAlertMessageUser(loginConfirmToManyUsersText)
        setShowAlertUser(true)
        setSpinnerLoading(false)

      }else if(response.status===290) {
        setShowRecoverButton(false)
        setAlertCodeUser(101161)
        setAlertMessageUser(useNoAccessText)
        setShowAlertUser(true)
        setSpinnerLoading(false)
      }
    } catch (error) {
      setSpinnerLoading(false)
      setAlertCodeUser(101163)
      setAlertMessageUser(wrongIdOrAccountText)
      setShowAlertUser(true)

    }
  }
 
 // Manual Bakesoft Login end -----------------------------------------------------------------

 // Facebook login start -----------------------------------------------------------------

useEffect(() => {
  const loadFacebookSDK = () => {
    // Load the SDK asynchronously if it's not already loaded
    if (!window.FB) {
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    } else {
      console.log('Facebook SDK already loaded');
    }
  };

  // Initialize the Facebook SDK when it's ready
  window.fbAsyncInit = function() {
    window.FB.init({
      appId      : '234940295680117',  // Your Facebook App ID
      cookie     : true,
      xfbml      : true,
      version    : 'v20.0'
    });

    // Subscribe to login status changes
    window.FB.Event.subscribe('auth.statusChange', function(response) {
      statusChangeCallback(response);
    });

    // Parse XFBML to render the Facebook button
    window.FB.XFBML.parse();
  };

  // Load the SDK
  loadFacebookSDK();

  // Parse the Facebook button again if the SDK is already loaded
  if (window.FB) {
    console.log('Parsing Facebook button');
    window.FB.XFBML.parse();
  }

}, [location]);  // Re-run the effect on route changes




const statusChangeCallback = async (response) => {
  if (response.status === 'connected') {

    // we use our own api key to get to the backend
   const apiKey = process.env.REACT_APP_FBLOGINKEY;

    const accessToken = response.authResponse.accessToken;
    FacebookLogin(accessToken, apiKey);
    setSpinnerLoading(true);

  }
};

async function FacebookLogin(accessToken, apiKey) {
  try {
    let response = await usePostData1(`facebookLogin`, { token: accessToken }, apiKey);

    // Handle the response from your backend
    if (response.status === 200 && response.data.token) {
      localStorage.setItem('auth_token', response.data.token);
      const decodedToken = jwtDecode(response.data.token);

      const country = decodedToken.country;
      // Store the country value in local storage
      localStorage.setItem('country', country);  
      
      // Store the statusLevel in the local storage
      const statusLevel = decodedToken.StatusLevel;
      let encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(statusLevel), `${process.env.REACT_APP_USER_SECRET2}`).toString();
      localStorage.setItem('qba', encryptedValue);

      if (response.data.image) {
        localStorage.setItem('image_link', response.data.image);
      }

      window.dispatchEvent(new Event('storage'));
      navigate('/WelcomeImage');
      setSpinnerLoading(false);

    } else if (response.status === 280) {
      localStorage.setItem('auth_token', response.data.token);
      setShowRecoverButton(true);
      setAlertCodeUser(0);
      setAlertMessageUser(loginConfirmToManyUsersText);
      setShowAlertUser(true);
      setSpinnerLoading(false);

    } else if (response.status === 298) {
      setShowRecoverButton(false);
      setAlertCodeUser(101161);
      setAlertMessageUser(notAuthorizedText);
      setShowAlertUser(true);
      setSpinnerLoading(false);
    }
  } catch (error) {
    // Handle any errors from the usePostData1 call
    console.error('Error during Facebook login:', error);

    // Set your error states or show an alert
    setShowRecoverButton(false);
    setAlertCodeUser(101161);
    setAlertMessageUser(notAuthorizedText);
    setShowAlertUser(true);
    setSpinnerLoading(false);
  }
}


// Facebook login end -----------------------------------------------------------------

const setEmailHandler = (value) => {
    setEmail(value);
}
const setPassworHandler = (value) => {
    setPassword(value);
}

const handleSignup = () => {
}
const moveTopasswordHandler=()=>{
    setEmaildone(true)
}

const jpHandler=()=>{
setCountry('ja')
localStorage.setItem('country','ja');
handleLanguageChange('ja');

}
const seHandler=()=>{
setCountry('se')
localStorage.setItem('country','se');
handleLanguageChange('se');
}
const gbHandler=()=>{
setCountry('en')
localStorage.setItem('country', 'en');
handleLanguageChange('en');

}   

const handleCloseAlert = () => {
  setShowAlert(false);
};
const handleCloseAlertUser = () => {
  setShowAlertUser(false);
};
const handleResetCloseAlert = () => {
  setResetShowAlert(false);
};

const onCloseYes = () => {
  localStorage.setItem('hasLineConsent', 'true');
  setShowAlert(false);
  LineloginHandler();
}

const onCloseNo = () => {
  setShowAlert(false);

}

  
async function SendResetLinkHandler (){
 // setSpinnerLoading(true)
  // we have to make shure the users has no qba level/access level in localstorage
  // so we remove it. That is because, when user loggs in we set this property
  // to give user the right access level the correct links in the app that thay
  // pay for, but if user do not logout manually this qba value will be there,so we
  //remove it.
  localStorage.removeItem('qba');
  const apiKey=process.env.REACT_APP_REQUESTPASSWORDRESET

try {
  let response = await usePostData1(`requestPasswordReset`, { email: resetEmail },
    apiKey
  );

  if (response.status === 200) {
  //  setSpinnerLoading(false);
    setResetAlertMessage(resetLinkSentToEmail);
    setResetShowAlert(true);
    setResetAlertCode(0);
    setRequireLink(false); // we hide the reset link area
    setResetEmail(''); // we clear the email input
  }else if(response.status===201){
    //setSpinnerLoading(false);
    setResetAlertMessage(selectedError[54002]);
    setResetShowAlert(true);
    setResetAlertCode(54002);
  }else if(response.status===202){
    //setSpinnerLoading(false);
    setResetAlertMessage(selectedError[54003]);
    setResetShowAlert(true);
    setResetAlertCode(54003);
  }
} catch (error) {
  //setSpinnerLoading(false);
  setResetAlertMessage(selectedError[54001]);
  setResetShowAlert(true);
  setResetAlertCode(54001);
}
}

async function SendForceLogin(){
  setSpinnerLoading(true)

  try{
    const token = localStorage.getItem('auth_token');
    if (!token) {
      throw new Error('No token found');
    }

    const headers = token ? { authorization: `Bearer ${token}` } : {};  
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/forceLogin`, { headers });

    if(response.status===200){
      setPassword('')
      setShowRecoverButton(false)
      setSpinnerLoading(false)
      setAlertCodeUser(0)
      setAlertMessageUser(forceLoginText)
      setShowAlertUser(true)
    }
  }catch(error){
    setSpinnerLoading(false)
    setAlertCodeUser(101164)
    setAlertMessageUser(noaccessContactSupportText)
    setShowAlertUser(true)
  }
}




// sign up with google -------------------------------------------------------------

// useEffect(() => {
//   if (showLogin) {
//     // Load the Google Identity Services SDK
//     const script = document.createElement('script');
//     script.src = 'https://accounts.google.com/gsi/client';
//     script.onload = initGoogleSignIn1;
//     document.body.appendChild(script);

//     // Cleanup script on unmount
//     return () => {
//       document.body.removeChild(script);
//     };
//   }
// }, [showLogin]);

// const initGoogleSignIn1 = () => {
//   const clientId = "447578481583-te1nq0ka5tuar8b3mjono33cq9reqdl3.apps.googleusercontent.com";
  
//   if (window.google) {
//     console.log('window.google');
//     window.google.accounts.id.initialize({
//       client_id: clientId,
//       callback: handleCredentialResponse1,  // Handle the response from Google Sign-In
//     });
//     window.google.accounts.id.renderButton(
//       document.getElementById('signInDiv'),
//       { theme: 'Outline', size: 'large', width: buttonWidth1, border:'none', borderColor:'white'} // customization attributes   );
//     );

    
//   }
// };





// const handleCredentialResponse1 = (response) => {
//   console.log('handleCredentialResponse1')
//   setSpinnerLoading(true)

//   // Decode the JWT token returned by Google
//   const decodedToken = jwtDecode(response.credential);
//   console.log(decodedToken)

//   // Extract user info from the decoded token
//   const { email, name, picture } = decodedToken;

//   // Set user data in the component state
//   setSignUpUser({
//     email: email,
//     name: name,
//     picture: picture
//   });

//   setGoogleSignupEmail(email);
//   setGoogleSignupName(name);
//   setGoogleSignupPicture(picture);

//   // Store user data in localStorage
//   localStorage.setItem('signUpUser', JSON.stringify({
//     email: email,
//     name: name,
//     picture: picture
//   }));
// setSpinnerLoading(false)
// setShowLogin(false)
// setShowSignup(false)
// setShowSignUpWithGoogleDetails(true)
// };


const openPayPalPopup = (e) => {
  e.preventDefault();
  window.open(
    'https://www.paypal.com/webapps/mpp/paypal-popup',
    'WIPaypal',
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'
  );
};


const handleSocialLogin = (platform) => {
  console.log('platform', platform);
  if (platform === 'Google') {
   //setShowGoogleLogin(true);
 initGoogleSignIn(); // Trigger Google Sign-In only when the user clicks on the Google button
  }else if(platform==='Facebook'){
    
    if (window.FB) {
      window.FB.login((response) => {
        statusChangeCallback(response);
      }, {scope: 'public_profile,email'});  // Requesting profile and email permissions
    }
  }else if(platform==='Line'){
    LineloginHandler()
  }
};


  const signupHandler=()=>{
    navigate('/signup')
  }

  // when mobile click login on the footer
  const handleLoginClick = () => {
    setShowLogin(true);
    setShowSignup(false);
  }



// <img src={cutebakery5} alt="Bakesoft" className="xzbackgroundImageLogin" />



  return (
    <div className={showLogin&&getMobileIsUsed?"xztotalArea":"xztotalArea1"}>
                

        <div className="xzareaB">
                  <img src={logo} alt="Bakesoft" className="xzbackgroundImageLogin" />

                  {showLogin&&getMobileIsUsed ||!showLogin&&getMobileIsUsed===false? (
           
    <div className="xzloginArea">
        
        <form autoComplete="on" onSubmit={(e)=>{e.preventDefault();HandleLogin()}} >
       
                  <div className="xzloginBox">

                        {getCountry!=='ja'?

                     <div className={getCountry==='se'?"xzloginTextSvenska":"xzloginTextEnglish"}>{welcomeMessageText}</div>
                       :
                     <div className="xzloginTextJAP">{welcomeMessageText}</div>
                        }
        
      
                     <div className="xzboxTop">

                            {getEmaildone===false&&requireLink===false?
                            <fieldset className="xzloginFieldset"><legend className="xzloginLegend">{emailadressText}</legend>
                              <input type="text" autoComplete='username'
                              value={email} onChange={(e) =>setEmailHandler(e.target.value)} className="xzloginEmail" />
                              </fieldset>:null}
          
                  
                            {getEmaildone===true&&requireLink===false?
                            <fieldset className="xzloginFieldset"><legend className="xzloginLegend">{passwordText}</legend>
                              <input type="password" autoComplete='current-password'
                              value={password} onChange={(e) => setPassworHandler(e.target.value)} 
                                className={getCountry!=='ja'?"xzloginPass":"xzloginPassJAP"} />
                            </fieldset>:null}

                            {/* // do we need to have a resetlink */}
                            {requireLink===true&&getEmaildone===false?
                            <fieldset className="xzloginFieldset"><legend className="xzloginLegend">{setresetEmailText}</legend>
                            <input type="email"  autoComplete='username'
                            onChange={(e)=>setResetEmail(e.target.value)} className="xzloginEmail" />
                            </fieldset>:null}
                


                      

                              {getEmaildone===true&&requireLink===false?<button type="submit" className={password.length>0?"xzloginButton":"xzloginButtonNo"} onClick={password.length>0?HandleLogin:undefined}>{loginText}</button>:null}
                              {getEmaildone===false&&requireLink===false?<button type="submit" className={email.length>0?"xzloginButton":"xzloginButtonNo"} onClick={email.length>0?()=>moveTopasswordHandler():undefined}>{continuebuttonText}</button>:null}
                              {requireLink===true&&getEmaildone===false?<button type="submit" className={resetEmail.length>0?"xzloginButton":"xzloginButtonNo"} onClick={(event) => { event.preventDefault(); SendResetLinkHandler(); }}>{sendResetButtonText}</button>:null}
                             
                              {getShowRecoverButton?<button type="submit" className="xzloginButtonForce" onClick={(event)=>{event.preventDefault();SendForceLogin()}}>{'Access my account'}</button>:null}
                
                    </div> 
                    
        <div className="xzboxSignup">
       <Link to="/signup"><button type="submit" className="xzsingUpButton" onClick={handleSignup}>
        <div className="xzlogintext">{noaccountText}</div><div className="xzunderlinedText">{createaccountText}</div></button></Link>
        </div>

                          <div className="xzloginButtonWrapper">
                                 {/* <div className="boxgoogleWrapper">
                                <div className="xzboxGoogle" id="signInDiv" ></div>
                                </div> */}

{getMobileIsUsed===false?(        
        <div className="infocontinueSocialWrapper">
        <div className="xzcontinueGoogleButton"
        onClick={() => handleSocialLogin('Google')}>
      <svg
className="infocontinueGoogleIcon"
viewBox="0 0 24 24"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
  fill="#4285F4"
/>
<path
  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
  fill="#34A853"
/>
<path
  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
  fill="#FBBC05"
/>
<path
  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
  fill="#EA4335"
/>
<path d="M1 1h22v22H1z" fill="none" />
</svg>
<div className="infocontinueGoogleText">
      {continueWithGoogleText}</div>
    </div>
</div>
):null}








                              
                            <div className="xzfbWrapper">

                            <div className="infocontinueSocialWrapper">
                                    <div className="xzcontinueFacebookButton"
                                    onClick={() => handleSocialLogin('Facebook')}
                                    >
                                    <svg className="infocontinueFacebookIcon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                      >
                                        <path
                                          fill="white"  // Ensures the icon is white on the blue background
                                          d="M22.675 0h-21.35C.595 0 0 .592 0 1.324v21.351C0 23.408.595 24 1.324 24h11.495v-9.294H9.688v-3.622h3.131V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24h-1.918c-1.504 0-1.794.715-1.794 1.763v2.31h3.588l-.467 3.622h-3.121V24h6.116c.729 0 1.324-.592 1.324-1.324V1.324C24 .592 23.405 0 22.675 0z"
                                        />
                                      </svg>
                                      <div className="infocontinueFacebookText">
                                      {continueWithFacebookText} </div>
                                    </div>

                                </div>









                                                {/* <div className="fb-login-button"
                                                          data-width="240px"
                                                          data-size="large"
                                                          data-button-type="login_with"
                                                          data-layout="default"
                                                          data-auto-logout-link="false"
                                                          data-use-continue-as="false"
                                                          data-scope="public_profile,email">
                                                      </div> */}
                                                
                                              </div>
                                

                                      <div className="xzlineloginboxWrapper">
                                      <div className="xzlineloginbox">
                                      <div className="infocontinueSocialWrapper">
                                          <div className="xzcontinueLineButton"
                                          
                                          onClick={() =>handleSocialLogin('Line')}
                                          >
                                            <img src={btn_base} className="infocontinueLineIcon" /> 
                                        
                                          <div className="infocontinueLineText">{continueWithLineText}</div>
                                          </div>
                                    
                                </div>





                                      {/* <button className="xzlineloginButton" onClick={(event)=>{event.preventDefault();LineloginHandler()}}
                                      >
                                        <img src={btn_login_base} alt="Login Icon" className="xzlinebuttonImage" />
                                        <span className="xzlinebuttonText">Login with Line</span>
                                    
                                      </button> */}

                                        
                                        {/* <img src={btn_login_base} className="xzlinelogin"
                                      onClick={LineloginHandler} alt="Line login"
                                      ></img>  */}
                                      </div>
                                      </div>
                            </div>
                            
                                      

        <div>
        <div className="xzlangaugesAvailabletext">{availableLanguagesText}</div>
      

        </div>

        <div className="xzcountryFlagBox">
        
        <SE title="Swedish" className="xzloginflagse" onClick={seHandler} />
        <JP title="Japanese" className="xzloginflagJapan"  onClick={jpHandler}/>
        <GB title="English" className="xzloginflaggb" onClick={gbHandler} />
      
        </div>


    


  
  <div className="xzforgotPasswordTop">
      <div className="xzforogotPasswordText" onClick={() => setRequireLink(prevRequireLink => !prevRequireLink)}>
                   {forgotPasswordText}</div>

                <div className="xzinputEmailArea">

{/* {requireLink === true ?
<div className="xzsendEmailWrapper">
<fieldset className="xzresetloginFieldset"><legend className="xzresetloginLegend">{sendMeResetLinkText}</legend>
<input type="email" className="xzresetInputEmail" placeholder={resetinputPlaceholder}
onChange={(e)=>setResetEmail(e.target.value)}/>
<button className="xzSendButton" onClick={(event) => { event.preventDefault(); SendResetLinkHandler(); }}>{sendResetButtonText}</button>
</fieldset>
</div>
: ''} */}


</div>
  </div>
  
    <div>
     </div>
  
  </div>
     </form>


    </div>
                  ) : null}

    </div>
 
    <div className="xzareaC">
    {getMobileIsUsed!==true?(
      <>
      <div className="xzAreaCWrapper">
              <div className="xzLatestNewsArea">
                    <div className="xzlatestNewsHeader">Latest News</div>
                          {getLatestNews.map((item,index)=>{
                            return(
                              <div key={index} className="xzlatestNewsBox">
                                  <div className="xzlatestNewsTitle">{item.benaemn}</div>
                                  <div className="xzlatestNewsText">{item.btext}</div>
                              </div>
                            )
                          }
                  
                          )}
              
                  </div>
            </div>
            <div className="xzlanguageArea">
                        <SE title="Swedish" className="xzloginflagse" onClick={seHandler} />
                        <JP title="Japanese" className="xzloginflagJapan1"  onClick={jpHandler}/>
                        <GB title="English" className="xzloginflaggb" onClick={gbHandler} />
                     </div>


                     <div className="xzPaymentWrapper">
                      <div className="xzPaymentChannelPaypal">

<table border="0" cellPadding="10" cellSpacing="0" align="center">
      <tbody>
        <tr>
          <td align="center"></td>
        </tr>
        <tr>
          <td align="center">
            <a href="#" title="How PayPal Works" onClick={openPayPalPopup}>
              <img
                src="https://www.paypalobjects.com/webstatic/mktg/logo/bdg_payments_by_pp_2line.png"
                border="0"
                alt="Payments by PayPal"
              />
            </a>
            <div style={{ textAlign: 'center' }}>
              <a href="https://www.paypal.com/webapps/mpp/pay-online" style={{ fontSize: '14px', fontFamily: 'Arial', color: '#0079CD', fontWeight: 'bold' }}>
                How PayPal Works
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
                     </div>
      </div>

                     </>
    ):''}
     </div>
     {/* we show the login area  and passing props*/}
     {showLogin===true&&showSignup===false?(
    <div className="xzreturnArea"
    onClick={()=>setShowLogin(false)}
    >{returnText}</div>
      ):''}
  

<div className="xzmidWrapper">
{!getMobileIsUsed?(
  <div className="xzsignUpPointerWrapper">
     <div className="xzSignUpPointerButton" 
     onClick={()=>navigate('/signup?signupbutton=2')}
      ><FaUserPlus className="xzfauserplusIcon"/>{signupButtontext}</div>
  </div>
  ):''}
  
<CookieConsent/>
  {/* we show the welcome message for mobile users */}
{getMobileIsUsed===true&&showLogin===false&&showSignup===false&&showSignUpWithGoogleDetails===false?
<MobileWelcomeMessage
 mobileWelcomeMessageText={mobileWelcomeMessageText}
  mobileWelcomeMessageText2={mobileWelcomeMessageText2}
/>
:''}


    

   
  <div className="xzloginSpinnerContainer">
              <div className="xzSpinner">
        <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="80" visible={getSpinnerLoading}/>
             </div>
          </div>
   
      
        
          {getShowAlertUser===true?<Alert message={getAlertMessageUser} code={getAlertCodeUser} onClose={handleCloseAlertUser} />:''}
    
          {getResetShowAlert===true?<Alert message={getResetAlertMessage} code={getResetAlertCode} onClose={handleResetCloseAlert} />:''}
  
      {getShowAlert===true?<YesNoLineAlert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert} onCloseYes={onCloseYes} onCloseNo={onCloseNo}/>:''}
        </div>

    {getMobileIsUsed===true&&showLogin===false&&showSignup===false&&showSignUpWithGoogleDetails===false?
    <Footer   
    setIsExpanded={setIsExpanded} isExpanded={isExpanded}
    showLogin={showLogin} setShowLogin={setShowLogin}
    showSignup={showSignup} setShowSignup={setShowSignup}
    seHandler={seHandler} jpHandler={jpHandler} gbHandler={gbHandler}
    aboutUsText={aboutUsText} contactText={contactText} privacyPolicyText={privacyPolicyText}
    loginText2={loginText2} signUpText2={signUpText2} orText2={orText2}
    handleLoginClick={handleLoginClick}
    />:''}
  
    </div>

  );
};

const Footer = (props) => {
let{setIsExpanded,isExpanded,showLogin,setShowLogin,showSignup,setShowSignup,
  seHandler,jpHandler,gbHandler,aboutUsText,contactText,privacyPolicyText,handleLoginClick,
  loginText2,signUpText2,orText2}=props

  let navigate = useNavigate();

  

  // const handleLoginClick = () => {
  //   setShowLogin(true);
  //   setShowSignup(false);
  // };

  const handleSignupClick = () => {
    navigate('/signup')
  };

  return (
    <div className="xzfooterArea">
            <div className="xzlanguageArea">
                        <SE title="Swedish" className="xzloginflagse" onClick={seHandler} />
                        <JP title="Japanese" className="xzloginflagJapan1"  onClick={jpHandler}/>
                        <GB title="English" className="xzloginflaggb" onClick={gbHandler} />
                     </div>
      <div className="xzfooterAreaWrapper">
      <span onClick={handleLoginClick}>{loginText2}</span>&nbsp; {orText2} <span onClick={handleSignupClick}>&nbsp;{signUpText2}</span>
      </div>
      
      <ul className="footer-links">
          {/* <li><a href="/about">{aboutUsText}</a></li>
          <li><a href="/contact">{contactText}</a></li>
          <li><a href="/privacy">{privacyPolicyText}</a></li> */}
        </ul>
  
                     <hr className="klarnaTopHr"></hr>
                     {/* <div className="klarnamessage">
<klarna-placement
  data-key="footer-promotion-auto-size"
  data-locale="sv-SE"
></klarna-placement>
</div> */}

    </div>

  );
};


function MobileWelcomeMessage(props){
  let{mobileWelcomeMessageText,mobileWelcomeMessageText2

  }=props
  return(
    <div className="xzMobileWelcomeMessageTop">
      <div className="xzMobileWelcomeMessage">  {mobileWelcomeMessageText}</div>
      <div className="xzMobileWelcomeMessage">  {mobileWelcomeMessageText2}</div>

    </div>
  )
}

export {LoginPage,Footer,MobileWelcomeMessage};