import React,{useState,useEffect} from 'react'
import prodStat from '../hooks_styles/prodStat'
import useDeviceDetection from './useDeviceDetection';
function UseChartChoice(props) {
  let {chartLegend}=props

const [statnew, setstatnew] = useState({})

  const { isIpad, isAndroid,isWindows } = useDeviceDetection()
  const {prodstatIpad10th,prodstatAndroid,prodstatWindows} = prodStat();// we get the styles for the device type here
      
  useEffect(()=>{
    if (isIpad) {
        setstatnew(prodstatIpad10th);
    } else if (isAndroid) {
        setstatnew(prodstatAndroid);
    } else if (isWindows) {
      
        setstatnew(prodstatWindows);

    }else{
        setstatnew(prodstatWindows)
    
    }
  },[isIpad,isAndroid,isWindows])


    let {chartHandler,getchosenChart}=props
  return (
    <div style={statnew.ChartChoiceArea}>

    <fieldset style={statnew.chartFieldset}>
      <legend style={statnew.chartLegend}>{chartLegend}</legend>
      <button
        className={`useChartChoice-chart-button ${getchosenChart === 1 ? 'active' : ''}`}
        onClick={() => chartHandler(1)}
      >
        Area
      </button>
      <button
        className={`useChartChoice-chart-button ${getchosenChart === 2 ? 'active' : ''}`}
        onClick={() => chartHandler(2)}
      >
        Line
      </button>
      <button
        className={`useChartChoice-chart-button ${getchosenChart === 3 ? 'active' : ''}`}
        onClick={() => chartHandler(3)}
      >
        Bar
      </button>
      </fieldset>
</div>
  )
}

export default UseChartChoice