
import axios from "axios";

// const REACT_APP_ERROR_API_KEY = process.env.REACT_APP_ERROR_API_KEY;
// const apiUrl = 'https://3f32a6yt97.execute-api.eu-north-1.amazonaws.com/1/';

// async function useReportErr(errnumber) {
//   const payload = {
//     apiKey:REACT_APP_ERROR_API_KEY,
//     errnumber: errnumber
//   };

//   try {
//     await axios.post(apiUrl, payload, {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });
//   } catch (error) {
//     // Handle errors here if needed
//     console.error('Error:', error);
//   }
//   console.log('Error reported');
// }

// export default useReportErr;



  async function useReportErr1(url, payload) {
    const token = localStorage.getItem('auth_token');
    const headers = token ? { authorization: `Bearer ${token}` } : {};
  
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/${url}`, payload, {
      headers: headers,
      Credentials: true
    });
  
    return result;
    
  }

  async function useReportErr(url, payload) {
    const apiKey=process.env.REACT_APP_ERRORAPIKEY;
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/${url}`,{payload},{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey},
        body: JSON.stringify(payload),
      Credentials: true
    });
  
    return result;
  }

  export default useReportErr;