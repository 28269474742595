import React from 'react';

const IconWrapper = ({ children, title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <title>{title}</title>
    {children}
  </svg>
);

export const EntryPlanIcon = () => (
  <IconWrapper title="Entry Plan"
  >
    
    <path d="M3 12h18M3 6h18M3 18h18"/>
    <circle cx="7" cy="6" r="1" fill="currentColor"/>
    <circle cx="7" cy="12" r="1" fill="currentColor"/>
    <circle cx="7" cy="18" r="1" fill="currentColor"/>
  </IconWrapper>
);

export const LanguageIcon = () => (
  <IconWrapper title="Language">
    <circle cx="12" cy="12" r="10"/>
    <path d="M2 12h20M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/>
  </IconWrapper>
);

export const CountryIcon = () => (
  <IconWrapper title="Country">
    <path d="M3 6v12h18V6H3zm0 4h18M9 6v12"/>
  </IconWrapper>
);

export const CurrencyIcon = () => (
  <IconWrapper title="Currency">
    <circle cx="12" cy="12" r="10"/>
    <path d="M15 9.354c-.584-.893-1.779-1.511-3.143-1.511-2.147 0-3.889 1.384-3.889 3.089 0 1.704 1.742 3.089 3.889 3.089 2.147 0 3.889 1.384 3.889 3.089 0 1.704-1.742 3.089-3.889 3.089-1.364 0-2.559-.618-3.143-1.511M12 6v2.5m0 7V18"/>
  </IconWrapper>
);

export const PeriodIcon = () => (
  <IconWrapper title="Period">
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
    <line x1="16" y1="2" x2="16" y2="6"/>
    <line x1="8" y1="2" x2="8" y2="6"/>
    <line x1="3" y1="10" x2="21" y2="10"/>
  </IconWrapper>
);

const Icons = {
  EntryPlan: EntryPlanIcon,
  Language: LanguageIcon,
  Country: CountryIcon,
  Currency: CurrencyIcon,
  Period: PeriodIcon,
};

export default Icons;