

const AppError = {
    se: {
        2001: "Kan inte hämta data",
    2002: "Kan inte skapa produkt",
    2003: "Kan inte hämta data",
    2004: "Kan ej uppdatera produkt",
    2005: "Kan inte hämta data",
    2006: "Kan inte hämta data",
    2007: "Kan ej deaktivera produkt",
    2008: "Kan ej uppdatera namn",
    2009: "Kan ej uppdatera kategori",
    2010: "Kan inte hämta id på server",
    3001: "Kan inte hämta data",
    3002: "Kan inte hämta data",
    3003: "Kan inte hämta recept/ produkt",
    3004: "Kan inte hämta historik på server",
    3005: "Kan inte hämta artikeldata",
    3006: "Kan inte uppdatera leverantör",
    3007: "Kan ej uppdatera ingrediens på recept",
    3008: "Kan ej skapa ny ingrediens",
    3009: "Kan ej uppdatera ingrediens",
    4001: "Kan ej hämta ingrediens/kategorier",
    4002: "Kan ej skapa ny kategori",
    4003: "Kan ej hämta ingredienser i kategori",
    4004: "Kan ej flytta ingrediens till annan kategori",
    4005: "Ingrediens kan ej flyttas till kategori-Övrigt",
    4006: "Kategori kan ej tas bort",
    4007: "Kan ej flytta ingrediens till annan kategori",
    4008: "Kan ej flytta ingrediens till kategori Övrigt",
    4009: "Kan ej flytta ingrediens till annan kategori",
    4010: "Kan inte uppdatera bakrunds nyans",
    4011: "Kan intre uppdatera meny nyans",
    4013: "kan inte ta bort kategori",
    4014: "Kan inte skapa ny JAP användare",
    5001: "Kan ej hämta produkt kategorier",
    5002: "Kan ej hämta produkter i kategori",
    5003: "Produkt/er har placerats i kategori-99",
    5004: "Kan ej flytta produkter till ny kategori",
    5005: "Kan ej skapa ny kategori",
    5006: "Kan ej ta bort kategori",
    5008: "Kan ej flytta produkter till kategori övrigt",
    6001: "Kan ej hämta leverantörer",
    6002: "Kan ej hämta förpackningar på vald leverantör",
    6003: "Kan ej spara köp",
    6004: "Kan ej skapa ny förpackning",
    6005: "Kan ej uppdatera förpackning",
    6006: "Kan ej ta bort förpackning",
    7001: "Kan ej hämta receptkategorier",
    7002: "Kan ej hämta recept/huvud",
    7003: "Kan ej hämta receptrader",
    7004: "Kan ej hämta ingredienskategorier",
    7005: "Kan ej hämta recept på vald kategori",
    7006: "Kan ej hämta recept",
    7007: "Kan ej uppdatera arborder",
    7008: "Kan ej hämta ingredienser på vald kategori",
    7009: "Kan ej uppdatera recept",
    8001: "Kan ej hämta receptkategorier",
    8002: "Kan ej skapa ny receptkategori",
    8003: "Kan ej hämta recept i vald kategori",
    8004: "Kan ej flytta recept till vald kategori",
    8005: "Recept kan ej tas bort",
    8006: "Receptkategori kan ej tas bort",
    9001: "Kan ej hämta ingredienser",
    9002: "Kan ej hämta ingredienser för vald kategori",
    9003: "Kan ej hämta receptid",
    9004: "Kan ej hämta valt recept",
    9005: "Kan ej skapa recept",
    10001: "Kan ej hämta produktkategorier",
    10002: "Kan ej hämta produkter i vald kategori",
    10003: "Kan ej klarmarkera produkt",
    10004: "Kan ej hämta historisk data",
    10005: "Kan ej spara produktion",
    10006: "Kan ej klarskriva produktion",
    10007: "Kan ej skapa produktion",
    10008: "Kan ej hämta pågående produktion",
    11001: "Kan ej hämta klarskriven produktion",
    11002: "Kan ej hämta produktionsnummering",
    11003: "Kan ej hämta kalkylerad data",
    11004: "Ken ej uppdatera beskrivning",
    12001: "Kan ej hämta total produktionsdata",
    12002: "Kan ej uppdatera produktionsstatus",
    13001: "Kan ej hämta total produktionsdata",
    14001: "Kan ej hämta ingredienstotaler",
    15001: "Kan ej hämta produktkategorier",
    15002: "Kan ej hämta produkter i vald kategori",
    15003: "Kan ej hämta graf på vald produkt",
    16001: "Kan ej hämta leverantörer",
    16002: "Kan ej hämta leverantörsdata",
    16003: "Kan ej uppdatera inleverans",
    17001: "Kan ej hämta ingredienser & kategorier",
    17002: "Kan inte hitta ingredienser",
    17003: "Kan inte uppdatera ingrediens",
    17004: "Kan inte lägga till ny ingrediens",
    17005: "Kan inte spara recept",
    17006: "Kan inte översätta ingrediens",
    17007: "Kan inte hämta information ifrån Api",
    17008: "Kan inte uppdatera bakrundsnyans",
    17009: "Kan inte uppdatera meny nyans",
    17010: "Kan inte rapportera till debit",
    17011:"Hittade inga recept ideer för sökning",
    17012:"Kan inte hämta recept ideer",
    17013:"Kan inte hämta senaste receptsökning",
    17014:"Kan inte uppdatera bakrundsnyans",
    17015:"Kan inte uppdatera meny nyans",
    18001: "Kan inte översätta text",
    18002: "Kan inte hämta information ifrån EDAMAM API",
    18003: "Kan inte returnera information ifrån EDAMA API",
    18004: "Kan inte uppdatera databasen med värden från EDAMA API",
    18005: "Kan inte göra delete på data på valt id i b_ingred_naringsamne",
    18006: "kan inte hämta ny beskrivning ifrån AI",
    19001: "Kan ej hämta ingredienser & kategorier",
    19002: "Kan ej hämta recept på vald kategori",
    19003: "Kan ej hämta valt recept",
    19004: "Kan ej ta bort ingrediens",
    19005: "Kan ej hämta info ifrån Edamam api",
    19006: "Kan ej lägga till ingrediens",
    19007: "Kan ej spara recept",
    19008: "kan ej hämta kommentar ifrån AI",
    19009: "Kan ej översätta ingrediens",
    19010: "Kan ej ta bort recept",
    19011: "Kan inte uppdatera bakgrundsnyans",
    19012: "Kan inte uppdatera meny nyans",
    19013: "Kan inte översätta text",
    19015:  "Kan inte hämta bilder från server",
    19016: "Kan inte ta bort bild på server",
    19017: "Kan inte ladda upp bild på server",
    19018: "Kan intes skapa AI bild på server",
    20001: "Kan ej hämta data",
    20002: "Kan ej hämta data i kategori",
    20003: "Kan ej hämta produkt rader",
    20004: "Kan ej hämta pris på ingrediens",
    20005: "Kan ej lägga till recept",
    20006: "Kan ej lägga till förpackning",
    20007: "Kan ej spara produkt",
    20008: "Kan inte uppdatera bakrunds nyans",
    20009: "Kan inte uppdatera meny nyans",
    20010: "Kan ej ta bort produkt",
    20011: "Kan inte hämta bilder",
    20012: "Kan inte hämta data ifrån server",
    20013: "Kan inte generera AI bild",
    20014:"Kan inte ta bort bild på server",
    20015:"Kan inte ladda upp bild på server",
    21001: "Kan ej hämta produktions data",
    21002: "Kan inte uppdatera bakrunds nyans",
    21003: "Kan inte uppdatera meny nyans",
    22001: "Kan ej hämta klarskriven data",
    22002: "Kan ej hämta produktionssummering",
    22003: "Kan ej hämta kalkylerad data",
    22004: "Kan ej uppdatera beskrivning",
    22005: "kan inte uppdatera bakrunds nyans",
    22006: "Kan inte uppdatera menynyans",
    23001: "Kan ej hämta produk kategorier",
    23002: "Kan ej hämta produktionsdata",
    23003: "Kan ej hämta produkter i vald kategori",
    23004: "Kan ej hämta pågående produktion",
    23005: "Kan ej hämta historisk data",
    23006: "Kan ej klarmarkera produkt",
    23007: "kan ej spara produktion",
    23008: "Kan ej skapa produktion",
    23009: "Kan ej klarskriva produktion",
    23010: "Kan ej uppdatera bakrunds nyans",
    23011: "Kan ej uppdatera meny nyans",
    23012: "Kan inte ta bort produkt ur produktion",
    24001: "Kan ej hämta ingredienstotaler",
    24002: "Kan ej hämta användarinställningar",
    24003: "Kan ej spara användarinställningar",
    24004: "Kan inte uppdatera bakgrundsnyans",
    24005: "Kan inte uppdatera meny nyans",
    25001: "Kan ej hämta produktkategorier",
    25002: "Kan ej hämta produkter i vald kategori",
    25003: "Kan ej hämta graf på vald produkt",
    25004: "Kan inte uppdatera bakrunds nyans",
    25005: "Kan inte uppdatera meny nyans",
    26001: "Kan ej hämta prisdata",
    26002: "Kan ej hämta prisdata i kategori",
    26003: "Kan ej uppdatera pris på produkt",
    26004: "kan inte uppdatera bakrunds nyans",
    26005: "Kan inte uppdater meny nyans",
    27001: "Kan inte hämta data",
    27002: "Kan inte hämta data",
    27003: "Kan inte spara ingrediens",
    27004: "Kan inte hämta data",
    27005: "Kan inte hämta data",
    27006: "Kan inte hämta recept/produkt",
    27007: "Kan inte hämta data",
    27008: "Kan inte hämta data",
    27009: "Kan inte översätta ingrediens",
    27010: "Kan inte hämta information ifrån API",
    27011: "Kan inte uppdatera ingrediens namn",
    27012: "Kan inte ersätta ingrediens",
    27013: "Kan inte ersätta ingrediens",
    27014: "Kan inte spara pris",
    27015: "Kan inte ta bort ingrediens",
    27016: "Kan inte spara näringsämnen",
    27017: "Kan inte konvertea information",
    27018: "Kan inte hämta data",
    27019: "Kan inte uppdatera bakgrunds nyans",
    27020: "Kan inte uppdatera meny nyans",
    27021: "Kan inte skapa JAP Ingrediens",
    27022: "Kan inte uppdatera JAP Ingrediens Namn",
    28001: "Kan ej hämta ingredienser & kategorier",
    28002: "Kan ej hämta ingredienser i vald kategori",
    28003: "Kan ej hämta valt recept",
    28004: "Kan inte hämta kommentarer",
    28005: "Kan inte översätta ingrediens",
    28006: "Kan inte hämta information ifrån API",
    28008: "Kan inte spara recept",
    28009: "Kan inte uppdatera bakrundsnyans",
    28010: "Kan inte uppdatera meny nyans",
    28011: "Kan inte hämta bild ifrån server",
    28012: "Kan inte skapa bild på server",
    28013: "Kan ej hitta någon bild på valt recept",
    28014: "Kan inte ta bort bild",
    28015: "Kan inte ladda upp bild på server",
    29001: "Kan ej hämta produkt/kategorier",
    29002: "Kan ej skapa produkt kategori",
    29003: "Kan ej hämta produkter i kategori",
    29004: "Kan ej flytta produkt till vald kategori",
    29005: "Kan ej flytta produkter till kategori övrigt",
    29006: "Kan ej flytta produkter till ny kategori",
    29007: "Kan inte uppdatera bakrundsnyans",
    29008: "Kan inte uppdatera meny nyans",
    29009: "Kan inte ta bort kategori",
    29010: "Kan inte flytta produkter till annan kategori",
    29011: "Kan inte skapa ny JAP produkt kategori",
    30001: "Kan ej hämta recept kategorier",
    30002: "Kan ej skapa ny receptkategori",
    30003: "Kan ej hämta recept i kategori",
    30004: "Kan ej flytta recept till annan kategori",
    30005: "Kan ej flytta recept till annan kategori",
    30006: "Kan ej flytta recept till kategori övrigt",
    30007: "Kan inte uppdatera bakrunds nyans",
    30008: "Kan inte uppdatera meny nyans",
    30009: "Kan ej ta bort kategori",
    30010: "Kan ej flytta ingredienser till annan kategori",
    30011: "Kan ej skapa ny kategori",
    31001: "Kan inte hämta kategorier",
    31002: "Kan inte hämta data för vald ingrediens",
    31003: "kan inte uppdatera aktiv status för vald ingrediens",
    31004: "Kan inte uppdatera bakgrundsnyans",
    31005: "Kan inte uppdatera meny nyans",
    33001:"Kan ej hämta sid data",
    33003:"Kan ej spara sidan",
    33004:"Kan ej hämta färginställningar",
    41001: "Kan inte hämta kategorier",
    41002: "Kan inte hämta recept i vald kategori",
    41003: "kan inte uppdatera aktiv status för vald ingrediens",
    41004: "Kan inte uppdatera bakgrundsnyans",
    41005: "Kan inte uppdatera meny nyans",
    51001: "Kan inte hämta kategorier",
    51002: "Kan inte hämta produkter i vald kategori",
    51003: "kan inte uppdatera aktiv status för vald ingrediens",
    51004: "Kan inte uppdatera bakrundsnyans",
    51005: "Kan inte uppdatera meny nyans",
    52000: "Kan inte hämta bakrundsbild",
    53000:"Kan inte hämta användar Data statistik",
    53001:"Kan inte uppdatera bakrundsnyans",
    53002:"Kan inte uppdatera meny nyans",
    53004:"Kan inte skicka email med användar data",
    53005:"Kan inte hämta användar Data uppgifter",
    53006:"Kan inte hämta adress Data",
    53007:"Kan inte uppdatera adress Data",
    53008:"Kan inte skapa nytt abonnemang",
    53013:"Kan inte uppdatera recept",
    53015:"Kan inte hämta faktura data",
    53016:"Kan inte skicka Support meddelande",
    53017:"Kan inte hämta support meddelande",
    53019:"Kan inte ladda hem faktura",
    53020:"Kan inte uppdatera kundbetalning",
    53021:"Kan inte skapa session på klarna",
    53022:"Klarna betalning kunde inte genomföras",
    53023:"Kan inte skapa session på klarna för konto setup",
    53024:"Kan inte slutföra köp för konto setup",
    53025:"Kan inte uppdatera support ticket",
    53026:"Kan inte ta bort användares uppgifter efter att konto setup gått fel",
    53027:"Kan inte hämta faktura uppgifter",
    53028:"Kan ej kontakta betalningsleverantör,kontakta support",
    53029:"Kan inte uppdatera tjänster,kontakta support",
    54001:"Email hittades inte! Vänligen registrera konto!",
    54002:"E-postadress ej korrekt",
    54003:"E-postadress finns ej registrerad",
    55001:"Kan inte hämta kategorier",
    55002:"Kan inte uppdatera bakrundsnyans",
    55003:"Kan inte uppdatera meny nyans",
    55004:"Kan inte hämta bilder på server",
    55005:"Kan inte hämta data från server",
    56001:"Informations fel",
    56002:"Kan inte skapa användare med facebook konto",
    56003:"Kan inte skapa användare med Line konto",
    56004:"Kan inte skapa användare med denna epost adress",
    
    57001:"Kan inte hämta produkt data",
    57002:"Kan inte hämta produkt data i kategori",
    58001:"Kan inte hämta faktura status användare, kontakta support!",
    58003:"Kan inte avsluta tjänst, kontakta support!",
    errMenu:{
        felkod: "felkod:",
        btnMessage: "Stäng",
        
    }
    },

    en: {
        2001: "Cannot retrieve data",
        2002: "Cannot create product",
        2003: "Cannot retrieve data",
        2004: "Cannot update product",
        2005: "Cannot retrieve data",
        2006: "Cannot retrieve data",
        2007: "Cannot deactivate product",
        2008: "Cannot update name",
        2009: "Cannot update category",
        2010: "Cannot retrieve ID from server",
        3001: "Cannot retrieve data",
        3002: "Cannot retrieve data",
        3003: "Cannot retrieve recipe/product",
        3004: "Cannot retrieve history from server",
        3005: "Cannot retrieve article data",
        3006: "Cannot update supplier",
        3007: "Cannot update ingredient in recipe",
        3008: "Cannot create new ingredient",
        3009: "Cannot update ingredient",
        4001: "Cannot retrieve ingredient/categories",
        4002: "Cannot create new category",
        4003: "Cannot retrieve ingredients in category",
        4004: "Cannot move ingredient to another category",
        4005: "Ingredient cannot be moved to category-Other",
        4006: "Category cannot be removed",
        4007: "Cannot move ingredient to another category",
        4008: "Cannot move ingredient to category Other",
        4009: "Cannot move ingredient to another category",
        4010: "Cannot update background hue",
        4011: "Cannot update menu hue",
        4013: "Cannot remove category",
        4014: "Cannot create new JAP user",
        5001: "Cannot retrieve product categories",
        5002: "Cannot retrieve products in category",
        5003: "Product/s have been placed in category-99",
        5004: "Cannot move products to new category",
        5005: "Cannot create new category",
        5006: "Cannot remove category",
        5008: "Cannot move products to category other",
        6001: "Cannot retrieve suppliers",
        6002: "Cannot retrieve packaging from selected supplier",
        6003: "Cannot save purchase",
        6004: "Cannot create new packaging",
        6005: "Cannot update packaging",
        6006: "Cannot remove packaging",
        7001: "Cannot retrieve recipe categories",
        7002: "Cannot retrieve recipe/main",
        7003: "Cannot retrieve recipe lines",
        7004: "Cannot retrieve ingredient categories",
        7005: "Cannot retrieve recipes for selected category",
        7006: "Cannot retrieve recipes",
        7007: "Cannot update order of work",
        7008: "Cannot retrieve ingredients for selected category",
        7009: "Cannot update recipe",
        8001: "Cannot retrieve recipe categories",
        8002: "Cannot create new recipe category",
        8003: "Cannot retrieve recipes in selected category",
        8004: "Cannot move recipe to selected category",
        8005: "Recipe cannot be removed",
        8006: "Recipe category cannot be removed",
        9001: "Cannot retrieve ingredients",
        9002: "Cannot retrieve ingredients for selected category",
        9003: "Cannot retrieve recipe ID",
        9004: "Cannot retrieve selected recipe",
        9005: "Cannot create recipe",
        10001: "Cannot retrieve product categories",
        10002: "Cannot retrieve products in selected category",
        10003: "Cannot finalize product",
        10004: "Cannot retrieve historical data",
        10005: "Cannot save production",
        10006: "Cannot complete production",
        10007: "Cannot create production",
        10008: "Cannot retrieve ongoing production",
        11001: "Cannot retrieve completed production",
        11002: "Cannot retrieve production summary",
        11003: "Cannot retrieve calculated data",
        11004: "Cannot update description",
        12001: "Cannot retrieve total production data",
        12002: "Cannot update production status",
        13001: "Cannot retrieve total production data",
        14001: "Cannot retrieve ingredient totals",
        15001: "Cannot retrieve product categories",
        15002: "Cannot retrieve products in selected category",
        15003: "Cannot retrieve graph for selected product",
        16001: "Cannot retrieve suppliers",
        16002: "Cannot retrieve supplier data",
        16003: "Cannot update delivery",
        17001: "Cannot retrieve ingredients & categories",
        17002: "Cannot find ingredients",
        17003: "Cannot update ingredient",
        17004: "Cannot add new ingredient",
        17005: "Cannot save recipe",
        17006: "Cannot translate ingredient",
        17007: "Cannot retrieve information from API",
        17008: "Cannot update background shade",
        17009: "Cannot update menu shade",
        17010: "Cannot report to debit",
        17011:"Cannot find recipe ideas for search",
        17012:"Cannot retrieve recipe ideas",
        17013:"Cannot retrieve latest recipe search",
        17014:"Cannot update background shade",
        17015:"Cannot update menu shade",
        18001: "Cannot translate text",
        18002: "Cannot retrieve information from EDAMAM API",
        18003: "Cannot return information from EDAMA API",
        18004: "Cannot update the database with values from EDAMA API",
        18005: "Cannot delete data for selected id in b_ingred_nutrient",
        18006: "Cannot retrieve new description from AI",
        19001: "Cannot retrieve ingredients & categories",
        19002: "Cannot retrieve recipes for selected category",
        19003: "Cannot retrieve selected recipe",
        19004: "Cannot remove ingredient",
        19005: "Cannot retrieve info from Edamam api",
        19006: "Cannot add ingredient",
        19007: "Cannot save recipe",
        19008: "Cannot retrieve comment from AI",
        19009: "Cannot translate ingredient",
        19010: "Cannot remove recipe",
        19011: "Cannot update background shade",
        19012: "Cannot update menu shade",
        19013: "Cannot translate text",
        19015: "Cannot retrieve images from server",
        19016: "Cannot remove image from server",
        19017: "Cannot upload image on server",
        19018: "Cannot create AI image on server",
        20001: "Cannot retrieve data",
        20002: "Cannot retrieve data in category",
        20003: "Cannot retrieve product lines",
        20004: "Cannot retrieve price of ingredient",
        20005: "Cannot add recipe",
        20006: "Cannot add packaging",
        20007: "Cannot save product",
        20008: "Cannot update background shade",
        20009: "Cannot update menu shade",
        20010: "Cannot remove product",
        20011: "Cannot retrieve images",
        20012: "Cannot retrieve data from server",
        20013: "Cannot generate AI image",
        20014:"Cannot remove image from server",
        20015:"Cannot upload image on server",
        21001: "Cannot retrieve production data",
        21002: "Cannot update background shade",
        21003: "Cannot update menu shade",
        22001: "Cannot retrieve finalized data",
        22002: "Cannot retrieve production summary",
        22003: "Cannot retrieve calculated data",
        22004: "Cannot update description",
        22005: "Cannot update background shade",
        22006: "Cannot update menu shade",
        23001: "Cannot retrieve product categories",
        23002: "Cannot retrieve production data",
        23003: "Cannot retrieve products in selected category",
        23004: "Cannot retrieve ongoing production",
        23005: "Cannot retrieve historical data",
        23006: "Cannot finalize product",
        23007: "Cannot save production",
        23008: "Cannot create production",
        23009: "Cannot finalize production",
        23010: "Cannot update background shade",
        23011: "Cannot update menu shade",
        23012: "Cannot remove product from production",
        24001: "Cannot retrieve ingredient totals",
        24002: "Cannot retrieve user settings",
        24003: "Cannot save user settings",
        24004: "Cannot update background shade",
        24005: "Cannot update menu shade",
        25001: "Cannot retrieve product categories",
        25002: "Cannot retrieve products in selected category",
        25003: "Cannot retrieve graph for selected product",
        25004: "Cannot update background shade",
        25005: "Cannot update menu shade",
        26001: "Cannot retrieve price data",
        26002: "Cannot retrieve price data in category",
        26003: "Cannot update price of product",
        26004: "Cannot update background shade",
        26005: "Cannot update menu shade",
        27001: "Cannot retrieve data",
        27002: "Cannot retrieve data",
        27003: "Cannot save ingredient",
        27004: "Cannot retrieve data",
        27005: "Cannot retrieve data",
        27006: "Cannot retrieve recipe/product",
        27007: "Cannot retrieve data",
        27008: "Cannot retrieve data",
        27009: "Cannot translate ingredient",
        27010: "Cannot retrieve information from API",
        27011: "Cannot update ingredient name",
        27012: "Cannot replace ingredient",
        27013: "Cannot replace ingredient",
        27014: "Cannot save price",
        27015: "Cannot remove ingredient",
        27016: "Cannot save nutrients",
        27017: "Cannot convert information",
        27018: "Cannot retrieve data",
        27019: "Cannot update background shade",
        27020: "Cannot update menu shade",
        27021: "Cannot create JAP Ingredient",
        27022: "Cannot update JAP Ingredient Name",
        28001: "Cannot retrieve ingredients & categories",
        28002: "Cannot retrieve ingredients in selected category",
        28003: "Cannot retrieve selected recipe",
        28004: "Cannot retrieve comments",
        28005: "Cannot translate ingredient",
        28006: "Cannot retrieve information from API",
        28008: "Cannot save recipe",
        28009: "Cannot update background shade",
        28010: "Cannot update menu shade",
        28011: "Cannot fetch image on server",
        28012: "Cannot create image on server",
        28013: "Cannot find image related to this recipe",
        28014: "Cannot delete image",
        28015: "Cannot upload image",
        29001: "Cannot retrieve product/categories",
        29002: "Cannot create product category",
        29003: "Cannot retrieve products in category",
        29004: "Cannot move product to selected category",
        29005: "Cannot move products to category other",
        29006: "Cannot move products to new category",
        29007: "Cannot update background shade",
        29008: "Cannot update menu shade",
        29009: "Cannot remove category",
        29010: "Cannot move products to another category",
        29011: "Cannot create new JAP product category",
        30001: "Cannot retrieve recipe categories",
        30002: "Cannot create new recipe category",
        30003: "Cannot retrieve recipes in category",
        30004: "Cannot move recipe to another category",
        30005: "Cannot move recipe to another category",
        30006: "Cannot move recipe to category other",
        30007: "Cannot update background shade",
        30008: "Cannot update menu shade",
        30009: "Cannot remove category",
        30010: "Cannot move ingredients to other category",
        30011: "Cannot create new category",
        31001: "Cannot retrieve categories",
        31002: "Cannot retrieve data for selected ingredient",
        31003: "Cannot update active status for selected ingredient",
        31004: "Cannot update background shade",
        31005: "Cannot update menu shade",
        33001:"Cannot fetch page data",
        33003:"Cannot save the page",
        33004:"Cannot get color settings",
        41001: "Cannot retrieve categories",
        41002: "Cannot retrieve recipes in selected category",
        41003: "Cannot update active status for selected ingredient",
        41004: "Cannot update background shade",
        41005: "Cannot update menu shade",
        51001: "Cannot retrieve categories",
        51002: "Cannot retrieve products in selected category",
        51003: "Cannot update active status for selected ingredient",
        51004: "Cannot update background shade",
        51005: "Cannot update menu shade",
        52000: "Cannot fetch background image",
        53000:"Cannot fetch user Data usage",
        53001:"Cannot update background shade",
        53002:"Cannot update menu shade",
        53004:"Cannot send email with user data",
        53005:"Cannot fetch user Data",
        53006:"Cannot fetch address Data",
        53007:"Cannot update address Data",
        53008:"Cannot create new subscription",
        53013:"Cannot update prescription",
        53015:"Cannot fetch invoice data",
        53016:"Cannot send Support message",
        53017:"Cannot fetch Support message",
        53019:"Cannot download invoice",
        53020:"Cannot update customer payment",
        53021:"Cannot create session on klarna",
        53022:"Klarna payment could not be completed",
        53023:"Cannot create session on klarna for account setup",
        53024:"Cannot complete purchase for account setup",
        53025:"Cannot update support ticket",
        53026:"Cannot remove user data after account setup failed",
        53027:"Cannot fetch user billing data",
        53028:"Cannot contact payment provider, please contact support",
        53029:"Cannot update services, please contact support",
        54001:"Email not found! Please register account",
        54002:"Email address not correct",
        54003:"Emailadress not registered",    
        55001:"Cannot retrieve categories",
        55002:"Cannot update background shade",
        55003:"Cannot update menu shade",
        55004:"Cannot fetch images from server",
        55005:"Cannot fetch data from server",
        56001:"Information error",
        56002:"Cannot create user with facebook account",
        56003:"Cannot create user with Line account",
        56004:"Cannot create user with this email address",
    
        57001:"Cannot retrieve product data",
        57002:"Cannot retrieve product data in category",
        58001:"Cannot retrieve invoice status user, contact support!",
        58003:"Cannot end service, contact support!",
        errMenu: {
            felkod: "error code:",
            btnMessage: "Close",
        }
    },

    ja: {
        2001: "データを取得できません",
        2002: "製品を作成できません",
        2003: "データを取得できません",
        2004: "製品を更新できません",
        2005: "データを取得できません",
        2006: "データを取得できません",
        2007: "製品を非アクティブ化できません",
        2008: "名前を更新できません",
        2009: "カテゴリを更新できません",
        2010: "サーバーからIDを取得できません",
        3001: "データを取得できません",
        3002: "データを取得できません",
        3003: "レシピ/製品を取得できません",
        3004: "サーバーから履歴を取得できません",
        3005: "記事データを取得できません",
        3006: "供給者を更新できません",
        3007: "レシピの成分を更新できません",
        3008: "新しい成分を作成できません",
        3009: "成分を更新できません",
        4001: "成分/カテゴリを取得できません",
        4002: "新しいカテゴリを作成できません",
        4003: "カテゴリ内の成分を取得できません",
        4004: "別のカテゴリに成分を移動できません",
        4005: "成分はその他のカテゴリに移動できません",
        4006: "カテゴリを削除できません",
        4007: "別のカテゴリに成分を移動できません",
        4008: "その他のカテゴリに成分を移動できません",
        4009: "別のカテゴリに成分を移動できません",
        4010: "背景の色合いを更新できません",
        4011: "メニューの色合いを更新できません",
        4013: "カテゴリを削除できません",
        4014: "新しいカテゴリを作成できません",
        5001: "製品カテゴリを取得できません",
        5002: "カテゴリ内の製品を取得できません",
        5003: "製品がカテゴリ99に配置されました",
        5004: "新しいカテゴリに製品を移動できません",
        5005: "新しいカテゴリを作成できません",
        5006: "カテゴリを削除できません",
        5008: "他のカテゴリに製品を移動できません",
        6001: "供給者を取得できません",
        6002: "選択した供給者から包装を取得できません",
        6003: "購入を保存できません",
        6004: "新しい包装を作成できません",
        6005: "包装を更新できません",
        6006: "包装を削除できません",
        7001: "レシピカテゴリを取得できません",
        7002: "主要レシピを取得できません",
        7003: "レシピ行を取得できません",
        7004: "成分カテゴリを取得できません",
        7005: "選択したカテゴリのレシピを取得できません",
        7006: "レシピを取得できません",
        7007: "作業順序を更新できません",
        7008: "選択したカテゴリの成分を取得できません",
        7009: "レシピを更新できません",
        8001: "レシピカテゴリを取得できません",
        8002: "新しいレシピカテゴリを作成できません",
        8003: "選択したカテゴリのレシピを取得できません",
        8004: "選択したカテゴリにレシピを移動できません",
        8005: "レシピを削除できません",
        8006: "レシピカテゴリを削除できません",
        9001: "成分を取得できません",
        9002: "選択したカテゴリの成分を取得できません",
        9003: "レシピIDを取得できません",
        9004: "選択したレシピを取得できません",
        9005: "レシピを作成できません",
        10001: "製品カテゴリを取得できません",
        10002: "選択したカテゴリの製品を取得できません",
        10003: "製品の仕上げができません",
        10004: "履歴を取得できません",
        10005: "生産を保存できません",
        10006: "生産を完了できません",
        10007: "生産を作成できません",
        10008: "現在進行中の生産を取得できません",
        11001: "完了した生産を取得できません",
        11002: "生産の要約を取得できません",
        11003: "計算されたデータを取得できません",
        11004: "説明を更新できません",
        12001: "総生産データを取得できません",
        12002: "生産ステータスを更新できません",
        13001: "総生産データを取得できません",
        14001: "成分の合計を取得できません",
        15001: "製品カテゴリを取得できません",
        15002: "選択したカテゴリの製品を取得できません",
        15003: "選択した製品のグラフを取得できません",
        16001: "供給者を取得できません",
        16002: "供給者データを取得できません",
        16003: "デリバリーを更新できません",
        17001: "成分およびカテゴリを取得できません",
        17002: "成分を見つけることができません",
        17003: "成分を更新できません",
        17004: "新しい成分を追加できません",
        17005: "レシピを保存できません",
        17006: "成分を翻訳できません",
        17007: "APIから情報を取得できません",
        17008: "背景の陰影を更新できません",
        17009: "メニューの陰影を更新できません",
        17010: "デビットに報告できません",
        17011:"検索のためのレシピアイデアが見つかりません",
        17012:"レシピアイデアを取得できません",
        17013:"最新のAI検索にアクセスできません",
        17014:"背景の陰影を更新できません",
        17015:"メニューの陰影を更新できません",
        18001: "テキストを翻訳できません",
        18002: "EDAMAM APIから情報を取得できません",
        18003: "EDAMA APIから情報を返せません",
        18004: "EDAMA APIの値でデータベースを更新できません",
        18005: "b_ingred_nutrientで選択されたidのデータを削除できません",
        18006: "AIから新しい説明を取得できません",
        19001: "成分およびカテゴリを取得できません",
        19002: "選択したカテゴリのレシピを取得できません",
        19003: "選択したレシピを取得できません",
        19004: "成分を削除できません",
        19005: "Edamam apiから情報を取得できません",
        19006: "成分を追加できません",
        19007: "レシピを保存できません",
        19008: "AIからコメントを取得できません",
        19009: "成分を翻訳できません",
        19010: "レシピを削除できません",
        19011: "背景の陰影を更新できません",
        19012: "メニューの陰影を更新できません",
        19013: "テキストを翻訳できません",
        19015:  "サーバーから画像を取得できません",
        19016: "サーバーから画像を削除できません",
        19017: "サーバーに画像をアップロードできません",
        19018: "サーバーでAI画像を生成できません",
        20001: "データを取得できません",
        20002: "カテゴリ内のデータを取得できません",
        20003: "製品ラインを取得できません",
        20004: "成分の価格を取得できません",
        20005: "レシピを追加できません",
        20006: "包装を追加できません",
        20007: "製品を保存できません",
        20008: "背景の陰影を更新できません",
        20009: "メニューの陰影を更新できません",
        20010: "製品を削除できません",
        20011: "画像を取得できません",
        20012: "サーバーからデータを取得できません",
        20013: "AI画像を生成できません",
        20014:"サーバーから画像を削除できません ",
        20015:"サーバーに画像をアップロードできません",
        21001: "生産データを取得できません",
        21002: "背景の陰影を更新できません",
        21003: "メニューの陰影を更新できません",
        22001: "最終データを取得できません",
        22002: "生産の要約を取得できません",
        22003: "計算されたデータを取得できません",
        22004: "説明を更新できません",
        22005: "背景の陰影を更新できません",
        22006: "メニューの陰影を更新できません",
        23001: "製品カテゴリを取得できません",
        23002: "生産データを取得できません",
        23003: "選択したカテゴリの製品を取得できません",
        23004: "現在進行中の生産を取得できません",
        23005: "歴史的データを取得できません",
        23006: "製品の最終化ができません",
        23007: "生産を保存できません",
        23008: "生産を作成できません",
        23009: "生産を最終化できません",
        23010: "背景の陰影を更新できません",
        23011: "メニューの陰影を更新できません",
        23012: "生産から製品を削除できません",
        24001: "成分の合計を取得できません",
        24002: "ユーザー設定を取得できません",
        24003: "ユーザー設定を保存できません",
        24004: "背景の陰影を更新できません",
        24005: "メニューの陰影を更新できません",
        25001: "製品カテゴリを取得できません",
        25002: "選択したカテゴリの製品を取得できません",
        25003: "選択した製品のグラフを取得できません",
        25004: "背景の陰影を更新できません",
        25005: "メニューの陰影を更新できません",
        26001: "価格データを取得できません",
        26002: "カテゴリ内の価格データを取得できません",
        26003: "製品の価格を更新できません",
        26004: "背景の陰影を更新できません",
        26005: "メニューの陰影を更新できません",
        27001: "データを取得できません",
        27002: "データを取得できません",
        27003: "成分を保存できません",
        27004: "データを取得できません",
        27005: "データを取得できません",
        27006: "レシピ/製品を取得できません",
        27007: "データを取得できません",
        27008: "データを取得できません",
        27009: "成分を翻訳できません",
        27010: "APIから情報を取得できません",
        27011: "成分名を更新できません",
        27012: "成分を置き換えることができません",
        27013: "成分を置き換えることができません",
        27014: "価格を保存できません",
        27015: "成分を削除できません",
        27016: "栄養素を保存できません",
        27017: "情報を変換できません",
        27018: "データを取得できません",
        27019: "背景の陰影を更新できません",
        27020: "メニューの陰影を更新できません",
        27021: "JAPAN成分を作成できません",
        27022: "JAPAN成分名を更新できません",
        28001: "成分およびカテゴリを取得できません",
        28002: "選択したカテゴリの成分を取得できません",
        28003: "選択したレシピを取得できません",
        28004: "コメントを取得できません",
        28005: "成分を翻訳できません",
        28006: "APIから情報を取得できません",
        28008: "レシピを保存できません",
        28009: "背景の陰影を更新できません",
        28010: "メニューの陰影を更新できません",
        28011: "テキストを翻訳できません",
        28012: "AIから画像を生成できません",
        28013: "データを取得できません",
        28014: "画像を削除できません",
        28015: "サーバーに画像をアップロードできません。",
        29001: "製品/カテゴリを取得できません",
        29002: "製品カテゴリを作成できません",
        29003: "カテゴリ内の製品を取得できません",
        29004: "選択したカテゴリに製品を移動できません",
        29005: "他のカテゴリに製品を移動できません",
        29006: "新しいカテゴリに製品を移動できません",
        29007: "背景の陰影を更新できません",
        29008: "メニューの陰影を更新できません",
        29009: "カテゴリを削除できません",
        29010: "別のカテゴリに製品を移動できません",
        29011: "新しいJAPAN製品カテゴリを作成できません",
        30001: "レシピカテゴリを取得できません",
        30002: "新しいレシピカテゴリを作成できません",
        30003: "カテゴリ内のレシピを取得できません",
        30004: "別のカテゴリにレシピを移動できません",
        30005: "別のカテゴリにレシピを移動できません",
        30006: "他のカテゴリにレシピを移動できません",
        30007: "背景の陰影を更新できません",
        30008: "メニューの陰影を更新できません",
        30009: "カテゴリを削除できません",
        30010: "成分を他のカテゴリに移動できません",
        30011: "新しいカテゴリを作成できません",
        31001: "カテゴリを取得できません",
        31002: "選択した成分のデータを取得できません",
        31003: "選択した成分のアクティブステータスを更新できません",
        31004: "背景の陰影を更新できません",
        31005: "メニューの陰影を更新できません",
        33001: "ページデータを取得できません",
        33003: "ページを保存できません",
        33004: "カラー設定を取得できません",
        41001: "カテゴリを取得できません",
        41002: "選択したカテゴリのレシピを取得できません",
        41003: "選択した成分のアクティブステータスを更新できません",
        41004: "背景の陰影を更新できません",
        41005: "メニューの陰影を更新でき",
        51001: "カテゴリを取得できません",
        51002: "選択したカテゴリの製品を取得できません",
        51003: "選択した成分のアクティブステータスを更新できません",
        51004: "背景の陰影を更新できません",
        51005: "メニューの陰影を更新できません",
        52000: "背景画像を取得できません",
        53000:"ユーザーデータ使用量を取得できません",
        53001:"背景の陰影を更新できません",
        53002:"メニューの陰影を更新できません",
        53004:"ユーザーデータを含むメールを送信できません",
        53005:"ユーザーデータを取得できません",
        53006:"アドレスデータを取得できません",
        53007:"アドレスデータを更新できません",
        53008:"新しいサブスクリプションを作成できません",
        53013:"処方箋を更新できません",
        53015:"請求書データを取得できません",
        53016:"サポートメッセージを送信できません",
        53017:"サポートメッセージを取得できません",
        53019:"請求書をダウンロードできません",
        53020:"顧客支払いを更新できません",
        53021:"Klarnaでセッションを作成できません",
        53022:"クラーナの支払いができませんでした",
        53023:"Klarnaでアカウント設定のセッションを作成できません",
        53024:"アカウント設定の購入を完了できません",
        53025:"サポートチケットを更新できません",
        53026:"アカウント設定が失敗した後、ユーザーデータを削除できません",
        53027:"ユーザー請求データを取得できません",
        53028:"支払いプロバイダーに連絡できません。サポートに連絡してください",
        53029:"サービスを更新できません。サポートに連絡してください",
    
        54001:"メールアドレスが見つかりません！アカウントを登録してください！",
        54002:"メールが正しくありません",
        54003:"メールアドレスが登録されていません！",
        55001:"カテゴリを取得できません",
        55002:"背景の陰影を更新できません",
        55003:"メニューの陰影を更新できません",
        55004:"サーバーから画像を取得できません",
        55005:"サーバーからデータを取得できません",
        56001:"情報エラー",
        56002:"Facebookアカウントでユーザーを作成できません",
        56003:"Lineアカウントでユーザーを作成できません",
        56004:"このメールアドレスでユーザーを作成できません",
    
        57001:"製品データを取得できません",
        57002:"カテゴリ内の製品データを取得できません",
        58001:"請求書ステータスユーザーを取得できません、サポートに連絡してください！",
        58003:"サービスを終了できません、サポートに連絡してください！",
        errMenu: {
            felkod: "エラーコード:",
            btnMessage: "閉じる",
        }
    }
}



export default AppError;



