import React,{useEffect,useState} from 'react'
import {useGetData,usePutData} from '../hooks/useGetData';
import {RotatingLines } from 'react-loader-spinner';
import Alert from '../functions/appAlert';
import useReportErr from '../hooks/useReportErr';
import './Linstingred1.css'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall';
import { ImCheckboxChecked } from "react-icons/im";
import { ImCheckboxUnchecked } from "react-icons/im";


function ListIngred() {

    const[getKategori,setKategori]=useState([{}])
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const[getAlertCode,setAlertCode]=useState(0)
    const[getAlertMessage,setAlertMessage]=useState('') // this is the message for the alert
    const[getShowAlert,setShowAlert]=useState(false) // this is the alert
    const[getBackGroundShade,setBackGroundShade]=useState(0.75)
    const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
    const[getMenuShade,setMenuShade]=useState(0.95)
    const[getMenuShade2,setMenuShade2]=useState(0.75)
    const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
    const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
    const[getIngredList,setIngredList]=useState([{}]) // this is the list of ingredients
    const[getchosenCategory,setchosenCategory]=useState(0) // this is the chosen category
    const[ingredData,setIngredData]=useState([]) // this is the data for the ingredients
    const[getButtonSavedStatus,setButtonSavedStatus]=useState(false) // this is the button saved status
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const [pageid]=useState(1)
    const [logtime]=useState(5000) // this is the time for the alert message to be shown
    const[getCountry,setCountry]=useState('en') // this is the country code
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the mobile is used

    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
   

    useEffect(()=>{
      let country = localStorage.getItem('country')||'en' // this is the country code
      setCountry(country)
    },[])




  let newFontFamily = '';
  if (getCountry === 'ja') {
     newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  } else {
    newFontFamily = 'Arial, Helvetica, sans-serif'
  //newFontFamily = 'Times New Roman, Times, serif'
  //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  }
  document.documentElement.style.setProperty('--font-family', newFontFamily);

  




        const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal} = usePageSettings(pageid);
        const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal);
   
        const navigate = useNavigate();
//alert(windowWidth)
// console.log(getCountry)
// console.log(newFontFamily)
useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    }

  }

  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
        

        function rgbToRgba(rgb, alpha) {
          return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
        }
        
      //--bakcground shade settings
      let rgbaVal
      let rgbaVal2
      let val
      
      if(getColorVal && getColorVal.length > 0){
        let rgb = getColorVal;
        let alpha = getMenuShade; // replace with your alpha value
        let alpha2 = getMenuShade2; // replace with your alpha value
        rgbaVal = rgbToRgba(rgb, alpha);
        rgbaVal2 = rgbToRgba(rgb, alpha2);
        val=`rgba(255, 255, 255, ${getBackGroundShade})`
      
        }else{
          rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
          rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
          val=`rgba(255, 255, 255, ${getBackGroundShade})`
        }
      //---
      
      useEffect(() => {
          let root = document.documentElement;
          root.style.setProperty('--rgbaVal', rgbaVal);
          root.style.setProperty('--rgbaVal2', rgbaVal2);
          root.style.setProperty('--val',val);
          root.style.setProperty('--windowHeight',windowHeight+'px');
          
        }, [getBackGroundShade,getMenuShade,getMenuShade2]);
      
        //-----

      
      // language settings
      const selectedCountry = languages[getCountry];
       let ingredienslistaTitle=''
      if(getMobileIsUsed===false){
      ingredienslistaTitle=selectedCountry.ingredienslista?.title
      }else{
      ingredienslistaTitle=selectedCountry.ingredienslista?.titleMobile
      }
      const kategoriLegend=selectedCountry.ingredienslista?.kategoriLegend
      const headerId=selectedCountry.ingredienslista?.headerId
      const headerNamn=selectedCountry.ingredienslista?.headerNamn
      const headerAktiv=selectedCountry.ingredienslista?.headerAktiv
      const headerDela=selectedCountry.ingredienslista?.headerDela
      const headerSpara=selectedCountry.ingredienslista?.headerSpara
      const ListSaveButton=selectedCountry.ingredienslista?.ListSaveButton
      const SavedOnButton=selectedCountry.ingredienslista?.SavedOnButton
      const titleAdjustMenu=selectedCountry.ingredienslista?.titleAdjustMenu
      const titleAdjustMenu1=selectedCountry.ingredienslista?.titleAdjustMenu1
    

      // error messages
      const selectedError=AppError[localStorage.getItem('country')]
 
     



          useEffect(() => {
            GetSettings();
          }, [GetSettings]);

          // we set the image url here
          useEffect(() => {
            setImageUrl(imageUrl);
            if (alertCode > 0) {
              setAlertMessage(alertMessage);
              setAlertCode(alertCode);
              setShowAlert(showAlert);
            }
          }, [imageUrl]);
        
          // here we set the color values and image values
          useEffect(() => {
            setBackGroundShade(backGroundShade);
            setMenuShade(menuShade);
            setMenuShade2(menuShade2);
            setColorVal(colorVal);
            setImageVal(imageVal);
          }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

      //alert('width:'+ windowWidth + 'height:'+windowHeight)

          useEffect(()=>{
            async function FetchAllIngred() {
                setSpinnerLoading(true)
        
          
                try {
                  const response = await useGetData('getIngredCategories')
                  setKategori(response.data[0]);
                  setSpinnerLoading(false)
             
                } catch (error) {
                  setAlertCode(31001)
                  setAlertMessage(selectedError[3001])
                  setSpinnerLoading(false)
                  setShowAlert(true)
                  
                  return;
            
                }
          
                }
                FetchAllIngred();


          },[])


            
    async function FetchAllIngred(category) {
    setSpinnerLoading(true)
            
              
     try {
         const response = await useGetData(`getIngredByCategoryNew/${category}`)
        setIngredData(response.data[0]);
        setSpinnerLoading(false)
                 
        } catch (error) {
        setAlertCode(31002)
        setAlertMessage(selectedError[31002])
        setSpinnerLoading(false)
        setShowAlert(true)
        // setTimeout(() => {
        //   navigate('/Logout');
        // },logtime);
   
        return;
                
        }
              
}

const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);
      await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);
        
const fetchIngredHandler=(category)=>{
    FetchAllIngred(category)
}



const kategoriHandler=(val)=>{
    setchosenCategory(val)
}

const activeHandler=(ingredid)=>{
  setSpinnerLoading(true)
    let mynewObjArray =[...ingredData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.ingredid ===ingredid);
    // first we find the value of the active
    let active = mynewObjArray[index].active
    if(active===1){
        active=0
        mynewObjArray[index].buttonStatus=1
    }else{
        active=1
        mynewObjArray[index].buttonStatus=1
  
    }
    mynewObjArray[index].active=active
    // add buttonstatus to the object
    //mynewObjArray[index].buttonStatus=active
    mynewObjArray[index].buttonSaved=0
    setIngredData(mynewObjArray)
    setSpinnerLoading(false)

}

async function UpdateActive(ingredid){   
    setSpinnerLoading(true)
    // here lets find the active and shared values
    let mynewObjArray =[...ingredData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.ingredid===ingredid);
    // first we find the value of the active
    let active = mynewObjArray[index].active
    let shared = mynewObjArray[index].shared

    try{
      //  const response = await useGetData(`updateIngredActiveShared/${ingredid}/${active}/${shared}`)
        // here we make a object instead 
        const response=await usePutData(`updateIngredActiveShared`,{ingredid,active,shared})
        if(response.status===200){
        setSpinnerLoading(false)
        //here we must set buttonstatus back to 0
        mynewObjArray[index].buttonStatus=0
        mynewObjArray[index].buttonSaved=1
        setIngredData(mynewObjArray)
        }
    }catch(error){
        setAlertCode(31003)
        setAlertMessage(selectedError[31003])
        setSpinnerLoading(false)
        setShowAlert(true)
        return;
    }


}


const sharedHandler=(ingredid)=>{
    let mynewObjArray =[...ingredData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.ingredid ===ingredid);
    // first we find the value of the active
    let active = mynewObjArray[index].shared
    if(active===1){
        active=0

    }else{
        active=1
  
    }
    mynewObjArray[index].shared=active
    // add buttonstatus to the object
    mynewObjArray[index].buttonStatus=active
    setIngredData(mynewObjArray)
}

const saveHandler=(ingredid)=>{
    UpdateActive(ingredid)
}

// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{ pageid, val })
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
    setAlertCode(31004);
    setAlertMessage(selectedError[31004]);
    setSpinnerLoading(false);
    setShowAlert(true)
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{ pageid, val })
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(31005)
  setAlertMessage(selectedError[31005])
  setSpinnerLoading(false)
  setShowAlert(true)
}


}

  return (
<>
{windowWidth<360?displayGadgetToSmall():''}
{getBackGroundShade<1 ?<img src={imageUrl} alt="Bakesoft" className="backgroundImageListIngred" /> :
<div className="backgroundImageListIngred1"/>
}
 
    <div className="totalIngredListArea1">      
  
  <div className="xxheaderArea">
  {getCountry!=='ja'?
        <div className={getBackGroundShade<1?"compNameIngredList":"compNameIngredList1"}>{ingredienslistaTitle}</div>
       :<div className={getBackGroundShade<1?"compNameIngredJAP":"compNameIngredJAP1"}>{ingredienslistaTitle}</div>
        } 
    
  </div>
  <div className="xxrangeArea">

      <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
        title={titleAdjustMenu} className="xxarange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

      <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
        title={titleAdjustMenu1} className="xxarange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
    
  </div>



        <div className="xxareaIngredListB">
            <div className="ingredselectTop10">
            <fieldset className="ingredselectFieldset10">
                <legend className={getCountry!=='ja'?"ingredselectLegend10":"ingredselectLegendJAP10"}>&nbsp;{kategoriLegend}</legend>
                <select className={getCountry!=='ja'?"ingredselectIngred10":"ingredselectIngredJAP10"} onChange={(e)=>fetchIngredHandler(e.target.value)}>
                                 <option value='0' hidden>&nbsp;{kategoriLegend}&nbsp;!&nbsp;</option>
               {getKategori.map((data,index)=><option className="option" key={index} 
                                              value={data.kategoriid}>&nbsp;{data.benaemn}</option>)}
               </select>
 </fieldset>
        </div>

        <div className="datalistTop10">
            <button className={getCountry!=='ja'?"listingredid10":"listingredidJAP10"}>{headerId||'Id'}</button>
            <button className={getCountry!=='ja'?"listingredbenaemn10":"listingredbenaemnJAP10"}>{headerNamn||'Namn'}</button>
            <button className={getCountry!=='ja'?"listingredactive10":"listingredactiveJAP10"}>{headerAktiv||'Aktiv'}</button>
            <button className={getCountry!=='ja'?"listingredSave10":"listingredSaveJAP10"}>{headerSpara||'Spara'}</button>
        </div>
        <div className={getCountry!=='ja'?"datalistData10":"datalistDataJAP10"}>
            {ingredData.length>0?ingredData.map((data,index)=><div className="datalistDataRow"  key={index}>
                <button className={index%2===0?"datalistDataItemId10":"datalistDataItemId110"}>{data.ingredid}</button>
                <button className={index%2===0?"datalistbenaemn10":"datalistbenaemn110"}title={data.shared>data.shared?'Bakesoft Store':'Egen tillagd ingrediens'}>{data.benaemn}</button>
                <button className={index%2===0?"datalistDataItemActive10":"datalistDataItemActive11"}
                onClick={()=>activeHandler(data.ingredid)}
                
                >
                  {data.active===1?<ImCheckboxChecked className="dataitemChecked"/>:<ImCheckboxUnchecked className="dataitemUnchecked"/>}</button>
             
                 {getCountry!=='ja'?
                <button className={data.buttonStatus===1?"xdatabutton":"xdatabutton1"}
                onClick={()=>saveHandler(data.ingredid)} title={data.buttonStatus===1?ListSaveButton:''}>{data.buttonSaved===1?SavedOnButton:ListSaveButton}</button>
                :<button className={data.buttonStatus===1?"xdatabuttonJAP1":"xdatabuttonJAP2"}
                onClick={()=>saveHandler(data.ingredid)} title={data.buttonStatus===1?ListSaveButton:''}>{data.buttonSaved===1?SavedOnButton:ListSaveButton}</button>
                }



            </div>):null}

        </div>
        </div>
       

        <div className={"listSpinnerContainer"}>
        <div className={"listSpinner"}>
          <RotatingLines strokeColor="grey" strokeWidth={windowWidth<2000?"2":"5"} animationDuration="1" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

   

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert} getCountry={getCountry}/>:''}


    </div></>

  )
}

export default ListIngred


