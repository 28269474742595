import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,LineChart,Line,Bar,BarChart,Legend } from 'recharts';
import React,{useEffect,useState,useRef} from 'react'
import prodStat from '../hooks_styles/prodStat'
import useDeviceDetection from '../hooks/useDeviceDetection'
import './ProdStatNew1.css'
import GetProductStatisticsAreaChart from '../hooks/useGetStatistics'
import { GetProductStatisticsLineChart } from '../hooks/useGetStatistics'
import { GetProductStatisticsBarChart } from '../hooks/useGetStatistics'
import UseChartChoice from '../hooks/useChartChoice'
import './chart.css'
import Alert from '../functions/appAlert'
import {useGetData,usePutData} from '../hooks/useGetData'
import useReportErr from '../hooks/useReportErr'
import { RotatingLines } from  'react-loader-spinner'
import Componentname from '../hooks/Componentname'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'






export default function ProdStatNew() {


      
const[getProductCategories,setProductCategories]=useState([{}])
const [getchosenCategory,setchosenCategory]=useState(0)
const[getproductsinCategory,setproductsinCategory]=useState([{}])
const[getchosenProduct,setchosenProduct]=useState(0)
const[getchosenDataObj,setchosenDataObj]=useState([{}])
const[getchosenChart,setchosenChart]=useState(3)

const[getchosenProductname,setchosenProductName]=useState('')
const[getSpinnerLoading,setSpinnerLoading]=useState(true)
const [getShowAlert,setShowAlert] = useState(false);
const [getAlertMessage,setAlertMessage] = useState({});
const[getAlertCode,setAlertCode]=useState(0)
const[statnew,setstatnew]=useState([{}])
const[getBackGroundShade,setBackGroundShade]=useState(0.55)
const[getRenderKey,setRenderKey]=useState(0)
const[getMenuShade,setMenuShade]=useState(0.85)
const[getMenuShade2,setMenuShade2]=useState(0.65)
const [getColorVal,setColorVal]=useState('')
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const pageid=12
const [logtime]=useState(5000)


const [windowWidth,setWindowWith]=useState(0) // this is the window width
const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
const[getCountry,setCountry]=useState('se') // this is the window height

useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])


        // if language is japanese we use the font Zen Old Mincho
        let newFontFamily=''
        if(getCountry==='ja'){
        newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
        }else{
        newFontFamily='Arial, Helvetica, sans-serif'
        }
        document.documentElement.style.setProperty('--font-family', newFontFamily);
    
    
const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 1); // Adding one day
const formattedDate = currentDate.toISOString().split('T')[0];
const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
const oneYearAgoDate=oneYearAgo.toISOString().split('T')[0]; // formatted date to 2011-06-20 format

const[enddate,setenddate]=useState(formattedDate)
const[startdate,setstartdate]=useState(oneYearAgoDate)

const navigate = useNavigate();

useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    } 

  }


  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
let rgb = getColorVal;
let alpha = getMenuShade; // replace with your alpha value
let alpha2 = getMenuShade2; // replace with your alpha value
rgbaVal = rgbToRgba(rgb, alpha);
rgbaVal2 = rgbToRgba(rgb, alpha2);
val=`rgba(255, 255, 255, ${getBackGroundShade})`

}else{
  rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
  rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
  val=`rgba(255, 255, 255, ${getBackGroundShade})`
}
//---

useEffect(() => {
  let root = document.documentElement;
  root.style.setProperty('--rgbaVal', rgbaVal);
  root.style.setProperty('--rgbaVal2', rgbaVal2);
  root.style.setProperty('--val',val);
  root.style.setProperty('--windowHeight',windowHeight+'px');

  
}, [getBackGroundShade,getMenuShade,getMenuShade2]);

  // language settings
const selectedCountry = languages[getCountry];

   let title=''
  if(getMobileIsUsed===false){
    title=selectedCountry.productStatistics?.title
  }else{
    title=selectedCountry.productStatistics?.titleMobile
  }

  const {
    titleMobile,
    leftSelectLegend,
    leftSelectFirstOption,
    fromLegend,
    toLegend,
    rightStatisticLegend,
    chartLegend,
    titleAdjustMenu,
    titleAdjustMenu1,
  } =selectedCountry.productStatistics || {};

// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']
  


  useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);





  useEffect(()=>{
  
        async function GetProductCategories(){
          setSpinnerLoading(true)
            try{
                let response= await useGetData(`getProductCategoriesB`)
             
                setProductCategories(response.data[0])
                setSpinnerLoading(false)
            }catch(error){
              setAlertCode(25001)
              setAlertMessage(selectedError[25001])
              setSpinnerLoading(false)
              setShowAlert(true)
             
            }
    
        }
    
    GetProductCategories()
    
    },[])
    
    const RunReportErrHandler = async (errCode) => {
      setSpinnerLoading(true);
      await useReportErr('ErrReport',{errCode});
      setSpinnerLoading(false);
    };
    
    useEffect(() => {
      if (getAlertCode > 0) {
        RunReportErrHandler(getAlertCode);
      }
    }, [getAlertCode]);
    
    const handleCloseAlert = () => {
      setShowAlert(false);
    };
    
    
    useEffect(()=>{
    
        async function GetProductsInChosenCategory(){
          setSpinnerLoading(true)
          try{
            let response= await useGetData(`getAllProductByCategory/${getchosenCategory}`)
            setproductsinCategory(response.data[0])
            setSpinnerLoading(false)
          }catch(error){
            setAlertCode(25002)
            setAlertMessage(selectedError[25002])
            setSpinnerLoading(false)
            setShowAlert(true)
        
          }
    
        }
    GetProductsInChosenCategory()
    
    },[getchosenCategory])
    
    
    useEffect(()=>{
    
        async function GetchosenproductData(){
          setSpinnerLoading(true)
           try{
           
            let response= await useGetData(`getchosenproductData/${getchosenProduct}/${startdate}/${enddate}`)
            setchosenDataObj(response.data[0])
            setSpinnerLoading(false)
           }catch(error){
            setAlertCode(25003)
            setAlertMessage(selectedError[25003])
            setSpinnerLoading(false)
            setShowAlert(true)
         
           }
        }
       GetchosenproductData()
    },[getchosenProduct])
    
    const chosenProductHandler= (produktid,benaemn)=>{
    setchosenProduct(produktid)
    setchosenProductName(benaemn)
    
    }
    
    const chartHandler=(chartVal)=>{
      setchosenChart(chartVal)
    }


// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(25004)
      setAlertMessage(selectedError[25004])
      setSpinnerLoading(false)
      setShowAlert(true)
 
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(25005)
  setAlertMessage(selectedError[25005])
  setSpinnerLoading(false)
  setShowAlert(true)

}
}
    

  return(
<>
{windowWidth<360?displayGadgetToSmall():''}
<div  className={getBackGroundShade<1?"xoprodStatArea":"xoprodStatArea1"}>
{getBackGroundShade==1?'':<img src={imageUrl} alt="Bakesoft" className="xobackgroundImageRecepyNew" />}

<div className="xoheaderArea">
  {getCountry!=='ja'?
<div className={getBackGroundShade<1?"xocompName":"xocompName1"}>{title}</div>
:<div className={getBackGroundShade<1?"xocompNameJAP":"xocompNameJAP1"}>{title}</div>}
</div>


<div className="xorangeArea">
    
     <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={1}  
      title={titleAdjustMenu} className="xorange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
   
    <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
    title={titleAdjustMenu1} className="xorange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>

  </div>


<div className="xoprodStatAreaA">

<div className="xoprodstatTop">
   <fieldset  className="xoprodstatFieldset"><legend  className="xoprodstatLegend">{leftSelectLegend}</legend>
   <select className="xoprodstatSelect" onChange={(e)=>setchosenCategory(e.target.value)}>
  <option value="0" hidden className="xoprodstatOption">&nbsp;&nbsp;{leftSelectFirstOption}!&nbsp;</option>
       {getProductCategories.map((data,key)=><option key={key}value={data.kategoriid} className="xoprodstatOption">&nbsp;&nbsp;{data.benaemn}</option>)}
</select>
</fieldset>
</div>

<div className="xoprodstatFromArea">
<fieldset className="xoprodstatFieldsetFrom"><legend className="xoprodstatLegendFrom">{fromLegend}</legend>
<input type="date" id="startdate" name="trip-start" className="xoprodstatStartDate"
       value={startdate}
       min="2020-01-01" max={enddate} onChange={(e)=>setstartdate(e.target.value)}></input>
       </fieldset>
</div>

<div className="xoprodstatoToArea">
<fieldset className="xoprodstatFieldsetTo"><legend className="xoprodstatLegendTo">{toLegend}</legend>
<input type="date" id="enddate" name="trip-end" className="xoprodstatEndDate"
        value={enddate}
        min="2021-01-01" max={enddate} onChange={(e)=>setenddate(e.target.value)}></input>
        </fieldset>
</div>



<div className="xoprodstatMidWrapper">
<div className={getCountry!=='ja'?"xoprodstatMid":"xoprodstatMidJAP"}>
{getproductsinCategory.filter(data=>data.produktid>0).map((data,index)=>
    <div className={index%2===0?"xoprodstatButton":"xoprodstatButton1"} key={index}>
    <div className="xoprodstatButtontext" onClick={(e)=>chosenProductHandler(data.produktid,data.benaemn)}>{data.benaemn}</div></div>)}
   </div>

   </div>
   </div>

<div className="xoprodStatAreaB">

  <fieldset className="xoprodstatRightFieldset"><legend className="xoprodstatRightLegend">{rightStatisticLegend}:</legend>
  <div className="xoprodstatHeader">{getchosenProductname||''}</div></fieldset>
<div className="xoprodstatChartWrapper">

    {/* // mobile charts */}

    {getchosenChart>0 && getchosenChart<2&&getchosenProduct>0?<MyAreaChart getChartData={getchosenDataObj} getCountry={getCountry} 
    rgbaVal2={rgbaVal2} windowWidth={windowWidth} windowHeight={windowHeight}/>:''}
    {getchosenChart>1 && getchosenChart<3&&getchosenProduct>0?<MyLineChart getChartData={getchosenDataObj} getCountry={getCountry}
     rgbaVal2={rgbaVal2} windowWidth={windowWidth} windowHeight={windowHeight}/>:''}
    {getchosenChart>2 && getchosenChart<4&&getchosenProduct>0?<MyBarChart getChartData={getchosenDataObj} getCountry={getCountry}
     rgbaVal2={rgbaVal2} windowWidth={windowWidth} windowHeight={windowHeight}/>:''}

{getchosenProduct>0?
    <ChartChoice chartHandler={chartHandler} getchosenChart={getchosenChart} 
     statnew={statnew}/>
    :''}
    </div>
 
    
    {/* {getMobileIsUsed===false?
    <UseChartChoice chartHandler={chartHandler} getchosenChart={getchosenChart} chartLegend={chartLegend}/>
    :''} */}


  

  
</div>


<div className="xoprodStatSpinnerContainer">
        <div className="xoprodstatSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
    </div>

  
</>

  )
 

}

function ChartChoice (props){
  let {chartHandler,getchosenChart,statnew,chartLegend}=props

  return(
    <div className="xoChartChoiceArea">

      <button
        className={getchosenChart===1?"xouseChartChoicechartbutton":"xouseChartChoicechartbuttonactive"}
        onClick={() => chartHandler(1)}
      >
        Area
      </button>
      <button
        className={getchosenChart===2?"xouseChartChoicechartbutton":"xouseChartChoicechartbuttonactive"}
        onClick={() => chartHandler(2)}
      >
        Line
      </button>
      <button
        className={getchosenChart===3?"xouseChartChoicechartbutton":"xouseChartChoicechartbuttonactive"}
      onClick={() => chartHandler(3)}
      >
        Bar
      </button>
</div>
  )
    
}

function MyAreaChart(props) {
  let {getChartData,getCountry,windowWidth,rgbaVal2,windowHeight}=props

//   let chartHeight=125
//  if(windowWidth>=360 && windowWidth<370){
//   getCountry==='ja'?chartHeight=120:
//   chartHeight=130
//  } else if(windowWidth>=370 && windowWidth<385){
//   getCountry==='ja'?chartHeight=150:
//   chartHeight=160
//  }else if(windowWidth>=385 && windowWidth<393){
//   chartHeight=160
//  }else if(windowWidth>=393 && windowWidth<430){ // mobile
//   chartHeight=240
//   }else if(windowWidth>=430 && windowWidth<500){
//     chartHeight=190
//   }else if (windowWidth>=500 && windowWidth<1081){//tablet
//     chartHeight=450
//   }
//   else if (windowWidth>=1081 && windowWidth<1195){
//     chartHeight=480
  
//   } else if (windowWidth>=1195 && windowWidth<1367){
//     windowHeight>=690 && windowHeight<800?chartHeight=450:
//     chartHeight=650
//   }
//   else if(windowWidth>=1367 && windowWidth<2304){
//     chartHeight=630
//   }else if(windowWidth>=2304){
//     chartHeight=1000
//   }


  const prepareChartData = (data) => {
    return data.map(item => ({
      week: item.week+1, // Subtract 1 from week to start from 0
      antal: Number(item.antal) // Ensure antal is a number
    }));
  };

  return (
<>
    <ResponsiveContainer width={"95%"} >
    <AreaChart

      data={prepareChartData(getChartData)}
      margin={{
        top:0,
        right: 10,
        left: -15,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="week" />
      <YAxis dataKey="antal" />
      <Area type="monotone" dataKey="antal" stroke={rgbaVal2} fill={rgbaVal2} />
    </AreaChart>
    </ResponsiveContainer>
    </>
  );
}

function MyLineChart(props) {
  let {getChartData,getCountry,rgbaVal2,windowWidth,windowHeight}=props

  // let antalLegend=''
  // if(getCountry==='se'){
  //   antalLegend='Antal per vecka'
  // }
  // else if(getCountry==='ja'){
  //   antalLegend='週ごとの量'
  // }else if(getCountry==='en'){
  //   antalLegend='Amount per week'
  // }
  
//   let chartHeight=125
//  if(windowWidth>=360 && windowWidth<370){
//   getCountry==='ja'?chartHeight=120:
//   chartHeight=130
//  } else if(windowWidth>=370 && windowWidth<385){
//   getCountry==='ja'?chartHeight=150:
//   chartHeight=160
//  }else if(windowWidth>=385 && windowWidth<393){
//   chartHeight=160
//  }else if(windowWidth>=393 && windowWidth<430){ // mobile
//   chartHeight=240
//   }else if(windowWidth>=430 && windowWidth<500){
//     chartHeight=190
//   }else if (windowWidth>=500 && windowWidth<1081){//tablet
//     chartHeight=450
//   }
//   else if (windowWidth>=1081 && windowWidth<1195){
//     chartHeight=480
  
//   } else if (windowWidth>=1195 && windowWidth<1367){
//     windowHeight>=690 && windowHeight<800?chartHeight=450:
//     chartHeight=650
//   }
//   else if(windowWidth>=1367 && windowWidth<2304){
//     chartHeight=630
//   }else if(windowWidth>=2304){
//     chartHeight=1000
//   }
  const prepareChartData = (data) => {
    return data.map(item => ({
      week: item.week+1, // Subtract 1 from week to start from 0
      antal: Number(item.antal) // Ensure antal is a number
    }));
  };


  return (
    <>
    <ResponsiveContainer width={"95%"} >
    <LineChart
      data={prepareChartData(getChartData)}
      margin={{
        top: 0,
        right: 10,
        left: -15,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="week" />
      <YAxis dataKey="antal" />
      <Line type="monotone" dataKey="antal" stroke={rgbaVal2} />
    </LineChart>
    </ResponsiveContainer>
    </>
  );
}

function MyBarChart(props) {
  let {getChartData,getCountry,rgbaVal2,windowWidth,windowHeight}=props

  // let antalLegend=''
  // if(getCountry==='se'){
  //   antalLegend='Antal per vecka'
  // }
  // else if(getCountry==='ja'){
  //   antalLegend='週ごとの量'
  // }else if(getCountry==='en'){
  //   antalLegend='Amount per week'
  // }
//728//690
//1180//1280


//   let chartHeight=125
//  if(windowWidth>=360 && windowWidth<370){
//   getCountry==='ja'?chartHeight=120:
//   chartHeight=160
//  } else if(windowWidth>=370 && windowWidth<385){
//   getCountry==='ja'?chartHeight=150:
//   chartHeight=160
//  }else if(windowWidth>=385 && windowWidth<393){
//   chartHeight=160
//  }else if(windowWidth>=393 && windowWidth<430){ // mobile
//   chartHeight=240
//   }else if(windowWidth>=430 && windowWidth<500){
//     chartHeight=190
//   }else if (windowWidth>=500 && windowWidth<1081){//tablet
//     chartHeight=450
//   }
//   else if (windowWidth>=1081 && windowWidth<1195){
//     chartHeight=480
  
//   } else if (windowWidth>=1195 && windowWidth<1367){
//     windowHeight>=690 && windowHeight<800?chartHeight=450:
//     chartHeight=650
//   }
//   else if(windowWidth>=1367 && windowWidth<2304){
//     chartHeight=630
//   }else if(windowWidth>=2304){
//     chartHeight=1000
//   }

  const prepareChartData = (data) => {
    return data.map(item => ({
      week: item.week+1, // Subtract 1 from week to start from 0
      antal: Number(item.antal) // Ensure antal is a number
    }));
  };

  return (
<>
  <ResponsiveContainer width={"95%"} >
    <BarChart
      data={prepareChartData(getChartData)}
      margin={{
        top: 0,
        right:10,
        left: -15,
        bottom:0,
      }}

    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="week" />
      <YAxis dataKey="antal" />
      <Bar dataKey="antal" fill={rgbaVal2} />
    </BarChart>
  </ResponsiveContainer>
</>
  );
}

export{ProdStatNew,ChartChoice,MyAreaChart,MyLineChart,MyBarChart}