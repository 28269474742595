import React,{useEffect,useState} from 'react'
import './welcomeimage.css'
import useFetchImageUrl from './hooks/useFetchImageUrl'
import usePageSettings from './hooks/usePageSettings'
import AppError from './AppLanguage/AppError';
import useGetData from './hooks/useGetData';
import languages from './AppLanguage/AppLanguage';
import {useNavigate} from 'react-router-dom';
import { IoIosCloseCircleOutline } from "react-icons/io";

function WelcomeImage() {
  const navigate = useNavigate();

const [getCountry,setCountry] = useState('en')
const [windowWidth, setWindowWith] = useState(window.innerWidth);
const [windowHeight, setWindowHeight] = useState(window.innerHeight);
const [mobileIsUsed,setMobileIsUsed] = useState(false)
const[getBackGroundShade,setBackGroundShade]=useState(0.75)
const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
const[getMenuShade,setMenuShade]=useState(0.95)
const[getMenuShade2,setMenuShade2]=useState(0.75)
const [getColorVal,setColorVal]=useState('')
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const[getSpinnerLoading,setSpinnerLoading]=useState(false)
const[getAlertCode,setAlertCode]=useState(0)
const[getAlertMessage,setAlertMessage]=useState('') // this is the message for the alert
const[getShowAlert,setShowAlert]=useState(false) // this is th
const[getMenu,setMenu]=useState(true) // this is the menu

// check for the invoice status
const[getMainInvoiceStatus,setMainInvoiceStatus]=useState(false)



const [pageid]=useState(23)

  useEffect(()=>{
    let country = localStorage.getItem('country')||'en' // this is the country code
    setCountry(country)
  },[])


let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
   newFontFamily='Arial, Helvetica, sans-serif'
  // newFontFamily="Lobster, Arial, Helvetica, sans-serif"
//newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);



const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal} = usePageSettings(pageid);
const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal);



useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    }

  }


  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
        


function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}
      //--bakcground shade settings
      let rgbaVal
      let rgbaVal2
      let val
      
      if(getColorVal && getColorVal.length > 0){
        let rgb = getColorVal;
        let alpha = getMenuShade; // replace with your alpha value
        let alpha2 = getMenuShade2; // replace with your alpha value
        rgbaVal = rgbToRgba(rgb, alpha);
        rgbaVal2 = rgbToRgba(rgb, alpha2);
        val=`rgba(255, 255, 255, ${getBackGroundShade})`
      
        }else{
          rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
          rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
          val=`rgba(255, 255, 255, ${getBackGroundShade})`
        }
      //---
      
      useEffect(() => {
          let root = document.documentElement;
          root.style.setProperty('--rgbaVal', rgbaVal);
          root.style.setProperty('--rgbaVal2', rgbaVal2);
          root.style.setProperty('--val',val);
          root.style.setProperty('--windowHeight',windowHeight+'px');
          
        }, [getBackGroundShade,getMenuShade,getMenuShade2]);
      
        //-----


        useEffect(() => {
          GetSettings();
        }, [GetSettings]);

        // we set the image url here
        useEffect(() => {
          setImageUrl(imageUrl);
          if (alertCode > 0) {
            setAlertMessage(alertMessage);
            setAlertCode(alertCode);
            setShowAlert(showAlert);
          }
        }, [imageUrl]);
      
        // here we set the color values and image values
        useEffect(() => {
          setBackGroundShade(backGroundShade);
          setMenuShade(menuShade);
          setMenuShade2(menuShade2);
          setColorVal(colorVal);
          setImageVal(imageVal);
        }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);


        const selectedError=AppError[localStorage.getItem('country')||'en']
        const selectedCountry = languages[getCountry];


        const{
          importantNoticeText,
          invoiceDueText,
          invoiceText,
          serviceIdText,
          dueDateText,
          amountText,
          settlePaymentHereText,
          gotoMyAccountText,
          ifnopaymentText,
          contactSupportText,
          supportLinkText,
          serviceDueText,
          deleteText,
          payText,
          subscriptionInRedText
        
        
       }=selectedCountry.welcomeImage || {}





        async function CheckInvoiceStatus(){
           setSpinnerLoading(true)
           try{
            let response=await useGetData('checkInvoiceStatus')
            console.log(response)
            if(response.status===200){
              setSpinnerLoading(false)
              setMainInvoiceStatus(response.data[0])
            }
           }catch(error){
            setSpinnerLoading(false)
            setAlertCode(58001)
            setAlertMessage(selectedError[58001])
            setShowAlert(true)
           }
 }

 useEffect(()=>{
  CheckInvoiceStatus()
 },[])

const deactivateServiceHandler=async(serviceid,fakturaid)=>{
  if(serviceid&&fakturaid){
  return DeactivateService(serviceid,fakturaid)
  }else{
    return;
  }
}
async function DeactivateService(serviceid,fakturaid){
  setSpinnerLoading(true)
  try{
    let response=await useGetData(`deactivateService/${serviceid}/${fakturaid}`)
    if(response.status===200){
      setSpinnerLoading(false)
      CheckInvoiceStatus()
    }
  }catch(error){
    setSpinnerLoading(false)
    setAlertCode(58003)
    setAlertMessage(selectedError[58003])
    setShowAlert(true)
  }
}


const closeMenuHandler=()=>{
  setMenu(false)
}


return(
  <div>
  <img src={imageUrl} alt="Bakesoft" className="backgroundImage" />
  <div className="wwtotalTop">

    <div className="wwtotalArea">
      <div className="areaBTop">
        <div className="areaBLogo">
        {getMainInvoiceStatus && getMainInvoiceStatus.length > 0 && getMenu===true && (
  <div className="wwServicesAreaTopWrapper">
    <div className="wwServicesAreaHeader">
      {importantNoticeText}
      <IoIosCloseCircleOutline className="wwServicesCloseIcon" 
      onClick={() =>closeMenuHandler()}
      />
    </div>
    <div className="wwServicesAreaWrapper">
      <div className="wwServiceHeadertext">{serviceDueText}</div>

      {getMainInvoiceStatus.map((data, index) => (
        <div className={data.serviceid===127||data.serviceid===128||data.serviceid===133||data.serviceid===134?"wwServicesRow1":"wwServicesRow"} key={index}>
          
          <div className="wwServicesInvoice">
            {invoiceText} {data.fakturaid}
          </div>
          <div className="wwServicesService">
          {!mobileIsUsed?serviceIdText:''}:{!mobileIsUsed?data.serviceid:''}
          </div>
          <div className="wwServicesDueDate">
            {!mobileIsUsed?data.dateFrom.slice(0, 10) :''}
          </div>
          <div className="wwServicesAmount">
            {amountText} {data.totalsumLocalCurrVat}
          </div>
          <div className="wwServicesBenaemn">
            {!mobileIsUsed?data.benaemn:''}
          </div>
          <div className="wwServicesButtonWrapper">
          <div className="wwServicesButton"
          onClick={()=>navigate('/MyAccount')}
          >
            {payText}
          </div>
          </div>
        </div>
      ))}
      <div className="wwExtraInfo">
        {subscriptionInRedText}
      </div>
    </div>
  </div>
)}
 

    <div className="wwLogoWrapper">
          Bakesoft
      </div>
          </div>

  </div>
  </div>
  </div>
  </div>
)



}    







export default WelcomeImage


