
import { Utensils, BookOpen, Lightbulb, Image, Search, Scale} from 'lucide-react';
export default function AIServicesJapanese(props){
    let {getImageUrl, buttonId} = props;
  
    return (
<div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="transparentBoxAIServices">
      <div className="plusServicesHeader">私たちは以下のAIベースのサービスを開発し、使用しています:</div>

      <div className="basicServicesListText">
        <ol className="basicServicesListMid">
          <div className="aiServicesSurround">
            <div className="aiServicesText">
              <Utensils className="aiServicesIcon" />AIでレシピを作成
            </div>
            <div className="aiServicesText">
              <Lightbulb className="aiServicesIcon" />レシピアイデアを作成
            </div>
            <div className="aiServicesText">
              <Image className="aiServicesIcon" />AIで画像を作成
            </div>
            <div className="aiServicesText">
              <Search className="aiServicesIcon" />材料情報を検索
            </div>
            <div className="aiServicesText">
              <Scale className="aiServicesIcon" />材料の変換
            </div>
          </div>
        </ol>
      </div>
    </div>
  </div>
</div>

    )
  }