import { Utensils, BookOpen, Lightbulb, Image, Search, Scale } from 'lucide-react';
import '../info.css'
export default function AboutUsEnglish(props){
    let {getImageUrl, buttonId} = props
  
    return (
<div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="aboutUsTransparentBox">
      <div className="basicServicesHeader"></div>
      <div className="aboutUsSurroundBorder">
        <Utensils className="aboutUsLeafIcon" />
        <div className="aboutUsChapter">
          <div className="aboutUsServicesCommentText">
            Bakesoft.se has gradually evolved through several prototypes of software that we originally created for the bakery, Överlida Bageri, located in western Sweden. We have previously operated a bakery in Gothenburg since the 1990s.
          </div>
        </div>

        <div className="aboutUsChapter">
          <div className="aboutUsServicesCommentText">
            During the work with Överlida Bageri, the first version of Bakesoft.se was developed, focusing on improving control over margins, purchases, recipes, and products.
          </div>
        </div>

        <div className="aboutUsChapter">
          <div className="aboutUsServicesCommentText">
            The first prototype was able to manage ingredients and calculate recipe costs, making it easier to compare costs between different recipes depending on the chosen ingredients.
          </div>
        </div>

        <div className="aboutUsChapter">
          <div className="aboutUsServicesCommentText">
            Over the years, the application has evolved, and in the last 12 months, it has grown significantly. In December 2023, the application received a web-based interface for mobile phones, in addition to the previous version for iPads. Today, we support most types of devices, from mobile phones to desktop computers.
          </div>
        </div>

        <div className="aboutUsChapter">
          <div className="aboutUsServicesCommentText">
            2023 and 2024 saw major progress with several AI integrations and new functionality, such as improved recipe searches, image uploads, and AI-generated images.
          </div>
        </div>

        <div className="aboutUsChapter">
          <div className="aboutUsServicesCommentText">
            In the fall of 2023, we decided to make Bakesoft.se available to more users, which involved extensive redevelopment of the application.
            We have worked to create a solution for ingredient, recipe, and product management because we prefer to focus on baking and having full control over our products, rather than sitting with manual calculations. It saves us time and gives us a complete overview of our business.
          </div>
        </div>
        <BookOpen className="aboutUsLeafIcon" />
      </div>
    </div>
  </div>
</div>

    );
  
  }