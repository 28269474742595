import React,{useState} from 'react'
import '../info.css'
export default function Faq(props){
    let{faqData,getCountry,faqHeaderText,getImageUrl,buttonId}=props
    const[expandedRow,setExpandedRow]=useState()
      return(
    
        <div className="infoFaqWrapper">
            <div className="faqBackgroundImage" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
             
              <div className="faqtransparentBox">
              <div className="faqSurroundBox">
          <div className={getCountry!=='ja'?"infoFaqHeader":"infoFaqHeaderJAP"}>
            {faqHeaderText}
          </div>
          <div className="infofaqDataList">
            {faqData.filter(data=>data.cc===getCountry).map((data,index)=>(
              <>
              <div className="infofaqData" key={index}>
                    <div className="infofaqId">{data.faqid}&nbsp;&nbsp;</div>
                    <div className="infofaqQuestion" onClick={() => setExpandedRow(expandedRow === index ? null : index)}>{data.question}</div>
              </div>
              <div className="infofaqAnswer">
                {expandedRow===index && <div className="infofaqAnimated">{data.answer}</div>}
                
                </div></>
            ))}
      </div>
  
        </div>
        </div>
        </div>
        </div>
    
      )
    
  }