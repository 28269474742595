import { Utensils, BookOpen, Lightbulb, Image, Search, Scale } from 'lucide-react';
import '../info.css'
export default function AboutUsJapanese(props){
    let {getImageUrl, buttonId} = props
  
    return (
<div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="aboutUsTransparentBox">
      <div className="basicServicesHeader"></div>
      <div className="aboutUsSurroundBorder">
        <Utensils className="aboutUsLeafIconJP" />
        <div className="aboutUsChapterJP">
          <div className="aboutUsServicesCommentText">
            Bakesoft.seは、西スウェーデンにあるベーカリー、Överlida Bageriのために最初に作成したいくつかのソフトウェアプロトタイプを通じて、徐々に進化してきました。私たちは1990年代からヨーテボリでベーカリーを運営していました。
          </div>
        </div>

        <div className="aboutUsChapterJP">
          <div className="aboutUsServicesCommentText">
            Överlida Bageriでの作業中に、利益率、仕入れ、レシピ、製品の管理を改善することに焦点を当てたBakesoft.seの最初のバージョンが開発されました。
          </div>
        </div>

        <div className="aboutUsChapterJP">
          <div className="aboutUsServicesCommentText">
            最初のプロトタイプでは、材料の管理とレシピコストの計算が可能で、選択した材料に応じて異なるレシピ間のコストを比較することが簡単になりました。
          </div>
        </div>

        <div className="aboutUsChapterJP">
          <div className="aboutUsServicesCommentText">
            何年にもわたってアプリケーションは進化し、過去12か月で大きく成長しました。2023年12月には、以前のiPad版に加え、モバイル用のウェブベースのインターフェースが導入されました。今日では、携帯電話からデスクトップコンピュータまで、ほとんどのデバイスをサポートしています。
          </div>
        </div>

        <div className="aboutUsChapterJP">
          <div className="aboutUsServicesCommentText">
            2023年と2024年には、複数のAI統合や新しい機能が導入され、レシピ検索の改善、画像アップロード、AI生成画像などが含まれました。
          </div>
        </div>

        <div className="aboutUsChapterJP">
          <div className="aboutUsServicesCommentText">
            2023年秋には、Bakesoft.seをより多くのユーザーに利用可能にするために、アプリケーションの大規模な再構築を行うことを決定しました。私たちは、手動での計算に時間を費やすのではなく、材料、レシピ、製品管理のソリューションを作成し、製品を完全にコントロールできるようにすることに力を入れています。これにより、時間が節約され、事業全体を把握することができます。
          </div>
        </div>
        <BookOpen className="aboutUsLeafIconJP" />
      </div>
    </div>
  </div>
</div>

    );
  
  }