// PlusServices.js
import React, { useState } from 'react';
import '../info.css';
//import { Cpu, Image, BookOpen, Camera, Box, LifeBuoy, Phone, ShieldCheck, Package, Lightbulb, Layers, List, Grid, Utensils, Search, Scale, MessageCircle } from 'lucide-react';
import * as Icons from 'lucide-react'; 
function PlusServices({
  getImageUrl,
  buttonId,
  getServicesInfo,
  getCountry,
  xchangeRate,
  currencySymbol,
  plusServicePrice,
  plusServiceBenaemning,
  plusServicesHeaderText,
  plusServicesHeaderText1,
  pricesGivenPerMonthText,
  getServicesMainInfo,
  klickOnTextMoreInfo,
}) {

// Icon mapping

console.log(getServicesInfo);
  const [expandedRow, setExpandedRow] = useState();

  const handleClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  let getServicesInfoFiltered =
    getServicesInfo && getServicesInfo.length > 0
      ? getServicesInfo.filter((data) => data.cc === getCountry)
      : '';
  let localxchangeRate =
    xchangeRate && xchangeRate.length > 0
      ? xchangeRate.find((rate) => rate.cc === getCountry)?.xchangeRate ?? ''
      : '';
  let localcurrencySymbol =
    currencySymbol && currencySymbol.length > 0
      ? currencySymbol.find((symbol) => symbol.currencyKey === getCountry)
          ?.currencySymbol ?? ''
      : '';

  return (
    <div className="basicServicesWrapper">
      <div
        className="infoBEnglish"
        style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}
      >
        <div className="transparentBoxPlus">
          {/* <div
            className={
              getCountry !== 'ja'
                ? 'basicServicesPlusHeader'
                : 'basicServicesPlusHeaderJAP'
            }
          > */}
                 {/* {plusServicesHeaderText}&nbsp;{plusServicesHeaderText1} */}
            {/* <p></p>
          </div> */}
          <div className="basicServicesPlusHeader">
            {pricesGivenPerMonthText}.<p></p>
          </div>
          <div className="basicServicesPlusHeaderMoreInfo">
            {klickOnTextMoreInfo}
            <p></p>
          </div>

          <div className="basicServicesListWrapper">
            <div className="basicServicesDataList">
              {getServicesInfoFiltered.map((data, index) => (
                
                <React.Fragment key={index}>
                  <div className="basicServicesData">
                    <div className="basicServicesListId">{data.serviceid}</div>
                    <div className="plusServicesIcon">
                    <DynamicComponent componentName={data.serviceIcon} size={20} color="currentColor" />
                    </div>
        
                    <div className="basicServicesListBenaemn" onClick={() => handleClick(index)}>
                      {data.benaemn}
                    </div>

                    <div className="basicServicesListPrice">
                      {localcurrencySymbol}&nbsp;
                      {Math.floor(data.price / localxchangeRate)}
                    </div>
                  </div>
                  {expandedRow === index && (
                    <div className="tjansterInfoAnimated">
                      <div className="basicServicesBaseInfo">
                        {getServicesMainInfo
                          .filter((info) => info.cc === getCountry)
                          .map((info, index) => {
                            return (
                              <div key={index}>
                                {info.serviceid === data.serviceid && info.benaemn}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlusServices;


const DynamicComponent = ({ componentName, ...props }) => {
    const iconMap = Icons;


    const Component = iconMap[componentName]; // Map to the component by name
    return Component ? <Component {...props} /> : null;
  };