
import { Utensils, BookOpen, Lightbulb, Image, Search, Scale } from 'lucide-react';
export default function AIServicesEnglish(props){
    const { getImageUrl, buttonId } = props;
  
    return (
<div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="transparentBoxAIServices">
      <div className="plusServicesHeader">We use the following AI-based services.</div>

      <div className="basicServicesListText">
        <ol className="basicServicesListMid">
          <div className="aiServicesSurround">
            <div className="aiServicesText">
              <Utensils className="aiServicesIcon" />Create recipe
            </div>
            <div className="aiServicesText">
              <Lightbulb className="aiServicesIcon" />Create recipe ideas
            </div>
            <div className="aiServicesText">
              <Image className="aiServicesIcon" />Create images
            </div>
            <div className="aiServicesText">
              <Search className="aiServicesIcon" />Search ingredient information
            </div>
            <div className="aiServicesText">
              <Scale className="aiServicesIcon" />Ingredient conversion
            </div>
          </div>
        </ol>
      </div>
    </div>
  </div>
</div>
    )
  }