
// import axios from "axios";
// import GetappUrl from "../functions/appUrl";

// const  useGetData=async (url,payload) => {
//       const token = localStorage.getItem('auth_token');
//       const headers = token ? { authorization: `Bearer ${token}` } : {};
//       const result = await axios.get(GetappUrl() + `/${url}`,{
//             headers: headers,
//             params: payload,
//             Credentials: 'true'
//       })

          
//              return result;
// };

// export {useGetData}
// export default useGetData;


import axios from "axios";
import GetappUrl from "../functions/appUrl";
// use parameters
async function  useGetData(url, payload) {
  const token = localStorage.getItem('auth_token');
  const headers = token ? { authorization: `Bearer ${token}` } : {};

  
    const result = await axios.get(GetappUrl() + `/${url}`, {
      headers: headers,
      params: payload,
      Credentials: 'true'
    });

    return result;
};
export default useGetData


// use parameters
async function  useDeleteData(url, payload) {
      const token = localStorage.getItem('auth_token');
      const headers = token ? { authorization: `Bearer ${token}` } : {};
    
      
        const result = await axios.delete(GetappUrl() + `/${url}`, {
          headers: headers,
          params: payload,
          Credentials: 'true'
        });
    
        return result;
    };
 
 // use body, send the information in the body
async function usePostData(url, payload) {
      const token = localStorage.getItem('auth_token');
      const headers = token ? { authorization: `Bearer ${token}` } : {};
    
      const result = await axios.post(GetappUrl() + `/${url}`, payload, {
        headers: headers,
        Credentials: true
      });
    
      return result;
    }

     // use body, send the information in the body
async function usePostData1(url, payload,apiKey) {
  const token = localStorage.getItem('auth_token');


  const result = await axios.post(GetappUrl() + `/${url}`,{payload},{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey},
      body: JSON.stringify(payload),
    Credentials: true
  });

  return result;
}


// here we use the api key for the 
async function useGetData1(url, payload, apiKey) {
  const headers = {
    'Content-Type': 'application/json',
        'x-api-key': apiKey,
  };
    const result = await axios.get(GetappUrl() + `/${url}`, {
      headers: headers,
      Credentials: true, // Correcting credentials usage
    });
  

    return result;
}

  // use body , send the information in the body
async function usePutData(url, payload) {
      const token = localStorage.getItem('auth_token');
      const headers = token ? { authorization: `Bearer ${token}` } : {};
    
      const result = await axios.put(GetappUrl() + `/${url}`, payload, {
        headers: headers,
        Credentials: true
      });
    
      return result;
    }

    async function  usePrintPdfData(url, payload,token) {
 
      const headers = token ? { authorization: `Bearer ${token}` } : {};
    
      
        const result = await axios.get(GetappUrl() + `/${url}`, {
          headers: headers,
          params: payload,
          Credentials: 'true'
        });
    
        return result;
    };
  

export { useGetData,usePutData,usePostData,usePostData1,useDeleteData,usePrintPdfData,useGetData1}





