

const appearance = {
    theme: 'stripe', // You can try 'flat', 'night', 'none' for full custom control
    variables: {
      colorPrimary: '#1a73e8', // A professional blue shade for primary elements
      colorBackground: '#ffffff', // White background for a clean, modern look
      colorText: '#333333', // Dark grey for readable text
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', // Clean font stack
      fontWeightNormal: '400', // Normal font weight for regular text
      borderRadius: '6px', // Softer border radius for modern input fields
      spacingUnit: '4px', // Consistent padding between elements
    },
    rules: {
      '.Input': {
        backgroundColor: '#f9f9f9', // Light grey for input backgrounds
        color: '#333333', // Dark grey text for better contrast and readability
        borderColor: '#d1d5db', // Subtle grey border for inputs
        borderRadius: '6px', // Matching border radius for inputs
        padding: '12px', // Comfortable padding inside input fields
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Light shadow for input depth
      },
      '.Input:focus': {
        borderColor: '#1a73e8', // Focused input border in the primary color
        boxShadow: '0 0 0 3px rgba(26, 115, 232, 0.3)', // Soft blue focus ring
      },
      '.Label': {
        fontWeight: '600', // Make the labels bold for better emphasis
        color: '#1a73e8', // Use the primary blue for labels to match branding
      },
      '.Button': {
        backgroundColor: '#1a73e8', // Primary blue for button backgrounds
        color: '#ffffff', // White text for high contrast on buttons
        fontSize: '16px', // Comfortable button text size
        padding: '12px 16px', // Adequate padding for buttons
        borderRadius: '6px', // Matching border radius for buttons
        border: 'none', // Remove default button borders
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for button depth
        transition: 'background-color 0.3s ease', // Smooth hover transition
      },
      '.Button:hover': {
        backgroundColor: '#155ab5', // Darker blue on hover for visual feedback
      },
      '.Button:disabled': {
        backgroundColor: '#d1d5db', // Light grey for disabled buttons
        color: '#7d7d7d', // Muted text color for disabled buttons
      },
      '.Error': {
        color: '#e63946', // Bright red for error messages
      },
    },
  };
  export default appearance;